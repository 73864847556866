import { Refresh } from "@mui/icons-material";
import { Box, CircularProgress, FormControl, IconButton, MenuItem, Tooltip as MuiTooltip, Select } from "@mui/material";
import { BarController, BarElement, CategoryScale, ChartData, Chart as ChartJS, Legend, LineController, LineElement, LinearScale, PointElement, Title, Tooltip } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import moment from 'moment';
import React from "react";
import { Chart } from 'react-chartjs-2';
import { useTranslation } from "react-i18next";
import { CFFDateFormat } from "../../../constants/appRegex";
import { EmptyPage } from "../../../shared/components/Common";
import { useNotifyContext } from '../../../shared/contexts';
import { GetBackTests } from '../../../shared/helpers';
import { useErrorTranslation } from '../../../shared/hooks';

interface DetailsPanelProps {
    setFund: React.Dispatch<React.SetStateAction<FundModel>>;
    fund: FundModel;
}

interface FundModel {
    oid: string;
    name: string;
    description: string;
    logo: string;
    startDate: string;
    endDate: string;
    commission: number;
    balance: number;
    expectedProfit: number;
    costs: number;
    public: boolean;
    ipCheck: boolean;
    quotesNumber: number;
    quotesAmount: number;
    quotesExpenses: number;
    takeProfitTarget: number;
    stopLoseTarget: number;
    status: string;
}

ChartJS.register(
    BarController,
    LineController,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);

export const useBackTestsPanel = (props: DetailsPanelProps) => {

    const notify = useNotifyContext();

    const getErrorTranslation = useErrorTranslation();
    const lotSizeRef = React.useRef({ max: 0 });

    const { t } = useTranslation();

    const [selectedTime, setSelectedTime] = React.useState(1);
    const [statistics, setStatistics] = React.useState<ChartData<"line">>({ labels: [], datasets: [] });
    const [loader, setLoader] = React.useState(false);

    const handleSelectedTime = (id: number) => {
        setSelectedTime(id);
    }

    const callGetFundOperation = () => {
        setLoader(true);
        GetBackTests({
            params: {
                oid: props.fund.oid,
                backTestType: selectedTime
            }
        }).then(({ data }) => {

            const _statistics: any = {
                labels: [],
                datasets: [
                    {
                        type: 'line' as const,
                        label: t("fundDetailsPage.balance"),
                        borderColor: 'rgb(99, 132, 99)',
                        backgroundColor: 'rgba(99, 132, 99, 0.5)',
                        pointRadius: 1,
                        borderWidth: 1,
                        data: [],
                        yAxisID: 'y',
                    },
                    {
                        type: 'line' as const,
                        label: t("fundDetailsPage.equity"),
                        borderColor: 'rgb(255, 165, 0)',
                        backgroundColor: 'rgba(255, 165, 0, 0.5)',
                        pointRadius: 1,
                        borderWidth: 1,
                        data: [],
                        yAxisID: 'y',
                    },
                    {
                        type: 'bar' as const,
                        label: t("fundDetailsPage.lotSizes"),
                        borderColor: 'rgb(132, 132, 132)',
                        backgroundColor: 'rgba(132, 132, 132, 0.4)',
                        data: [],
                        barThickness: 2,
                        position: 'right',
                        yAxisID: 'y1',
                    }
                ]
            };

            data?.backTestStatistics.forEach((found) => {

                const date = moment(found.operationDate).format(CFFDateFormat.short);
                _statistics.labels?.push(date);
                _statistics.datasets[0].data.push(found.balance);
                _statistics.datasets[1].data.push(found.equity);
                _statistics.datasets[2].data.push(found.lotSize);
            });

            lotSizeRef.current.max = Math.max(..._statistics.datasets[2].data);

            setStatistics({ ..._statistics });
            setLoader(false);
        }).catch((ev) => {

            setLoader(false);
            const status = ev.response?.data?.errorCode || ev.response?.status || 500;
            const url = ev.config.url;
            notify.warning({
                content: getErrorTranslation(url, status)
            });
        });
    }

    React.useEffect(() => {
        callGetFundOperation();
    }, [selectedTime]);

    return {
        lotSizeRef,
        statistics,
        loader,
        selectedTime,
        handleSelectedTime,
        handleReload: callGetFundOperation
    }
};

export const PanelBackTests: React.FC<DetailsPanelProps> = (props) => {

    const { t } = useTranslation();
    const {
        lotSizeRef,
        statistics,
        loader,
        handleReload,
        selectedTime,
        handleSelectedTime
    } = useBackTestsPanel(props);

    return (
        <Box px={2} height="100%">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                    <MuiTooltip title={t("shared.reload")}>
                        <IconButton onClick={handleReload}>
                            <Refresh />
                        </IconButton>
                    </MuiTooltip>
                </Box>
                <FormControl>
                    <Select
                        value={selectedTime}
                        sx={{ fontSize: '14px' }}
                        onChange={(ev) => { handleSelectedTime(Number(ev.target.value)); }}
                    >
                        {[1, 3, 6, 12, 18, 36, 60].map((value, i) => (
                            <MenuItem
                                value={value}
                                key={`menuitem-${i}`}
                                style={{ fontSize: '14px' }}
                            >
                                {`${value} ${value === 1 ? t("shared.month") : t("shared.months")}`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box
                sx={{
                    boxSizing: 'border-box',
                    overflow: 'auto',
                    width: 'calc(100vw - 270px)',
                    height: 'calc(100% - 40px)'
                }}
            >
                {loader && (
                    <Box height="calc(100vh - 270px)" width="100%" display="flex" alignItems="center" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
                {!loader ? statistics?.labels?.length ? (
                    <Box sx={{ overflow: 'auto', textAlign: 'center', alignItems: 'center' }}>
                        <Box sx={{ height: 'calc(100vh - 183px)' }}>
                            <Chart
                                type='bar'
                                data={statistics}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    scales: {
                                        x: {
                                            offset: false,
                                            grid: {
                                                drawOnChartArea: true,
                                                drawTicks: true
                                            },
                                        },
                                        y: {
                                            type: 'linear',
                                            display: true,
                                            position: 'left',
                                        },
                                        y1: {
                                            type: 'linear',
                                            display: true,
                                            position: 'right',
                                            max: lotSizeRef.current.max * 8,
                                            grid: {
                                                drawOnChartArea: true
                                            },
                                        },
                                    },
                                    plugins: {
                                        zoom: {
                                            zoom: {
                                                wheel: {
                                                    enabled: true
                                                },
                                                drag: {
                                                    enabled: true
                                                },
                                                mode: "x",
                                            }
                                        }
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                ) : (
                    <Box sx={{ height: '100%' }}>
                        <EmptyPage title={t("fundDetailsPage.noOperationsFromBacktest")} />
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
}
