import { Components } from "@mui/material/styles";

export const MuiBreadcrumbs: Components['MuiBreadcrumbs'] = {
    styleOverrides: {
        root: {
            fontSize: '1.0rem',
        },
        separator: {
            color: '#1c6e3d'
        }
    },
    defaultProps: {
        separator: '>'
    }
};
