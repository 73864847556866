import { IconButton, Stack, Tooltip } from '@mui/material';
import * as React from 'react';
import { Filters, TFTableProps } from './TFTable';
import { RiFilterOffLine } from 'react-icons/ri';
import { columnTypeGuard } from './TFTableHeader';

/** Traduzioni di default per le sezioni della tabella */
const tsl = {
    resetFilters: 'Reset filters'
}

type ConditionalActionsProps<R> = {
    actions: TFTableProps<R>['actions'];    // Actions da utilizzare 
    totalCount: number;         // Numero di record totali
    resetFilter: () => void;
    filters: Filters;
    options: TFTableProps<R>['options'];
    columns: TFTableProps<R>['columns']
}

export const ConditionalActions: <R>(props: ConditionalActionsProps<R>) => React.ReactElement | null = (props) => {

    const _filtersCount = React.useMemo(() => {

        const _columnsFilters = props.columns.filter((column) => column && columnTypeGuard(column) && column.filter);
        const _useSearch = props?.options?.useSearch !== false;

        return _columnsFilters.length + (_useSearch ? 1 : 0);

    }, [props.columns, props.options]);

    const _hasFilters = React.useMemo(() => {

        const _columnsFilters = props?.filters?.filters?.filter(({ filter }) => filter) || [];

        return _columnsFilters.length + (Boolean(props.filters.search) ? 1 : 0);

    }, [props.filters]);

    if (!props.actions?.length) return null;

    return (
        <Stack direction="row" spacing={1} sx={{ flexGrow: 1 }}>
            {props.actions.map((action, j) => {
                return action && (
                    <Tooltip arrow title={'' + action.tooltip} key={`action-${j}`}>
                        <span>
                            <IconButton
                                disabled={(action?.disableIfBodyIsEmpty && !props.totalCount) || action?.disabled}
                                onClick={(ev) => { action.onClick(ev, props.filters); }}
                                size="small"
                                sx={{ color: '#1c6e3d', width: '34px', height: '34px' }}
                            >
                                {action.icon}
                            </IconButton>
                        </span>
                    </Tooltip>
                );
            })}
            {_filtersCount > 1 && (
                <Tooltip arrow title={props.options?.localize?.resetFilters || tsl.resetFilters}>
                    <span>
                        <IconButton
                            disabled={!_hasFilters}
                            onClick={(ev) => { props.resetFilter(); }}
                            size="small"
                            sx={{ color: '#1c6e3d', width: '34px', height: '34px' }}
                        >
                            <RiFilterOffLine />
                        </IconButton>
                    </span>
                </Tooltip>
            )}
        </Stack>
    );
}
