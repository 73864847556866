import axios from 'axios';
import {
    AuthenticatedResponse,
    ChangePasswordRequest,
    ConfirmEmailRequestType,
    ConfirmEmailResponseType,
    ConfirmForgotPasswordRequestType,
    RegistrationRequest,
    SendChangeUsernameRequestType,
    SendForgotPasswordRequestType,
    SignInRequest,
    SignInResponse
} from '../types/auth.types';

const baseUrl = '/api/auth';

export const SignIn =
    async (request: SignInRequest) => await axios.post<SignInResponse>(`${baseUrl}/signin`, request.data);

export const SignOut =
    async () => await axios.post<void>(`${baseUrl}/signout`);

export const IsAuthenticated =
    async () => await axios.post<AuthenticatedResponse>(`${baseUrl}/isauthenticated`);

export const SendForgotPasswordRequest =
    async (request: SendForgotPasswordRequestType) => await axios.post(`${baseUrl}/sendforgotpasswordrequest`, request.data);

export const ConfirmForgotPasswordRequest =
    async (request: ConfirmForgotPasswordRequestType) => await axios.post(`${baseUrl}/confirmforgotpasswordrequest`, request.data);

export const SendChangeUsernameRequest =
    async (request: SendChangeUsernameRequestType) => await axios.post(`${baseUrl}/sendchangeusernamerequest`, request.data);

export const ConfirmEmailRequest =
    async (request: ConfirmEmailRequestType) => await axios.post<ConfirmEmailResponseType>(`${baseUrl}/confirmemailrequest`, request.data);

export const ChangePassword =
    async (request: ChangePasswordRequest) => await axios.post(`${baseUrl}/changePassword`, request.data);

export const CallRegistration =
    async (request: RegistrationRequest) => await axios.post(`${baseUrl}/registration`, request.data);
