export const CFFRegex = {
    email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    username: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!.,?;#$%^&*()_+]).{7,}$/,
    code: /[0-9,A-Z]{6}/,
    ip: /^[0-9]{1,}\.[0-9]{1,}\.[0-9]{1,}\.[0-9]{1,}$/
}

export const CFFDateFormat = {
    long: "DD/MM/YYYY, HH:mm:ss",
    short: "DD/MM/YYYY"
}

export const COMMON_IP_VALIDATOR = (ip: string) => !CFFRegex.ip.test(ip);
export const COMMON_EMAIL_VALIDATOR = (email: string) => !CFFRegex.email.test(email);
export const COMMON_PSW_VALIDATOR = (psw: string) => !CFFRegex.password.test(psw);
export const COMMON_CODE_VALIDATOR = (code: string) => !CFFRegex.code.test(code);

export const CFFRegexValidators = {
    email: (email: string) => !CFFRegex.email.test(email),
}
