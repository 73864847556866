import { Refresh } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import moment from 'moment';
import { default as React } from 'react';
import { useTranslation } from 'react-i18next';
import { CFFDateFormat } from '../../../constants/appRegex';
import { RT, TFTable } from '../../../shared/components';
import { useNotifyContext } from '../../../shared/contexts';
import { GetSubscriptionsByAdmin } from '../../../shared/helpers';
import { useErrorTranslation, useMathUtilities } from "../../../shared/hooks";
import { SubscriptionApiDto } from '../../../shared/types';

interface DetailsPanelProps {
    user: {
        userId: string;
        email: string;
    };
}

export const PanelSubscriptions: React.FC<DetailsPanelProps> = (props) => {

    const { t } = useTranslation();
    const notify = useNotifyContext();
    const getErrorTranslation = useErrorTranslation();
    const { toLocalePrice } = useMathUtilities();

    const tableRef = React.useRef() as React.MutableRefObject<RT<SubscriptionApiDto>>;

    return (
        <Box px={2} sx={{ width: 'calc(100vw - 282px)' }}>
            <Grid spacing={2} container>
                <Grid xs={12} sm={12} md={12} item>
                    <TFTable<SubscriptionApiDto>
                        ref={tableRef}
                        data={(query) => {

                            return new Promise((result) => {

                                GetSubscriptionsByAdmin({
                                    params: {
                                        orderBy: query?.sorting?.field,
                                        orderByDescending: query?.sorting?.order === 'Desc',
                                        userId: props.user.userId || '',
                                        pageNr: query?.pageNumber,
                                        pageSize: query?.pageSize,
                                    }
                                }).then(({ data }) => {

                                    result({
                                        data: data.subscriptions,
                                        totalCount: data.totalCount
                                    });
                                }).catch((ev) => {

                                    const status = ev.response?.data?.errorCode || ev.response?.status || 500;
                                    const url = ev.config.url;
                                    notify.warning({
                                        content: getErrorTranslation(url, status)
                                    });
                                });
                            })
                        }}
                        actions={[
                            {
                                icon: <Refresh />,
                                tooltip: t("shared.table.TT_refresh"),
                                onClick: () => {
                                    tableRef.current.reload();
                                }
                            },
                        ]}
                        options={{
                            usePagination: true,
                            useSearch: false,
                            localize: {
                                noData: t('shared.table.gridEmptyMsg'),
                                resetFilters: t('shared.table.resetFilters'),
                                search: t('shared.table.search'),
                                displayedElementsSeparator: t('shared.table.displayedElementsSeparator'),
                                rowsForPage: t('shared.table.rowsForPage'),
                            },
                            minWidth: "300px",
                            style: {
                                maxHeight: "calc(100vh - 242px)"
                            },
                            filtersAdjustmentStyle: {
                                top: '40px'
                            }
                        }}
                        columns={[
                            {
                                title: () => t("fundDetailsPage.date"),
                                render: ({ startDate }) => moment(startDate).format(CFFDateFormat.short),
                                style: { textAlign: 'right' },
                                thStyle: { textAlign: 'right' },
                            },
                            {
                                title: () => "Quote",
                                render: ({ quotesNumber }) => toLocalePrice(quotesNumber || 0, 2, false),
                                style: { textAlign: 'right' },
                                thStyle: { textAlign: 'right' },
                            },
                            {
                                title: () => t("fundDetailsPage.totalAmount"),
                                render: ({ totalAmount }) => toLocalePrice(totalAmount || 0, 2, false),
                                style: { textAlign: 'right' },
                                thStyle: { textAlign: 'right' },
                            },
                            {
                                title: () => t("fundDetailsPage.fixExpenses"),
                                render: ({ fixExpenses }) => toLocalePrice(fixExpenses || 0, 2, false),
                                style: { textAlign: 'right' },
                                thStyle: { textAlign: 'right' },
                            },
                            {
                                title: () => t("fundDetailsPage.percentageExpenses"),
                                render: ({ percentageExpenses }) => toLocalePrice(percentageExpenses || 0, 2, false),
                                style: { textAlign: 'right' },
                                thStyle: { textAlign: 'right' },
                            },
                            {
                                title: () => "Valore attuale",
                                render: ({ expectedProfit }) => toLocalePrice(expectedProfit || 0, 2, false),
                                style: { textAlign: 'right' },
                                thStyle: { textAlign: 'right' },
                            },
                            {
                                title: () => t("fundDetailsPage.variation"),
                                render: ({ variation }) => toLocalePrice(variation || 0, 2, false),
                                style: { textAlign: 'right' },
                                thStyle: { textAlign: 'right' },
                            },
                            {
                                title: () => t("fundDetailsPage.commission"),
                                render: ({ commission }) => toLocalePrice(commission || 0, 2, false),
                                style: { textAlign: 'right' },
                                thStyle: { textAlign: 'right' },
                            },
                        ]}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
