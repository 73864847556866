import { Components } from "@mui/material/styles";

export const MuiLink: Components['MuiLink'] = {
    styleOverrides: {
        root: {
            display: 'flex',
            alignItems: 'center',
            gap: '4px',

            fontWeight: '500',
            textDecoration: 'none',
            color: '#1c6e3ddd',
            cursor: 'pointer',
            fontSize: '1.0rem',
            ':hover': {
                opacity: 0.8
            }
        }
    }
};
