import { Close, FilterAltOffOutlined, Refresh, Search } from '@mui/icons-material';
import { Box, Checkbox, FormControl, Grid, Icon, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, TextField, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { CategoryScale, Chart as ChartJS, Filler, Legend, LineElement, LinearScale, PointElement, Title } from 'chart.js';
import { t } from 'i18next';
import * as React from 'react';
import { MdErrorOutline } from 'react-icons/md';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { Filters, UserSubscriptionsLocationState, UserSubscriptionsPanelsMap } from '.';
import { fundStatusType } from '../../constants/appStates';
import { EmptyPage, FundCard, PageLoader, PageTitle, StyledPageContainer } from '../../shared/components/Common';
import { useNotifyContext } from '../../shared/contexts';
import { GetSubscription, GetSubscriptions } from '../../shared/helpers';
import { useErrorTranslation, useStandardCatch } from '../../shared/hooks';
import { SubscriptionWithStatisticsApiDto } from '../../shared/types';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Filler,
    Legend
);

export const PageSubscriptions = () => {

    const theme = useTheme();
    const smNoMatches = useMediaQuery(theme.breakpoints.up('md'));
    const location: Location<UserSubscriptionsLocationState> = useLocation();
    const navigate = useNavigate();

    const { state } = location;

    const notify = useNotifyContext();
    const getErrorTranslation = useErrorTranslation();
    const { baseCatch } = useStandardCatch();

    const [filters, setFilters] = React.useState<Filters>({ search: '', status: ['Current', 'Future'], ...state?.filters });
    const [tempFilters, setTempFilters] = React.useState<Omit<Filters, 'status'>>({ search: '', ...state?.filters });
    const [fundsWithOperations, setFundsWithOperations] = React.useState<SubscriptionWithStatisticsApiDto[]>([]);
    const [pageLoader, setPageLoader] = React.useState(true);

    React.useEffect(() => {
        handleFundsReload();
    }, [filters]);

    const handleFundsReload = () => {

        setPageLoader(true);
        GetSubscriptions({
            params: {
                name: filters.search,
                status: filters.status,
            }
        }).then(({ data }) => {
            setPageLoader(false);
            setFundsWithOperations(data.subscriptions);
        }).catch(ev => {
            setPageLoader(false);
            const status = ev.response?.data?.errorCode || ev.response?.status || 500;
            const url = ev.config.url;
            notify.warning({
                content: getErrorTranslation(url, status)
            });
        });
    };

    const handleFilterReset = () => {
        setTempFilters({ search: '' });
        setFilters({ search: '', status: ['Current', 'Future'] });
    };

    const handleSearch = () => {
        setFilters({
            ...filters,
            search: tempFilters.search
        });
    };

    const handleFundDetailsRedirect = (subscriptions: SubscriptionWithStatisticsApiDto, activeTab = UserSubscriptionsPanelsMap.Details) => {
        setPageLoader(true);
        GetSubscription({
            params: {
                oid: subscriptions.oid
            }
        }).then(({ data }) => {
            setPageLoader(false);
            navigate('/protected/userSubscriptions/details', {
                state: {
                    models: {
                        subscription: data.subscription,
                        activeTab
                    },
                    filters,
                } as UserSubscriptionsLocationState
            });
        }).catch(ev => {
            setPageLoader(false);
            baseCatch(ev);
        });
    };

    const handleSearchOnChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
        setTempFilters({
            ...tempFilters,
            search: ev.target.value
        });
    };

    const handleSearchClear: React.MouseEventHandler<HTMLButtonElement> = (ev) => {
        setTempFilters({ ...tempFilters, search: '' });
        setFilters({ ...filters, search: '' });
    };

    const handleStatusMultiselectOnChange = (event: SelectChangeEvent<string[]>) => {
        const { target: { value } } = event;
        if ((typeof value === 'string' && value.split(',').length > 0) || value.length > 0) {
            setFilters({ ...filters, status: typeof value === 'string' ? value.split(',') : value });
        }
    };

    const handleSearchOnKeyUp: React.KeyboardEventHandler<HTMLInputElement> = (ev) => {
        const { key } = ev;
        if (key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <StyledPageContainer>
            {smNoMatches && <PageTitle title={t("userSubscriptions.pageTitle")} />}
            {smNoMatches && (
                <Box display="flex" alignItems="center" gap={2} p={2}>
                    <Box display="flex" alignItems="center" flexWrap="wrap" gap={2} flexGrow={1}>
                        <TextField
                            label={t("shared.search")}
                            disabled={pageLoader}
                            sx={{ width: '240px' }}
                            onChange={handleSearchOnChange}
                            onKeyUp={handleSearchOnKeyUp}
                            value={tempFilters.search}
                            InputProps={{
                                startAdornment: tempFilters.search !== filters.search && (
                                    <InputAdornment position="start">
                                        <Tooltip title={t("shared.warningFilterNotApplied")}>
                                            <Icon fontSize="small">
                                                <MdErrorOutline color="var(--warning)" />
                                            </Icon>
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {tempFilters.search && (
                                            <IconButton disabled={pageLoader} onClick={handleSearchClear} edge="end">
                                                <Close />
                                            </IconButton>
                                        )}
                                        <IconButton disabled={pageLoader} onClick={handleSearch} edge="end">
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <FormControl sx={{ width: '220px' }}>
                            <InputLabel sx={{ background: 'white', px: 1 }} shrink>{t('shared.status')}</InputLabel>
                            <Select
                                disabled={pageLoader}
                                multiple
                                value={filters.status}
                                onChange={handleStatusMultiselectOnChange}
                                renderValue={(selected) => selected.map(name => t(`shared.fundStatus_${name}`)).join(', ')}
                            >
                                {[fundStatusType.closed, fundStatusType.current, fundStatusType.future].map((name, i) => (
                                    <MenuItem key={`menuitem-${i}`} value={name} dense disabled={pageLoader} sx={{ padding: 0 }}>
                                        <Checkbox checked={filters.status.indexOf(name) > -1} />
                                        <ListItemText primary={t(`shared.fundStatus_${name}`)} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Tooltip title={t("shared.TT_resetFilters")}>
                            <Box>
                                <IconButton disabled={pageLoader} onClick={handleFilterReset} sx={{ p: 0 }}>
                                    <FilterAltOffOutlined />
                                </IconButton>
                            </Box>
                        </Tooltip>
                        <Tooltip title={t("shared.TT_refreshAction")}>
                            <Box>
                                <IconButton size="small" disabled={pageLoader} onClick={handleFundsReload} sx={{ p: 0 }}>
                                    <Refresh />
                                </IconButton>
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            )}
            <Box overflow="auto" height={smNoMatches ? "calc(100vh - 145px)" : "auto"} px={2}>
                <Grid container spacing={2} sx={{ height: '100%' }}>
                    {pageLoader ? (
                        <Grid sm={12} item overflow="hidden">
                            <PageLoader />
                        </Grid>
                    ) : (
                        fundsWithOperations.length ? (
                            fundsWithOperations.map((subscriptions, i) => (
                                <Grid xs={12} sm={12} md={12} lg={6} xl={4} item key={`fundcard-${i}`}>
                                    <FundCard
                                        model={{
                                            ...subscriptions as any,
                                            balance: subscriptions.totalAmount,
                                            name: subscriptions.fundName,
                                            status: subscriptions.fundStatus,
                                        }}
                                        events={{
                                            handleDetailsClick: () => {
                                                handleFundDetailsRedirect(subscriptions);
                                            },
                                        }}
                                    />
                                </Grid>
                            ))
                        ) : (
                            <Grid sm={12} item sx={{ height: '100%' }}>
                                <EmptyPage title={t("userSubscriptionsPage.noSubscriptions")} />
                            </Grid>
                        )
                    )}
                </Grid>
            </Box>
        </StyledPageContainer>
    );
};
