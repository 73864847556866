import * as React from 'react';
import { Alert, AlertProps, AlertTitle, Snackbar } from '@mui/material';

interface NotifyContextProps {
    open?: boolean;
    title?: string | React.ReactElement;
    content?: string | React.ReactElement;
    severity?: AlertProps['severity'];
}

const NotifyContext = React.createContext<React.Dispatch<React.SetStateAction<NotifyContextProps>>>(() => { });

export const NotifyContextProvider: React.FC<{
    children: React.ReactElement;
}> = ({ children }) => {
    const [alert, setAlert] = React.useState<NotifyContextProps>({ open: false, severity: 'success', content: '' });
    const clearAlert = () => { setAlert({ ...alert, open: false }); };
    return (
        <NotifyContext.Provider value={setAlert}>
            {children}
            <Snackbar
                open={alert.open}
                autoHideDuration={3000}
                onClose={clearAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity={alert.severity}>
                    {alert.title && <AlertTitle>{alert.title}</AlertTitle>}
                    {alert.content}
                </Alert>
            </Snackbar>
        </NotifyContext.Provider>
    );
};

export const useNotifyContext = () => {
    const setAlert = React.useContext(NotifyContext);
    const warning = React.useCallback(
        (rest: Partial<NotifyContextProps>) => setAlert({ severity: 'warning', open: true, ...rest }),
        [setAlert]
    );
    const success = React.useCallback(
        (rest: Partial<NotifyContextProps>) => setAlert({ severity: 'success', open: true, ...rest }),
        [setAlert]
    );
    const error = React.useCallback(
        (rest: Partial<NotifyContextProps>) => setAlert({ severity: 'error', open: true, ...rest }),
        [setAlert]
    );
    return {
        warning,
        success,
        error,
        setAlert
    };
};
