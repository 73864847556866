import { Close } from '@mui/icons-material';
import { IconButton, MenuItem, Select, TextField } from '@mui/material';
import * as React from 'react';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { RiSearchLine } from 'react-icons/ri';
import { Filters, SortingTypes } from './TFTable';

/** Traduzioni di default per le sezioni della tabella */
const tsl = {
    search: 'Search'
}

interface ConditionalSortingProps {
    order: SortingTypes;
    field: string;
    submit: (filters: Partial<Filters>) => void;     // Evento di applicazione del filtro
}

export const ConditionalSorting: (props: ConditionalSortingProps) => React.ReactElement = (props) => {

    if (props.order === 'Asc')
        return (
            <IconButton
                size="small"
                style={{ fontSize: '16px' }}
                onClick={() => {
                    props.submit({
                        sorting: {
                            field: props.field,
                            order: 'Desc'
                        }
                    });
                }}
            >
                <FaSortDown style={{ fill: 'var(--primary)' }} />
            </IconButton>
        );

    if (props.order === 'Desc')
        return (
            <IconButton
                size="small"
                style={{ fontSize: '16px' }}
                onClick={() => {
                    props.submit({
                        sorting: {
                            field: props.field,
                            order: 'Asc'
                        }
                    });
                }}
            >
                <FaSortUp style={{ fill: 'var(--primary)' }} />
            </IconButton>
        );

    return (
        <IconButton
            size="small"
            style={{ fontSize: '16px' }}
            onClick={() => {
                props.submit({
                    sorting: {
                        field: props.field,
                        order: 'Asc'
                    }
                });
            }}
        >
            <FaSort style={{ fill: 'var(--primary)' }} />
        </IconButton>
    );
}

interface TextFilterProps {
    id: string,
    search?: string,
    filter: {
        filter: string | null,
        field: string;
    },
    submit: (filters: Partial<Filters>) => void;     // Evento di applicazione del filtro
}

export const TextFilter: (props: TextFilterProps) => React.ReactElement = (props) => {

    const [tempFilter, setTempFilter] = React.useState<string>(props.filter.filter || '');
    const [filter, setFilter] = React.useState<string>(props.filter.filter || '');

    React.useEffect(() => {

        setTempFilter(props.filter.filter || '');
        setFilter(props.filter.filter || '');

    }, [props.filter.filter]);

    const onKeyPressEventHandler = (event: any) => {

        if (event.key === 'Enter') {

            setFilter(tempFilter);
            props.submit({
                filters: [{
                    field: props.filter.field,
                    filter: tempFilter.trim()
                }]
            })
        }
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flexGrow: 1 }}>
                <TextField
                    size="small"
                    placeholder={props?.search || tsl.search}
                    sx={{
                        color: 'var(--primary)',
                        border: 'unset',
                        '.MuiOutlinedInput-root': {
                            borderRadius: 'unset!important',
                            border: 'unset!important',
                        },
                        fontFamily: 'Roboto!important',
                        marginRight: '0px!important',
                        paddingRight: '0px!important',
                    }}
                    onKeyPress={onKeyPressEventHandler}
                    InputProps={{
                        endAdornment: (

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {filter && (
                                    <IconButton
                                        size='small'
                                        data-target-id={props.id}
                                        onClick={() => {

                                            setFilter('');
                                            setTempFilter('');
                                            props.submit({
                                                filters: [{
                                                    field: props.filter.field,
                                                    filter: ''
                                                }]
                                            })
                                        }}
                                    >
                                        <Close style={{ fill: '#1c6e3d', fontSize: '23px' }} />
                                    </IconButton>
                                )}
                                <IconButton
                                    size='small'
                                    data-target-id={props.id}
                                    onClick={() => {

                                        setFilter(tempFilter.trim());
                                        props.submit({
                                            filters: [{
                                                field: props.filter.field,
                                                filter: tempFilter.trim()
                                            }]
                                        })
                                    }}
                                >
                                    <RiSearchLine style={{ fill: '#1c6e3d' }} />
                                </IconButton>
                            </div>
                        )
                    }}
                    onBlur={(ev) => {

                        if ((ev.relatedTarget as any)?.dataset?.targetId !== props.id) {

                            setTempFilter(filter);
                        }
                    }}
                    onChange={(ev) => {

                        setTempFilter(ev?.currentTarget?.value || '')
                    }}
                    value={tempFilter}
                />
            </div>
        </div>
    );
}

interface SelectFilterProps {
    options: { text: string, value: string }[],
    filter: {
        filter: string | null,
        field: string;
    },
    submit: (filters: Partial<Filters>) => void;     // Evento di applicazione del filtro
}

export const SelectFilter: (props: SelectFilterProps) => React.ReactElement = (props) => {

    const [tempFilter, setTempFilter] = React.useState<string>(props.filter.filter || '');

    React.useEffect(() => {

        setTempFilter(props.filter.filter || '');

    }, [props.filter.filter]);

    return (
        <Select
            value={tempFilter}
            sx={{
                border: 'unset',
                fontFamily: 'Roboto!important',
                ':hover': {
                    border: 'unset!important',
                },
                color: tempFilter === '' ? '#9DA6AD' : 'var(--primary)'
            }}
            onChange={(ev: any) => {
                setTempFilter(ev.target.value || '');
                props.submit({
                    filters: [{
                        field: props.filter.field,
                        filter: ev.target.value || ''
                    }]
                })
            }}
            displayEmpty
        >
            {props.options.map(({ text, value }, i) => {
                return (
                    <MenuItem key={i} value={value || ''} sx={{
                        fontFamily: 'Roboto!important',
                        fontSize: '14px'
                    }}>{text}</MenuItem>
                );
            })}
        </Select>
    );
}


