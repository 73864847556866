import { Components } from "@mui/material/styles";

export const MuiListItemButton: Components['MuiListItemButton'] = {
    styleOverrides: {
        root: {
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
        }
    }
};
