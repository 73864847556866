import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Avatar, Button, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaPencil, FaUser } from 'react-icons/fa6';
import { CFFRegex, COMMON_EMAIL_VALIDATOR } from '../../constants/appRegex';
import { StyledPageContainer, StyledPageTitleToolbar } from '../../shared/components/Common';
import { useLoaderContext, useNotifyContext, useProfileContext } from '../../shared/contexts';
import { ChangePassword, SendChangeUsernameRequest } from '../../shared/helpers';
import { useErrorTranslation, useTextFieldVisibility } from '../../shared/hooks';
import { CFF } from '../../shared/types';

export const useUserPage = () => {

    const { t } = useTranslation();
    const notify = useNotifyContext();
    const { setLoader } = useLoaderContext();
    const getErrorTranslation = useErrorTranslation();
    const { profile } = useProfileContext();

    const [usernameChangeDialog, setUsernameChangeDialog] = React.useState({
        profile: {} as CFF.ApplicationUser,
        profileErrors: {} as Record<string, string | boolean>,
        open: false
    });
    const [passwordChangeDialog, setPasswordChangeDialog] = React.useState({
        profile: {} as {
            oldPassword: string;
            newPassword: string;
            confirmPassword: string;
        },
        profileErrors: {} as Record<string, string | boolean>,
        open: false
    });

    const togglePasswordDialogState = () => {
        setPasswordChangeDialog({
            profile: passwordChangeDialog.open ?
                passwordChangeDialog.profile :
                {} as {
                    oldPassword: string;
                    newPassword: string;
                    confirmPassword: string;
                },
            profileErrors: passwordChangeDialog.open ?
                passwordChangeDialog.profileErrors :
                {} as Record<string, string | boolean>,
            open: !passwordChangeDialog.open
        });
    }
    const toggleUsernameDialogState = () => {
        setUsernameChangeDialog({
            profile: usernameChangeDialog.open ?
                usernameChangeDialog.profile :
                {} as CFF.ApplicationUser,
            profileErrors: usernameChangeDialog.open ?
                usernameChangeDialog.profileErrors
                : {} as Record<string, string | boolean>,
            open: !usernameChangeDialog.open
        });
    }

    const handleUsernameChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {

        setUsernameChangeDialog({
            ...usernameChangeDialog,
            profile: {
                ...usernameChangeDialog.profile,
                email: ev.target.value
            },
            profileErrors: {
                email: COMMON_EMAIL_VALIDATOR(ev.target.value) ? t("validations.email") : false
            }
        });
    }

    const handleUsernameSubmit = () => {

        const emailError = COMMON_EMAIL_VALIDATOR(usernameChangeDialog.profile.email);
        setUsernameChangeDialog({
            ...usernameChangeDialog,
            profileErrors: {
                email: emailError ? t("validations.email") : false
            }
        });
        if (!emailError) {

            setLoader(true);

            SendChangeUsernameRequest({
                data: usernameChangeDialog.profile
            })
                .then(() => {
                    setLoader(false);
                    notify.success({
                        content: t("success:SendChangeUsernameRequest")
                    });
                    toggleUsernameDialogState();
                })
                .catch(ev => {
                    setLoader(false);
                    const status = ev.response?.data?.errorCode || ev.response?.status || 500;
                    const url = ev.config.url;
                    notify.warning({
                        content: getErrorTranslation(url, status)
                    });
                });
        }
    }

    const handleOldPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {

        setPasswordChangeDialog({
            ...passwordChangeDialog,
            profile: {
                ...passwordChangeDialog.profile,
                oldPassword: ev.target.value
            },
            profileErrors: {
                ...passwordChangeDialog.profileErrors,
                oldPassword: !CFFRegex.password.test(ev.target.value) ? t("validations.oldPassword") : false,
            }
        });
    }

    const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {

        setPasswordChangeDialog({
            ...passwordChangeDialog,
            profile: {
                ...passwordChangeDialog.profile,
                newPassword: ev.target.value
            },
            profileErrors: {
                ...passwordChangeDialog.profileErrors,
                newPassword: !CFFRegex.password.test(ev.target.value) ? t("validations.newPassword") : false,
                confirmPassword: !(ev.target.value === passwordChangeDialog.profile.confirmPassword) ? t("validations.confirmPassword") : false
            }
        });
    }

    const handleConfirmPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {

        setPasswordChangeDialog({
            ...passwordChangeDialog,
            profile: {
                ...passwordChangeDialog.profile,
                confirmPassword: ev.target.value
            },
            profileErrors: {
                ...passwordChangeDialog.profileErrors,
                confirmPassword: !(passwordChangeDialog.profile.newPassword && passwordChangeDialog.profile.newPassword === ev.target.value) ? t("validations.confirmPassword") : false
            }
        });
    }

    const handlePasswordSubmit = () => {

        const passwordError = !CFFRegex.password.test(passwordChangeDialog.profile.newPassword) || !CFFRegex.password.test(passwordChangeDialog.profile.oldPassword) ;
        const confirmPasswordError = !(passwordChangeDialog.profile.newPassword && passwordChangeDialog.profile.newPassword === passwordChangeDialog.profile.confirmPassword);
        setPasswordChangeDialog({
            ...passwordChangeDialog,
            profileErrors: {
                oldPassword: passwordError ? t("validations.password") : false,
                newPassword: passwordError ? t("validations.password") : false,
                confirmPassword: confirmPasswordError ? t("validations.confirmPassword") : false,
            }
        });
        if (!(passwordError || confirmPasswordError)) {

            setLoader(true);

            ChangePassword({
                data: {
                    ...passwordChangeDialog.profile,
                    id: profile.id
                }
            })
                .then(() => {
                    setLoader(false);
                    togglePasswordDialogState();
                    notify.success({
                        content: t("success:changePassword")
                    });
                })
                .catch(ev => {
                    setLoader(false);
                    const status = ev.response?.data?.errorCode || ev.response?.status || 500;
                    const url = ev.config.url;
                    notify.warning({
                        content: getErrorTranslation(url, status)
                    });
                });
        }
    }

    return {
        models: {
            profile,
            passwordChangeDialog,
            togglePasswordDialogState,
            usernameChangeDialog,
        },
        events: {
            handleConfirmPasswordChange,
            handleOldPasswordChange,
            handlePasswordSubmit,
            handlePasswordChange,
            handleUsernameSubmit,
            toggleUsernameDialogState,
            handleUsernameChange
        }
    }
}

export const PageUser = () => {

    const { t } = useTranslation();
    const confirmPassword  = useTextFieldVisibility();
    const newPassword = useTextFieldVisibility();
    const oldPassword = useTextFieldVisibility();

    const {
        models: {
            profile,
            passwordChangeDialog,
            togglePasswordDialogState,
            usernameChangeDialog,
        },
        events: {
            handleConfirmPasswordChange,
            handleOldPasswordChange,
            handlePasswordSubmit,
            handlePasswordChange,
            handleUsernameSubmit,
            toggleUsernameDialogState,
            handleUsernameChange
        }
    } = useUserPage();

    return (
        <StyledPageContainer>
            <StyledPageTitleToolbar sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2, py: 4 }}>
                <Avatar sx={{ backgroundColor: '#1c6e3d' }}>
                    <FaUser />
                </Avatar>
                <Typography sx={{ fontSize: '1rem', color: '#1c6e3d', fontWeight: '500' }}>
                    {t("userPage.userInfo")}
                </Typography>
            </StyledPageTitleToolbar>
            <Container maxWidth="sm">
                <Paper>
                    <Grid container spacing={1}>
                        <Grid sm={12} item>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ color: '#888', width: '70px' }}>
                                            {t("userPage.code")}
                                        </TableCell>
                                        <TableCell>
                                            {profile.id}
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ color: '#888', width: '70px' }}>
                                            {t("userPage.email")}
                                        </TableCell>
                                        <TableCell>
                                            {profile.email}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton size="small" sx={{ fontSize: '0.8rem' }} onClick={toggleUsernameDialogState}>
                                                <FaPencil />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ color: '#888', width: '70px' }}>
                                            {t("userPage.password")}
                                        </TableCell>
                                        <TableCell>
                                            xxxxxxxxxx
                                        </TableCell>
                                        <TableCell>
                                            <IconButton size="small" sx={{ fontSize: '0.8rem' }} onClick={togglePasswordDialogState}>
                                                <FaPencil />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ color: '#888', width: '70px' }}>
                                            {t("userPage.role")}
                                        </TableCell>
                                        <TableCell>
                                            {profile.roles?.map((role, key) => (
                                                <Chip
                                                    size="small"
                                                    key={`role-box-${key}`}
                                                    sx={{
                                                        backgroundColor: 'var(--role-' + role + '-color-wo)',
                                                        color: 'var(--role-' + role + '-color)',
                                                        mr: 1
                                                    }}
                                                    label={t(`shared.${role}`)}
                                                />
                                            ))}
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
            <Dialog
                sx={{
                    '& .MuiDialogTitle-root': {
                        color: '#1c6e3d',
                        borderBottom: '2px solid #1c6e3d',
                        backgroundColor: 'white'
                    },
                }}
                open={passwordChangeDialog.open}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle>{t("userPage.dialog_ChangePassword_Title")}</DialogTitle>
                <DialogContent >
                    <Grid container spacing={2} pt={2}>
                        <Grid xs={12} item>
                            <TextField
                                label={`${t("userPage.oldPassword")}*`}
                                type={oldPassword.visibility ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={oldPassword.setVisibility}
                                                edge="end"
                                            >
                                                {oldPassword.visibility ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                name="oldPassword"
                                value={passwordChangeDialog.profile.oldPassword}
                                onChange={handleOldPasswordChange}
                                error={!!passwordChangeDialog.profileErrors.oldPassword}
                                helperText={passwordChangeDialog.profileErrors.oldPassword}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <TextField
                                label={`${t("userPage.newPassword")}*`}
                                type={newPassword.visibility ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={newPassword.setVisibility}
                                                edge="end"
                                            >
                                                {newPassword.visibility ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                name="password"
                                value={passwordChangeDialog.profile.newPassword}
                                onChange={handlePasswordChange}
                                error={!!passwordChangeDialog.profileErrors.newPassword}
                                helperText={passwordChangeDialog.profileErrors.newPassword}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <TextField
                                label={`${t("userPage.confirmPassword")}*`}
                                type={confirmPassword.visibility ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={confirmPassword.setVisibility}
                                                edge="end"
                                            >
                                                {confirmPassword.visibility ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                name="confirmPassword"
                                value={passwordChangeDialog.profile.confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                error={!!passwordChangeDialog.profileErrors.confirmPassword}
                                helperText={passwordChangeDialog.profileErrors.confirmPassword}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: '#eee' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            togglePasswordDialogState();
                        }}
                    >
                        {t("shared.close")}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handlePasswordSubmit}
                    >
                        {t("shared.save")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                sx={{
                    '& .MuiDialogTitle-root': {
                        color: '#1c6e3d',
                        borderBottom: '2px solid #1c6e3d',
                        backgroundColor: 'white'
                    },
                }}
                open={usernameChangeDialog.open}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle>{t("userPage.dialog_ChangeUsername_Title")}</DialogTitle>
                <DialogContent >
                    <Grid container spacing={2} pt={2}>
                        <Grid xs={12} item>
                            <TextField
                                label={`${t("userPage.email")}*`}
                                name="username"
                                value={usernameChangeDialog.profile.email}
                                onChange={handleUsernameChange}
                                error={!!usernameChangeDialog.profileErrors.email}
                                helperText={usernameChangeDialog.profileErrors.email}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: '#eee' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            toggleUsernameDialogState();
                        }}
                    >
                        {t("shared.close")}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleUsernameSubmit}
                    >
                        {t("shared.save")}
                    </Button>
                </DialogActions>
            </Dialog>
        </StyledPageContainer>
    );
};
