import { styled, Drawer, ListItemIcon, Box } from "@mui/material";

const drawerWidth = 250;

export const CFFDrawer = styled(Drawer)(() => ({
    zIndex: 1,
    boxSizing: 'border-box',
    width: drawerWidth,
    '& .MuiDrawer-paper': {
        borderColor: 'var(--primary-wo)',
        boxShadow: 'unset',
        width: drawerWidth,
        backgroundColor: 'var(--background)'
    }
}));

export const StyledCFFDrawerListItemIcon = styled(ListItemIcon)(() => ({
    minWidth: 0,
    marginRight: '8px',
    color: 'var(--primary)',
}));

export const StyledCFFDrawerListItemText = styled(Box)(() => ({
    color: 'var(--primary)',
    fontWeight: '500',
}));
