import { Add, Refresh } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EmptyWalletsPage, PageLoader, PageTitle, StyledPageContainer, WalletCard } from '../../shared/components/Common';
import { useDialogContext, useLoaderContext, useNotifyContext, useProfileContext } from '../../shared/contexts';
import { DeleteWallet, GetWallet, GetWallets } from '../../shared/helpers';
import { useErrorTranslation, useStandardCatch } from '../../shared/hooks';
import { WalletApiDto } from '../../shared/types';

type Wallet = {
    code: number;
    userId: string;
    name: string;
    description: string;
    creationDate: string;
    amount: string;
    enabled: boolean;
    deactivationDate: string;
}


const useWalletsPage = () => {

    const { setLoader } = useLoaderContext();
    const navigate = useNavigate();
    const profileContext = useProfileContext();
    const setDialog = useDialogContext();
    const { t } = useTranslation();
    const notify = useNotifyContext();
    const getErrorTranslation = useErrorTranslation();

    const { baseCatch } = useStandardCatch();
    const [pageLoader, setPageLoader] = React.useState(true);
    const [wallets, setWallets] = React.useState<WalletApiDto[]>([]);

    React.useEffect(() => {
        handleGetWalletReload();
    }, []);

    const handleGetWalletReload = () => {

        setPageLoader(true);
        GetWallets({
            params: {
                userId: profileContext.profile.id,
                enabled: true
            }
        })
            .then(({ data }) => {
                setPageLoader(false);
                setWallets(data.wallets);
            })
            .catch((ev) => {
                setPageLoader(false);
                baseCatch(ev);
            });
    }

    const handleWalletDetailsRedirect = (wallet: Wallet, tab = 0) => {

        setLoader(true);

        GetWallet({
            params: {
                code: wallet.code,
                userId: profileContext.profile.id
            }
        })
            .then(({ data }) => {
                setLoader(false);
                navigate('/protected/wallets/details', { state: { wallet: data.wallet, tab } });
            })
            .catch(ev => {
                setLoader(false);
                baseCatch(ev);
            });
    };

    const handleAddWallet = () => {
        navigate('/protected/wallets/details');
    };

    const handleDeleteClick = React.useCallback((wallet: WalletApiDto) => {

        setDialog({
            title: t("shared.dialogRemove_title"),
            content: t("walletsPage.dialog_RemoveWallet_body", { name: wallet.name }),
            yesCallback: (next) => {

                setLoader(true);
                DeleteWallet({
                    params: {
                        code: wallet.code
                    },
                    data: {
                        ...wallet,
                        enabled: false
                    }
                })
                    .then(() => {
                        notify.success({
                            content: t("success:deleteWallet")
                        });
                        setLoader(false);
                        next();
                        handleGetWalletReload();
                    })
                    .catch(ev => {
                        setLoader(false);
                        const status = ev.response?.data?.errorCode || ev.response?.status || 500;
                        const url = ev.config.url;
                        notify.warning({
                            content: getErrorTranslation(url, status)
                        });
                    })
            },
            noCallback: (next) => {
                next();
            }
        });
    }, []);

    return {
        models: {
            profileContext,
            wallets,
            loader: pageLoader
        },
        events: {
            handleDeleteClick,
            handleRefresh: handleGetWalletReload,
            handleWalletDetailsRedirect,
            handleAddWallet
        }
    }
}

export const PageWallets = () => {

    const { t } = useTranslation();

    const theme = useTheme();
    const smNoMatches = useMediaQuery(theme.breakpoints.up('md'));

    const {
        models: {
            wallets,
            loader
        },
        events: {
            handleDeleteClick,
            handleRefresh,
            handleWalletDetailsRedirect,
            handleAddWallet
        }
    } = useWalletsPage();

    return (
        <StyledPageContainer>
            <PageTitle title={t("shared.sectionWallets")} />
            {smNoMatches && (
                <Box display="flex" alignItems="center" gap={2} p={2}>
                    <Box display="flex" alignItems="center" flexWrap="wrap" gap={2} flexGrow={1}>
                        <Tooltip title={t("walletsPage.TT_refreshAction")}>
                            <Box>
                                <IconButton size="small" disabled={loader} onClick={handleRefresh} sx={{ p: 0 }}>
                                    <Refresh />
                                </IconButton>
                            </Box>
                        </Tooltip>
                    </Box>
                    <Button onClick={handleAddWallet} startIcon={<Add />}>
                        {t('walletsPage.addWallet')}
                    </Button>
                </Box>
            )}
            <Box overflow="auto" height={smNoMatches ? "calc(100vh - 155px)" : "auto"} px={2}>
                <Grid container spacing={2}>
                    {loader ? (
                        <Grid sm={12} item overflow="hidden">
                            <PageLoader />
                        </Grid>
                    ) : (
                        wallets.length ? (
                            wallets.map((wallet, i) => (
                                <Grid xs={12} sm={12} md={12} lg={4} xl={4} item key={`fundcard-${i}`}>
                                    <WalletCard
                                        model={wallet}
                                        events={{
                                            handleDetailsClick: () => {
                                                handleWalletDetailsRedirect(wallet);
                                            },
                                            handleDeleteClick: () => {
                                                handleDeleteClick(wallet);
                                            },
                                        }}
                                    />
                                </Grid>
                            ))
                        ) : (
                            <Grid sm={12} item sx={{ height: '100%' }}>
                                <EmptyWalletsPage />
                            </Grid>
                        )
                    )}
                </Grid>
            </Box>
        </StyledPageContainer>
    );
};
