import axios from 'axios';
import {
    AddSubscriptionRequest,
    AddSubscriptionResponse,
    GetSubscriptionRequest,
    GetSubscriptionResponse,
    GetSubscriptionsByAdminRequest,
    GetSubscriptionsByAdminResponse,
    GetSubscriptionsRequest,
    GetSubscriptionsResponse
} from '../types/subscriptions.types';

const baseUrl = '/api/subscriptions';

export const GetSubscription =
    async (request: GetSubscriptionRequest) => await axios.get<GetSubscriptionResponse>(`${baseUrl}/${request.params.oid}`);
    
export const GetSubscriptions =
    async (request: GetSubscriptionsRequest) => await axios.get<GetSubscriptionsResponse>(`${baseUrl}`, { params: request.params });

export const AddSubscription =
    async (request: AddSubscriptionRequest) => await axios.post<AddSubscriptionResponse>(`${baseUrl}`, request.data);
    
export const GetSubscriptionsByAdmin =
    async (request: GetSubscriptionsByAdminRequest) => await axios.get<GetSubscriptionsByAdminResponse>(`${baseUrl}/platform`, { params: request.params });


