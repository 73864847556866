import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as app_it from '../translations/it/app_it.json';
import * as errors_it from '../translations/it/errors_it.json';
import * as app_it_success from '../translations/it/app_it.success.json';

i18n.use(initReactI18next).init({
    resources: {
        it: {
            translation: app_it,
            errors: errors_it,
            success: app_it_success
        }
    },
    lng: 'it',
    fallbackLng: 'it',
    interpolation: {
        escapeValue: false
    }
});
