export type FundStatusType = "Closed" | "Current" | "Future" | "Open";
export const fundStatusType: Record<string, FundStatusType > = {
    closed: "Closed",
    current: "Current",
    future: "Future",

    open: "Open"
};

export const backTestTimes = {
    '1m': 1,
    '3m': 3,
    '6m': 6,
    '12m': 12,
}
