import { Components } from "@mui/material/styles";

export const MuiSelect: Components['MuiSelect'] = {
    defaultProps: {
        size: 'small',
    },
    styleOverrides: {
        root: {
            '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--primary-wo)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--primary-wo)',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--primary-wo)',
            },
            '.MuiSvgIcon-root ': {
                fill: "var(--primary-wo) !important",
            }
        }
    }
};
