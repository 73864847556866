import { Box, FormControl, MenuItem, Select } from '@mui/material';
import * as React from 'react';
import { Filters } from './TFTable';

/** Traduzioni di default per le sezioni della tabella */
const tsl = {
    rowsForPage: 'Rows for page:',
    displayedElementsSeparator: 'of',
}

/** Stili di default per le sezioni della tabella */
const paginationContainer = {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px'
};

// Proprietà applicabili alla sezione legata alla paginazione
interface VdsTablePaginationProps {
    usePagination?: boolean;           // false se la paginazione non deve essere utilizzata
    pageSize: number;           // Numero di righe totali per pagina
    totalCount: number;         // Numero di record totali
    rowsPerPageOptions: number[];   // Valori consentiti per la gestione delle righe per pagina di default [5, 10, 25]
    loader?: boolean;           // undefined se il set di dati è una lista
    rowsForPage?: string;       // Traduzione realativa al numero di righe
    displayedElementsSeparator?: string;    // Traduzione realativa al separatore per le pagine    
    submit: (filters: Partial<Filters>) => void;     // Evento di applicazione del filtro
}

export const ConditionalPageSizePagination: (props: VdsTablePaginationProps) => React.ReactElement | null = (props) => {

    if (!props.usePagination) return null;

    return (
        <Box component="span" sx={paginationContainer}>
            <Box component="span">
                {/* {props?.rowsForPage || tsl.rowsForPage} */}
            </Box>
            <FormControl sx={{ 'fieldset': { border: 'unset' } }} >
                <Select
                    value={props.pageSize}
                    sx={{ fontSize: '14px', mt: '4px' }}
                    onChange={(ev) => props.submit({ pageSize: Number(ev.target.value) })}
                >
                    {props.rowsPerPageOptions.map((value, i) => (
                        <MenuItem
                            value={value}
                            key={`menuitem-${i}`}
                            style={{ fontSize: '14px' }}
                        >
                            {value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {props?.loader && (<Box component="span">-</Box>)}
                {!props?.loader && (<Box component="span">{props.pageSize >= props.totalCount ? props.totalCount : props.pageSize} {props?.displayedElementsSeparator || tsl.displayedElementsSeparator} {props.totalCount}</Box>)}
            </Box>
        </Box>
    );
}
