import { Box, Breadcrumbs, Link, Tab, Tabs, TabsOwnProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FundPanelsMap, useFundLocationState, useRedirectToPageFunds } from '.';
import { routes } from '../../constants/appRoutes';
import { fundStatusType } from '../../constants/appStates';
import { PageTitle, StyledPageContainer } from '../../shared/components/Common';
import { useLoaderContext, useProfileContext } from "../../shared/contexts";
import { GetFund } from '../../shared/helpers';
import { useStandardCatch } from '../../shared/hooks';
import { FundApiDto } from '../../shared/types/fund.types';
import { PanelBackTests } from './components/PanelBackTests';
import { PanelGeneralDetails } from './components/PanelGeneralDetails';
import { PanelOperations } from './components/PanelOperations';
import { PanelSecurity } from './components/PanelSecurity';
import { PanelStatistics } from './components/PanelStatistics';
import { PanelSubscriptions } from './components/PanelSubscriptions';

export const useRedirectToPageFundDetails = () => {

    const navigate = useNavigate();
    const { setLoader } = useLoaderContext();
    const { baseCatch } = useStandardCatch();
    const { isAdmin } = useProfileContext();

    return (fundOid: string, otherState: object) => {
        setLoader(true);
        GetFund({
            params: {
                oid: fundOid
            }
        }).then(({ data }) => {
            setLoader(false);
            navigate(`/${routes.protected}/${routes.funds}/${routes.details}`, {
                state: {
                    models: {
                        fund: {
                            ...data.fund,
                            balance: isAdmin ? data.fund.balance : 10000
                        }
                    },
                    ...otherState,
                }
            });
        }).catch(ev => {
            setLoader(false);
            baseCatch(ev);
        });
    }
};

export const PageFundDetails = () => {

    const theme = useTheme();
    const smNoMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const { t } = useTranslation();

    const redirectToPageFunds = useRedirectToPageFunds();
    const locationState = useFundLocationState();

    const { isAdmin } = useProfileContext();

    const [fund, setFund] = React.useState<FundApiDto>({
        oid: '',
        endDate: '',
        startDate: '',
        public: false,
        stopLoseTarget: 0,
        commission: 0,
        costs: 0,
        status: fundStatusType.open,
        description: '',
        expectedProfit: 0,
        quotesAmount: 0,
        quotesExpenses: 0,
        quotesNumber: 0,
        ipCheck: false,
        logo: '',
        name: '',
        takeProfitTarget: 0,
        ...locationState.models?.fund,
        balance: isAdmin ? (locationState.models?.fund?.balance || 0) : 10000
    });
    const [panel, setPanel] = React.useState(locationState.models?.activeTab || FundPanelsMap.Details);

    const handleChange: TabsOwnProps['onChange'] = (_ev, newValue) => { setPanel(newValue); };
    const handleRedirectToPageFunds = () => { redirectToPageFunds(locationState); };

    return (
        <StyledPageContainer>
            <PageTitle
                title={t("fundsPage.pageTitle")}
                breadcrumbs={(
                    <Breadcrumbs>
                        <Link onClick={handleRedirectToPageFunds} sx={{ fontSize: '0.8rem' }} >
                            {t("shared.sectionStore")}
                        </Link>
                        <Typography sx={{ fontSize: '0.8rem', color: '#1c6e3d', fontWeight: '500', wordBreak: 'break-all' }}>
                            {fund?.name ? fund.name : t("fundDetailsPage.new")}
                        </Typography>
                    </Breadcrumbs>
                )}
            />
            <Box>
                {fund?.oid && (
                    <Tabs value={panel} onChange={handleChange}>
                        <Tab label={t("fundDetailsPage.details")} value={FundPanelsMap.Details} />
                        {fund?.oid && isAdmin && <Tab label={t("fundDetailsPage.security")} value={FundPanelsMap.Security} />}
                        {fund?.oid && <Tab label={t("fundDetailsPage.statistics")} value={FundPanelsMap.Statistics} />}
                        {fund?.oid && <Tab label={t("fundDetailsPage.backtests")} value={FundPanelsMap.BackTests} />}
                        {fund?.oid && isAdmin && <Tab label={t("fundDetailsPage.operations")} value={FundPanelsMap.Operations} />}
                        {fund?.oid && isAdmin && <Tab label={t("fundDetailsPage.subscriptions")} value={FundPanelsMap.Subscriptions} />}
                    </Tabs>
                )}
                <Box
                    sx={{
                        boxSizing: 'border-box',
                        overflow: 'auto',
                        paddingTop: '8px',
                        borderTop: '1px solid var(--primary-wo)',
                        height: smNoMatches ? 'calc(100vh - 132px)' : 'auto',
                    }}
                >
                    {panel === FundPanelsMap.Details && (
                        <PanelGeneralDetails setFund={setFund} fund={fund} />
                    )}
                    {panel === FundPanelsMap.Statistics && fund?.oid && (
                        <PanelStatistics setFund={setFund} fund={fund} />
                    )}
                    {panel === FundPanelsMap.Security && fund?.oid && (
                        <PanelSecurity setFund={setFund} fund={fund} />
                    )}
                    {panel === FundPanelsMap.BackTests && fund?.oid && (
                        <PanelBackTests setFund={setFund} fund={fund} />
                    )}
                    {panel === FundPanelsMap.Operations && fund?.oid && (
                        <PanelOperations setFund={setFund} fund={fund} />
                    )}
                    {panel === FundPanelsMap.Subscriptions && fund?.oid && (
                        <PanelSubscriptions setFund={setFund} fund={fund} />
                    )}
                </Box>
            </Box>
        </StyledPageContainer>
    );
};
