import { Box, Grid, Icon, TextField } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaSackDollar } from 'react-icons/fa6';
import { useLocation } from 'react-router-dom';
import { CFFDateFormat } from '../../../constants/appRegex';
import { StyledDescriptionText } from '../../../shared/components/Common';
import { useMathUtilities } from '../../../shared/hooks';
import { SubscriptionApiDto } from '../../../shared/types/subscriptions.types';

export const PanelDetails = () => {

    const location = useLocation();

    const { t } = useTranslation();

    const { toLocalePrice } = useMathUtilities();

    const locationSubscription = location.state?.models?.subscription as SubscriptionApiDto;

    const subscription = React.useMemo(() => {

        const quoteNumbers = +locationSubscription.quotesNumber

        const total = quoteNumbers * locationSubscription.fund.quotesAmount;
        const expectedProfit = (locationSubscription.fund.expectedProfit * total / 100);
        const expectedCommission = (locationSubscription.fund.commission * expectedProfit) / 100;
        const totalExpenses = (locationSubscription.fund.costs + (locationSubscription.fund.quotesExpenses * quoteNumbers));

        const netProfit = quoteNumbers ? (expectedProfit - totalExpenses - expectedCommission) : 0;
        const stopLose = (locationSubscription.fund.stopLoseTarget * quoteNumbers * locationSubscription.fund.quotesAmount) / 100;
        const takeProfit = (locationSubscription.fund.takeProfitTarget * quoteNumbers * locationSubscription.fund.quotesAmount) / 100;

        return {
            total,
            totalExpenses,
            netProfit,
            expectedProfit,
            expectedCommission,
            stopLose,
            takeProfit
        };
    }, [locationSubscription]);

    return (
        <Box sx={{ height: 'calc(100vh - 86px)', overflow: 'auto' }} >
            <Box sx={{ borderBottom: "1px dashed #DCE9E0", p: 2 }} >
                <Grid spacing={2} container >
                    <Grid md={2} item>
                        <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <Box
                                sx={{
                                    width: '100px',
                                    height: '100px',
                                    borderRadius: '50%',
                                    border: '1px solid #ddd',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {locationSubscription.fund.logo ? (
                                    <img src={locationSubscription.fund.logo} width="100px" height="100px" className="img" alt="logo" style={{ borderRadius: '50%' }} />
                                ) : (
                                    <Icon
                                        className="img"
                                        sx={{
                                            color: "#222",
                                            fill: '#222',
                                            fontSize: '1.5rem'
                                        }}
                                    >
                                        <FaSackDollar />
                                    </Icon>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid md={10} item>
                        <Grid container spacing={2}>
                            <Grid xs={12} sm={12} md={4} lg={6} item>
                                <TextField
                                    label={t("fundDetailsPage.name")}
                                    defaultValue={locationSubscription.fund.name}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} sm={12} md={4} lg={3} item>
                                <TextField
                                    label={t("fundDetailsPage.startDate")}
                                    defaultValue={moment(locationSubscription.fund.startDate).format(CFFDateFormat.short)}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} sm={12} md={4} lg={3} item>
                                <TextField
                                    label={t("fundDetailsPage.endDate")}
                                    defaultValue={moment(locationSubscription.fund.endDate).format(CFFDateFormat.short)}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} sm={12} md={12} lg={12} item>
                                <TextField
                                    label={t("fundDetailsPage.description")}
                                    defaultValue={locationSubscription.fund.description}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    rows={1}
                                    multiline
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid xs={6} sm={6} md={3} lg={3} item>
                        <Box display="flex" gap={2} flexDirection="column">
                            <TextField
                                label="Portafoglio*"
                                defaultValue={locationSubscription.wallet.name}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={6} sm={6} md={3} lg={3} item>
                        <Box display="flex" gap={2} flexDirection="column">
                            <TextField
                                label="Numero di quote*"
                                defaultValue={locationSubscription.quotesNumber}
                                InputProps={{
                                    readOnly: true,
                                    inputProps: {
                                        style: { textAlign: "right" },
                                    }
                                }}
                            />
                            <TextField
                                label="Spese fisse(&euro;)*"
                                defaultValue={toLocalePrice(locationSubscription.fund.costs, 2, false)}
                                InputProps={{
                                    readOnly: true,
                                    inputProps: {
                                        style: { textAlign: "right" },
                                    }
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={6} sm={6} md={3} lg={3} item>
                        <Box display="flex" gap={2} flexDirection="column">
                            <TextField
                                label="Costo unitario(&euro;)*"
                                InputProps={{
                                    readOnly: true,
                                    inputProps: {
                                        style: { textAlign: "right" },
                                    }
                                }}
                                defaultValue={toLocalePrice(locationSubscription.fund.quotesAmount, 2, false)}
                            />
                            <TextField
                                label="Spese variabili(&euro;)*"
                                InputProps={{
                                    readOnly: true,
                                    inputProps: {
                                        style: { textAlign: "right" },
                                    }
                                }}
                                defaultValue={toLocalePrice(locationSubscription.fund.quotesExpenses * locationSubscription.quotesNumber, 2, false)}
                            />
                            <TextField
                                label="Profitto previsto(%)*"
                                InputProps={{
                                    readOnly: true,
                                    inputProps: {
                                        style: { textAlign: "right" },
                                    }
                                }}
                                defaultValue={toLocalePrice(locationSubscription.fund.expectedProfit, 2, false)}
                            />
                            <TextField
                                label="Commissioni(%)*"
                                InputProps={{
                                    readOnly: true,
                                    inputProps: {
                                        style: { textAlign: "right" },
                                    }
                                }}
                                defaultValue={toLocalePrice(locationSubscription.fund.commission, 2, false)}
                            />
                            <StyledDescriptionText sx={{ height: '40px', alignItems: 'center', displa: 'flex' }}>
                                Profitto al netto delle spese e delle commissioni
                            </StyledDescriptionText>
                            <TextField
                                label="Stop loss Target(%)*"
                                InputProps={{
                                    readOnly: true,
                                    inputProps: {
                                        style: { textAlign: "right" },
                                    }
                                }}
                                defaultValue={toLocalePrice(locationSubscription.fund.stopLoseTarget, 2, false)}
                            />
                            <TextField
                                label="Take profit target(%)*"
                                InputProps={{
                                    readOnly: true,
                                    inputProps: {
                                        style: { textAlign: "right" },
                                    }
                                }}
                                defaultValue={toLocalePrice(locationSubscription.fund.takeProfitTarget, 2, false)}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={6} sm={6} md={3} lg={3} item>
                        <Box display="flex" gap={2} flexDirection="column">
                            <TextField
                                label="Totale sottoscrizione(&euro;)*"
                                InputProps={{
                                    readOnly: true,
                                    inputProps: {
                                        style: { textAlign: "right" },
                                    }
                                }}
                                defaultValue={toLocalePrice(locationSubscription.totalAmount, 2, false)}
                            />
                            <TextField
                                label="Totale spese(&euro;)*"
                                InputProps={{
                                    readOnly: true,
                                    inputProps: {
                                        style: { textAlign: "right" },
                                    }
                                }}
                                defaultValue={toLocalePrice(subscription.totalExpenses, 2, false)}
                            />
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                    inputProps: {
                                        style: { textAlign: "right" },
                                    }
                                }}
                                defaultValue={toLocalePrice(subscription.expectedProfit, 2, false)}
                            />
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                    inputProps: {
                                        style: {
                                            textAlign: "right"
                                        },
                                    }
                                }}
                                defaultValue={toLocalePrice(subscription.expectedCommission, 2, false)}
                            />
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                    inputProps: {
                                        style: {
                                            backgroundImage: "linear-gradient(45deg, #fff, #bdf5f5)",
                                            textAlign: "right"
                                        },
                                    }
                                }}
                                defaultValue={toLocalePrice(subscription.netProfit, 2, false)}
                            />
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                    inputProps: {
                                        style: {
                                            backgroundImage: "linear-gradient(45deg, #fff, #f5cfcf)",
                                            textAlign: "right"
                                        },
                                    }
                                }}
                                defaultValue={toLocalePrice(subscription.stopLose, 2, false)}
                            />
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                    inputProps: {
                                        style: {
                                            backgroundImage: "linear-gradient(45deg, #fff, #d2edd2)",
                                            textAlign: "right"
                                        },
                                    }
                                }}
                                defaultValue={toLocalePrice(subscription.takeProfit, 2, false)}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
