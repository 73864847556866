import { Box, Button, FormControlLabel, Grid, Icon, IconButton, Stack, Switch, TextField } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import DatePicker from "react-datepicker";
import { useTranslation } from 'react-i18next';
import { FaRegCopy } from 'react-icons/fa';
import { FaSackDollar, FaUpload } from 'react-icons/fa6';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { FundLocationState } from '..';
import { routes } from '../../../constants/appRoutes';
import { CustomDateInput, StyledDescriptionText } from '../../../shared/components/Common';
import { useDialogContext, useLoaderContext, useNotifyContext, useProfileContext } from '../../../shared/contexts';
import { AddFund, DeleteFund, UpdateFund } from '../../../shared/helpers';
import { useErrorTranslation, useStandardCatch } from '../../../shared/hooks';
import { FundApiDto } from '../../../shared/types';

interface DetailsPanelProps {
    setFund: React.Dispatch<React.SetStateAction<FundApiDto>>;
    fund: FundApiDto;
}

type Errors<R> = Record<keyof R, any>;
type Validators<R> = Partial<Record<keyof R, { pattern: (state: R) => Partial<Errors<R>>; }>>;

export const useGeneralDetailsPanel = (props: DetailsPanelProps) => {

    const navigate = useNavigate();
    const location: Location<FundLocationState> = useLocation();
    const { t } = useTranslation();

    const notify = useNotifyContext();
    const { isAdmin } = useProfileContext();
    const { setLoader } = useLoaderContext();
    const getErrorTranslation = useErrorTranslation();
    const setDialog = useDialogContext();
    const { baseCatch } = useStandardCatch();

    const validators = React.useRef<Validators<FundApiDto>>({
        name: {
            pattern: (state) => ({
                name: !state.name ? t("validations.name") : ''
            })
        },
        startDate: {
            pattern: (state) => ({
                startDate: !state.startDate ? t("validations.startDate") : ''
            })
        },
        endDate: {
            pattern: (state) => ({
                endDate: !state.endDate ? t("validations.endDate") : ''
            })
        },
        balance: {
            pattern: (state) => ({
                balance: isNaN(+state.balance) || +state.balance <= 0 ? t("validations.invalidNumber") : ''
            })
        },
        expectedProfit: {
            pattern: (state) => ({
                expectedProfit: isNaN(+state.expectedProfit) || +state.expectedProfit <= 0 ? t("validations.invalidNumber") : ''
            })
        },
        takeProfitTarget: {
            pattern: (state) => ({
                takeProfitTarget: isNaN(+state.takeProfitTarget) || +state.takeProfitTarget < 0 ? t("validations.invalidNumber") : ''
            })
        },
        stopLoseTarget: {
            pattern: (state) => ({
                stopLoseTarget: isNaN(+state.stopLoseTarget) || +state.stopLoseTarget < 0 ? t("validations.invalidNumber") : ''
            })
        },
        quotesExpenses: {
            pattern: (state) => ({
                quotesExpenses: isNaN(+state.quotesExpenses) || +state.quotesExpenses < 0 ? t("validations.invalidNumber") : ''
            })
        },
        costs: {
            pattern: (state) => ({
                costs: isNaN(+state.costs) || +state.costs < 0 ? t("validations.invalidNumber") : ''
            })
        },
        commission: {
            pattern: (state) => ({
                commission: isNaN(+state.commission) || +state.commission < 0 ? t("validations.invalidNumber") : ''
            })
        },
        quotesNumber: {
            pattern: (state) => ({
                quotesNumber: isNaN(+state.quotesNumber) || +state.quotesNumber <= 0 ? t("validations.invalidNumber") : ''
            })
        },
    });

    const [fundForm, setFundForm] = React.useState({
        ...props.fund,
        balance: isAdmin ? props.fund.balance : 10000
    });
    const [fundFormErrors, setFundFormErrors] = React.useState<Partial<Errors<FundApiDto>>>({
        name: '',
        startDate: '',
        endDate: '',
        balance: '',
        expectedProfit: '',
        takeProfitTarget: '',
        status: '',
        stopLoseTarget: '',
        quotesExpenses: '',
        costs: '',
        commission: '',
        quotesNumber: '',
    });

    const change = (name: keyof FundApiDto, value: string | number | boolean) => {
        const newState = { ...fundForm, [name]: value };
        setFundForm(newState);
        const correctValidator = validators.current[name];
        if (correctValidator?.pattern) {
            const newErrors = {
                ...fundFormErrors,
                ...correctValidator.pattern(newState)
            };
            setFundFormErrors(newErrors);
        }
    };

    const validate = (complete: () => void, fallback?: () => void) => {
        if (validators.current) {
            let _errors = { ...fundFormErrors };
            Object
                .keys(validators.current)
                .forEach((key) => {
                    const correctValidator = _.get(validators.current, key);
                    if (correctValidator?.pattern) {
                        _errors = {
                            ..._errors,
                            ...correctValidator.pattern(fundForm)
                        };
                    }
                });
            setFundFormErrors({ ..._errors });
            const hasErrors = _.values(_errors);
            const counter = _.some(hasErrors, error => error);
            if (counter && fallback) {
                fallback();
            } else if (!counter) {
                complete();
            }
        } else {
            complete();
        }
    };

    const handleSubmitForm = () => validate(() => {

        setLoader(true);

        if (fundForm.oid) {

            UpdateFund({
                params: {
                    oid: fundForm.oid
                },
                data: {
                    ...fundForm,
                    takeProfitTarget: +fundForm.takeProfitTarget,
                    stopLoseTarget: +fundForm.stopLoseTarget,
                    commission: +fundForm.commission,
                    costs: +fundForm.costs,
                    quotesAmount: +fundForm.quotesAmount,
                    quotesExpenses: +fundForm.quotesExpenses,
                    quotesNumber: +fundForm.quotesNumber,
                    balance: +fundForm.balance,
                    expectedProfit: +fundForm.expectedProfit,
                }
            })
                .then(() => {
                    notify.success({
                        content: t("success:updateFund")
                    });
                    props.setFund(fundForm);
                    setLoader(false);
                })
                .catch(ev => {
                    setLoader(false);
                    const url = '/api/funds';
                    baseCatch(ev, url);
                });
        } else {

            AddFund({
                data: {
                    ...fundForm,
                    takeProfitTarget: +fundForm.takeProfitTarget,
                    stopLoseTarget: +fundForm.stopLoseTarget,
                    commission: +fundForm.commission,
                    costs: +fundForm.costs,
                    quotesAmount: +fundForm.quotesAmount,
                    quotesExpenses: +fundForm.quotesExpenses,
                    quotesNumber: +fundForm.quotesNumber,
                    balance: +fundForm.balance,
                    expectedProfit: +fundForm.expectedProfit,
                }
            })
                .then(({ data }) => {
                    setFundForm({ ...fundForm, oid: data.oid });
                    notify.success({
                        content: t("success:addFund")
                    });
                    props.setFund({ ...fundForm, oid: data.oid });
                    setLoader(false);
                })
                .catch(ev => {
                    setLoader(false);
                    const url = '/api/funds';
                    baseCatch(ev, url);
                });
        }
    });

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        let reader = new FileReader();
        if (event.currentTarget.files?.length) {
            const file = Object(event.currentTarget.files)[0];
            reader.readAsDataURL(file);
            reader.onload = function () {
                setFundForm({ ...fundForm, logo: String(reader.result) });
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
    };

    const handleDeleteClick = React.useCallback(() => {

        setDialog({
            title: t("fundsPage.dialogDeleteTitle"),
            content: t("fundsPage.dialogDeleteContent", { name: fundForm.name }),
            yesCallback: (next) => {

                setLoader(true);
                DeleteFund({
                    params: {
                        fundId: fundForm.oid,
                    }
                }).then(() => {
                    notify.success({
                        content: t("success:deleteFund")
                    });
                    setLoader(false);
                    next();
                    navigate(`/protected/${routes.funds}`, location);
                }).catch(ev => {
                    setLoader(false);
                    const status = ev.response?.data?.errorCode || ev.response?.status || 500;
                    const url = ev.config.url;
                    notify.warning({
                        content: getErrorTranslation(url, status)
                    });
                })
            },
            noCallback: (next) => {
                next();
            }
        });
    }, []);

    const handlePublicSwitchOnChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setFundForm({ ...fundForm, public: ev.target.checked });
    };


    return {
        models: {
            validators,
            formModel: fundForm,
            formErrorModel: fundFormErrors,
        },
        events: {
            handlePublicSwitchOnChange,
            handleFileChange,
            change,
            setFundForm,
            setFundFormErrors,
            handleSubmitForm,
            handleDeleteClick,
        }
    };
};

export const ApplicationEntityLogo: React.FC<{
    logo: string | null;
    changeEvent?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
    isEditable?: boolean;
}> = ({ logo, changeEvent, isEditable }) => {

    const triggerFileUpload = React.useCallback(() => {
        document.getElementById("fileLoader")?.click();
    }, []);

    return (
        <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Box
                onClick={triggerFileUpload}
                sx={{
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%',
                    border: '1px solid #ddd',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '.img2': {
                        display: 'none'
                    },
                    ':hover': {
                        ...(!isEditable ? {} : {
                            opacity: 0.3,
                            '.img': {
                                display: 'none'
                            },
                            '.img2': {
                                display: 'initial'
                            }
                        })
                    }
                }}
            >
                <Icon className="img2">
                    <FaUpload />
                </Icon>
                {logo ? (
                    <img src={logo} width="100px" height="100px" className="img" alt="logo" style={{ borderRadius: '50%' }} />
                ) : (
                    <Icon
                        className="img"
                        sx={{
                            color: "#222",
                            fill: '#222',
                            fontSize: '1.5rem'
                        }}
                    >
                        <FaSackDollar />
                    </Icon>
                )}
                {changeEvent && <input
                    disabled={!isEditable}
                    type="file"
                    id="fileLoader"
                    onChange={e => changeEvent(e)} style={{ display: 'none' }}
                    accept=".png,.jpeg,.jpg,.svg"
                />}
            </Box>
        </Box>
    );
}

export const PanelGeneralDetails: React.FC<DetailsPanelProps> = (props) => {

    const { t } = useTranslation();
    const { isAdmin } = useProfileContext();

    const {
        models: {
            validators,
            formModel,
            formErrorModel,
        },
        events: {
            handlePublicSwitchOnChange,
            handleFileChange,
            change,
            setFundForm,
            setFundFormErrors,
            handleSubmitForm,
            handleDeleteClick,
        }
    } = useGeneralDetailsPanel(props);

    return (
        <Box px={2}>
            <Grid spacing={2} container pt={2}>
                <Grid md={2} item>
                    <ApplicationEntityLogo
                        isEditable={isAdmin}
                        changeEvent={handleFileChange}
                        logo={formModel.logo}
                    />
                </Grid>
                <Grid md={10} item>
                    <Grid container spacing={2}>
                        {isAdmin && (
                            <Grid xs={12} sm={12} md={12} item>
                                <StyledDescriptionText>
                                    {formModel.oid && (
                                        <IconButton
                                            onClick={async (data) => {
                                                const type = "text/plain";
                                                var blob = new Blob([formModel.oid], { type });
                                                await navigator.clipboard.write([new ClipboardItem({ [type]: blob })]);
                                            }}
                                        >
                                            <FaRegCopy style={{ fill: '#1c6e3d' }} />
                                        </IconButton>
                                    )}
                                    {t("shared.code")}: {formModel.oid || 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxxx'}
                                </StyledDescriptionText>
                            </Grid>
                        )}
                        <Grid xs={12} sm={12} md={8} item>
                            <TextField
                                label={`${t("fundDetailsPage.name")}*`}
                                name="name"
                                value={formModel.name || ''}
                                error={Boolean(formErrorModel?.name)}
                                helperText={formErrorModel.name}
                                onChange={(ev) => {
                                    const { value } = ev.target;
                                    change('name', value);
                                }}
                                InputProps={{
                                    readOnly: !isAdmin
                                }}
                            />
                        </Grid>
                        <Grid xs={12} sm={12} md={4} item>
                            <TextField
                                label={`${t("fundDetailsPage.balance")}*`}
                                onChange={(ev) => {
                                    const { value } = ev.target;
                                    change('balance', value);
                                }}
                                value={formModel.balance || ''}
                                name="balance"
                                error={Boolean(formErrorModel?.balance)}
                                helperText={formErrorModel.balance}
                                InputProps={{
                                    readOnly: !isAdmin,
                                    inputProps: {
                                        style: { textAlign: "right" },
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} sm={12} md={12} item>
                    <TextField
                        label={`${t("fundDetailsPage.description")}`}
                        name="description"
                        onChange={(ev) => {
                            const { value } = ev.target;
                            change('description', value);
                        }}
                        value={formModel.description || ''}
                        error={Boolean(formErrorModel?.description)}
                        helperText={formErrorModel.description}
                        InputProps={{
                            readOnly: !isAdmin
                        }}
                        multiline
                        rows={3}
                    />
                </Grid>
                <Grid xs={12} sm={12} md={4} lg={3} item>
                    <DatePicker
                        popperPlacement="right"
                        dateFormat="dd/MM/yyyy"
                        readOnly={!isAdmin}
                        onChange={(data) => {
                            const formState = {
                                ...formModel,
                                startDate: data?.toISOString() || '',
                            };
                            setFundForm(formState);
                            if (validators.current?.startDate?.pattern) {
                                setFundFormErrors({
                                    ...formErrorModel,
                                    ...validators.current.startDate?.pattern(formState),
                                });
                            }
                        }}
                        maxDate={formModel.endDate ? moment(formModel.endDate).add(-1, "day").toDate() : null}
                        selected={formModel.startDate ? new Date(formModel.startDate) : null}
                        customInput={(
                            <CustomDateInput
                                InputProps={{
                                    readOnly: !isAdmin
                                }}
                                label={`${t("fundDetailsPage.startDate")}*`}
                                error={Boolean(formErrorModel?.startDate)}
                                helperText={formErrorModel.startDate}
                                clear={() => {
                                    change('startDate', '');
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid xs={12} sm={12} md={4} lg={3} item>
                    <DatePicker
                        popperPlacement="right"
                        dateFormat="dd/MM/yyyy"
                        readOnly={!isAdmin}
                        minDate={formModel.startDate ? moment(formModel.startDate).add(1, "day").toDate() : null}
                        onChange={(date: any) => {
                            change('endDate', date);
                        }}
                        selected={formModel.endDate ? new Date(formModel.endDate) : null}
                        customInput={(
                            <CustomDateInput
                                InputProps={{
                                    readOnly: !isAdmin
                                }}
                                label={`${t("fundDetailsPage.endDate")}*`}
                                error={Boolean(formErrorModel?.endDate)}
                                helperText={formErrorModel.endDate}
                                clear={() => {
                                    change('endDate', '');
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid xs={12} sm={12} md={4} lg={6} item>
                    <FormControlLabel
                        sx={{ mt: 1 }}
                        label={`${t("fundDetailsPage.public")}`}
                        control={(
                            <Switch
                                disabled={!isAdmin}
                                checked={formModel.public}
                                onChange={handlePublicSwitchOnChange}
                            />
                        )}
                    />
                </Grid>
                <Grid xs={12} sm={12} md={3} item>
                    <TextField
                        sx={{ textAlign: 'right' }}
                        InputProps={{
                            readOnly: !isAdmin,
                            inputProps: {
                                style: { textAlign: "right" },
                            }
                        }}
                        label={`${t("fundDetailsPage.expectedProfit")}*`}
                        onChange={(ev) => {
                            const { value } = ev.target;
                            change('expectedProfit', value);
                        }}
                        value={formModel.expectedProfit || ''}
                        name="expectedProfit"
                        error={Boolean(formErrorModel?.expectedProfit)}
                        helperText={formErrorModel.expectedProfit}
                    />
                </Grid>
                <Grid xs={12} sm={12} md={3} item>
                    <TextField
                        sx={{ textAlign: 'right' }}
                        InputProps={{
                            readOnly: !isAdmin,
                            inputProps: {
                                style: { textAlign: "right" },
                            }
                        }}
                        label={`${t("fundDetailsPage.fundCommission")}`}
                        onChange={(ev) => {
                            const { value } = ev.target;
                            change('commission', value);
                        }}
                        value={formModel.commission || ''}
                        name="commission"
                        error={Boolean(formErrorModel?.commission)}
                        helperText={formErrorModel.commission}
                    />
                </Grid>
                <Grid xs={12} sm={12} md={3} item>
                    <TextField
                        sx={{ textAlign: 'right' }}
                        InputProps={{
                            readOnly: !isAdmin,
                            inputProps: {
                                style: { textAlign: "right" },
                            }
                        }}
                        label={`${t("fundDetailsPage.costs")}`}
                        onChange={(ev) => {
                            const { value } = ev.target;
                            change('costs', value);
                        }}
                        value={formModel.costs || ''}
                        name="costs"
                        error={Boolean(formErrorModel?.costs)}
                        helperText={formErrorModel.costs}
                    />
                </Grid>
                <Grid xs={12} sm={12} md={3} item>
                    <TextField
                        InputProps={{
                            readOnly: !isAdmin,
                            inputProps: {
                                style: { textAlign: "right" },
                            }
                        }}
                        label={`${t("fundDetailsPage.quotesExpenses")}`}
                        name="quotesExpenses"
                        onChange={(ev) => {
                            const { value } = ev.target;
                            change('quotesExpenses', value);
                        }}
                        value={formModel.quotesExpenses || ''}
                        error={Boolean(formErrorModel?.quotesExpenses)}
                        helperText={formErrorModel.quotesExpenses}
                    />
                </Grid>
                <Grid xs={12} sm={12} md={3} item>
                    <TextField
                        InputProps={{
                            readOnly: !isAdmin,
                            inputProps: {
                                style: { textAlign: "right" },
                            }
                        }}
                        label={`${t("fundDetailsPage.quotesNumber")}*`}
                        name="quotesNumber"
                        onChange={(ev) => {
                            const { value } = ev.target;
                            change('quotesNumber', value);
                        }}
                        value={formModel.quotesNumber || ''}
                        error={Boolean(formErrorModel?.quotesNumber)}
                        helperText={formErrorModel.quotesNumber}
                    />
                </Grid>
                <Grid xs={12} sm={12} md={3} item>
                    <TextField
                        InputProps={{
                            readOnly: !isAdmin,
                            inputProps: {
                                style: { textAlign: "right" },
                            }
                        }}
                        label={`${t("fundDetailsPage.takeProfitTarget")}`}
                        name="takeProfitTarget"
                        onChange={(ev) => {
                            const { value } = ev.target;
                            change('takeProfitTarget', value);
                        }}
                        value={formModel.takeProfitTarget || ''}
                        error={Boolean(formErrorModel?.takeProfitTarget)}
                        helperText={formErrorModel.takeProfitTarget}
                    />
                </Grid>
                <Grid xs={12} sm={12} md={3} item>
                    <TextField
                        InputProps={{
                            readOnly: !isAdmin,
                            inputProps: {
                                style: { textAlign: "right" },
                            },
                        }}
                        label={`${t("fundDetailsPage.stopLoseTarget")}`}
                        name="stopLoseTarget"
                        onChange={(ev) => {
                            const { value } = ev.target;
                            change('stopLoseTarget', value);
                        }}
                        value={formModel.stopLoseTarget || ''}
                        error={Boolean(formErrorModel?.stopLoseTarget)}
                        helperText={formErrorModel.stopLoseTarget}
                    />
                </Grid>
                {isAdmin && (
                    <Grid md={12} item>
                        <Stack spacing={2} direction="row" justifyContent="end" alignItems="center">
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                {formModel.oid && (
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleDeleteClick}
                                    >
                                        {t("shared.delete")}
                                    </Button>
                                )}
                                <Button onClick={handleSubmitForm} >
                                    {t("shared.save")}
                                </Button>
                            </Box>
                        </Stack>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}
