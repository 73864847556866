import { Refresh } from '@mui/icons-material';
import { Box, Breadcrumbs, Grid, IconButton, Link, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserLocationState } from '.';
import { EmptyWalletsPage, PageLoader, PageTitle, StyledPageContainer, WalletCard } from '../../shared/components/Common';
import { useLoaderContext, useProfileContext } from '../../shared/contexts';
import { GetWallet, GetWallets } from '../../shared/helpers';
import { useStandardCatch } from '../../shared/hooks';
import { WalletApiDto } from '../../shared/types';

type Wallet = {
    code: number;
    userId: string;
    name: string;
    description: string;
    creationDate: string;
    amount: string;
    enabled: boolean;
    deactivationDate: string;
}

export const PageAdminWallets = () => {

    const { t } = useTranslation();
    const location = useLocation();

    const state = location.state as UserLocationState;
    const theme = useTheme();
    const smNoMatches = useMediaQuery(theme.breakpoints.up('md'));

    const { setLoader } = useLoaderContext();
    const navigate = useNavigate();
    const profileContext = useProfileContext();

    const { baseCatch } = useStandardCatch();
    const [pageLoader, setPageLoader] = React.useState(true);
    const [wallets, setWallets] = React.useState<WalletApiDto[]>([]);

    React.useEffect(() => {
        handleGetWalletReload();
    }, []);

    const handleGetWalletReload = () => {

        setPageLoader(true);
        GetWallets({
            params: {
                userId: location?.state?.models?.user?.userId
            }
        })
            .then(({ data }) => {
                setPageLoader(false);
                setWallets(data.wallets);
            })
            .catch((ev) => {
                setPageLoader(false);
                baseCatch(ev);
            });
    }

    const handleWalletDetailsRedirect = (wallet: Wallet, state: any) => {

        setLoader(true);

        GetWallet({
            params: {
                code: wallet.code,
                userId: profileContext.profile.id
            }
        })
            .then(({ data }) => {
                setLoader(false);

                navigate('/protected/users/adminWalletsView/details', {
                    state: {
                        models: {
                            ...location.state.models,
                            wallet: data.wallet
                        }
                    } as UserLocationState
                });
            })
            .catch(ev => {
                setLoader(false);
                baseCatch(ev);
            });
    };

    const handleRedirect = () => {
        navigate("/protected/users");
    }

    return (
        <StyledPageContainer>
            <PageTitle
                title={t("shared.sectionWallets")}
                breadcrumbs={(
                    <Breadcrumbs>
                        <Link onClick={handleRedirect} sx={{ fontSize: '0.8rem' }} >
                            {state?.models?.user?.email}
                        </Link>
                        <Typography sx={{ fontSize: '0.8rem', color: '#1c6e3d', fontWeight: '500', wordBreak: 'break-all' }}>
                            {t("shared.sectionWallets")}
                        </Typography>
                    </Breadcrumbs>
                )}
            />
            {smNoMatches && (
                <Box display="flex" alignItems="center" gap={2} p={2}>
                    <Box display="flex" alignItems="center" flexWrap="wrap" gap={2} flexGrow={1}>
                        <Tooltip title={t("walletsPage.TT_refreshAction")}>
                            <Box>
                                <IconButton size="small" disabled={pageLoader} onClick={handleGetWalletReload} sx={{ p: 0 }}>
                                    <Refresh />
                                </IconButton>
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            )}
            <Box overflow="auto" height={smNoMatches ? "calc(100vh - 155px)" : "auto"} px={2}>
                <Grid container spacing={2}>
                    {pageLoader ? (
                        <Grid sm={12} item overflow="hidden">
                            <PageLoader />
                        </Grid>
                    ) : (
                        wallets.length ? (
                            wallets.map((wallet, i) => (
                                <Grid xs={12} sm={12} md={12} lg={4} xl={4} item key={`fundcard-${i}`}>
                                    <WalletCard
                                        model={wallet}
                                        events={{
                                            handleDetailsClick: () => {
                                                handleWalletDetailsRedirect(wallet, state);
                                            },
                                        }}
                                    />
                                </Grid>
                            ))
                        ) : (
                            <Grid sm={12} item sx={{ height: '100%' }}>
                                <EmptyWalletsPage />
                            </Grid>
                        )
                    )}
                </Grid>
            </Box>
        </StyledPageContainer>
    );
};
