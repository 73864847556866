import React from "react";

export const useTextFieldVisibility = () => {

    const [visibility, setVisibility] = React.useState(false);

    const handleClickShowPassword = React.useCallback(() => {
        setVisibility((visibility) => !visibility);
    }, []);

    return {
        visibility,
        setVisibility: handleClickShowPassword
    }
}