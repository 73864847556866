import { Box, Paper } from '@mui/material';
import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { routes } from '../../../constants/appRoutes';
import { ApplicationLogo } from '../../components/Common/ApplicationLogo';
import { useLoaderContext, useProfileContext } from '../../contexts';
import { IsAuthenticated } from '../../helpers/auth.services';

const useLoginOutlet = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { setLoader } = useLoaderContext();

    const { setProfile } = useProfileContext();

    React.useEffect(() => {

        if (location.pathname === '/') {

            setLoader(true);
            IsAuthenticated()
                .then(({ data }) => {
                    setLoader(false);
                    setProfile(data);
                    navigate(routes.protected);
                })
                .catch(() => {
                    setLoader(false);
                });
        }
    }, []);
}

export const LoginOutlet = () => {

    useLoginOutlet();

    return (
        <Box
            height="100%"
            width="100vw"
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="400px"
        >
            <Paper
                sx={{
                    padding: '1rem 2.5rem 2.5rem',
                    width: '250px',
                }}
            >
                <Box mb={2} mt={2} mx={2}>
                    <ApplicationLogo />
                </Box>
                <Outlet />
            </Paper>
        </Box>
    );
};
