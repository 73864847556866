import { Cloud } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";

export const ApplicationLogo = () => {

    return (
        <Box
            display="flex"
            alignItems="center"
            gap={1}
            sx={{
                fontWeight: 'bold',
                borderRadius: '4px',
                padding: 2,
                color: 'var(--primary)',
                //background: 'linear-gradient(45deg, var(--primary), var(--primary-wo))',
                height: '61px'
            }}
        >
            <Cloud style={{ fontSize: "2.5rem" }} />
            <Box sx={{ fontSize: '1rem' }}>
                Cloud financial freedom
            </Box>
        </Box>
    );
}