import { Components } from "@mui/material/styles";

export const MuiButton: Components['MuiButton'] = {
    defaultProps: {
        disableElevation: true,
        disableRipple: true,
        variant: 'contained',
        color: 'primary',
        size: 'small'
    },
};
