import { Box, Icon, styled } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { MdPlaylistRemove } from "react-icons/md";

const StyledDescriptionText = styled(Box)({
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: '500',
    fontSize: '1rem',
});

export const EmptyWalletsPage: React.FC<{ title?: string }> = ({ title }) => {

    const { t } = useTranslation();

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', gap: 2, flexDirection: 'column', alignItems: 'center' }}>
            <Icon sx={{ fontSize: '10rem', color: '#ddd' }}>
                <MdPlaylistRemove />
            </Icon>
            <StyledDescriptionText sx={{ color: '#ddd', fontSize: '2rem', textAlign: 'center' }}>
                {title || t('homePage.noWallets')}
            </StyledDescriptionText>
        </Box>
    );
}