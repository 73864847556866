import { Box, Breadcrumbs, Typography } from "@mui/material";
import React from "react";

interface PageTitleProps {
    title: string;
    breadcrumbs?: React.ReactNode;
}

export const PageTitle: React.FC<PageTitleProps> = ({ title, breadcrumbs }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            p={2}
            sx={{
                borderBottom: '1px solid var(--primary-wo)',

            }}
        >
            <Box
                display="flex"
                flexDirection="column"
            >
                <Breadcrumbs>
                    <Typography
                        sx={{
                            fontSize: '1.2rem',
                            color: '#1c6e3d',
                            fontWeight: '500',
                            wordBreak: 'break-all'
                        }}
                    >
                        {title}
                    </Typography>
                </Breadcrumbs>
                {breadcrumbs }
            </Box>
        </Box>
    );
}