import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

type DialogCallback = ((close: () => void) => void);

interface DialogProps {
    open: boolean;
    content?: string | React.ReactElement;
    noCallback?: DialogCallback;
    yesCallback?: DialogCallback;
    title?: string;
    localize?: {
        noText?: string;
        yesText?: string;
    }
};

const UpdateDialogContext = createContext<(state: Omit<DialogProps, 'open'>) => void>(() => { });

export const useDialogContext = () => useContext(UpdateDialogContext);

export const DialogContext: React.FC<{ children: React.ReactElement }> = ({ children }) => {

    const { t } = useTranslation();

    const [dialogState, setDialogState] = useState<DialogProps>({
        open: false,
    });

    const closeDialog = React.useCallback(() => {
        setDialogState({
            ...dialogState,
            open: false
        });
    }, [dialogState, setDialogState]);

    const setDialog = React.useCallback((state: Omit<DialogProps, 'open'>) => {
        setDialogState({
            ...state,
            open: true
        });
    }, [setDialogState]);

    return (
        <UpdateDialogContext.Provider value={setDialog}>
            {children}
            <Dialog
                sx={{
                    zIndex: 1,
                    '& .MuiDialogTitle-root': {
                        color: '#a53636',
                        borderBottom: '2px solid #a53636',
                        backgroundColor: 'white'
                    },
                }}
                open={dialogState.open}
                fullWidth
                maxWidth="xs"
            >
                {dialogState.title && <DialogTitle>{dialogState.title}</DialogTitle>}
                {dialogState.content && (
                    <DialogContent>
                        <DialogContentText sx={{ pt: 2 }}>{dialogState.content}</DialogContentText>
                    </DialogContent>
                )}
                <DialogActions sx={{ backgroundColor: '#eee' }}>
                    {dialogState.noCallback ? (
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                if (dialogState.noCallback)
                                    dialogState.noCallback(closeDialog);
                            }}
                        >
                            {dialogState?.localize?.noText || t("shared.no")}
                        </Button>
                    ) : null}
                    {dialogState.yesCallback && (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                if (dialogState.yesCallback)
                                    dialogState.yesCallback(closeDialog);
                            }}
                        >
                            {dialogState?.localize?.yesText || t("shared.yes")}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </UpdateDialogContext.Provider>
    );
};
