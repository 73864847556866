import { useTranslation } from 'react-i18next';

export const useErrorTranslation = () => {
    const { t } = useTranslation();
    return (service: string, code: string) => {
        const error = t(`errors:${service.replace('/', '')}.${code}`);
        console.warn("[error]" + service +": " + error);
        /** considerando che generalmente per le descrizioni non si utilizzano caratteri speciali tipo '/',
         * se dopo il controllo di i18next error include il carattere '/' nel testo
         * allora possiamo assumere che non ci sia stato un match con le traduzioni e restituisco un errore generico */
        if (error.includes('/')) {
            return t('errors:common.500');
        }
        return error;
    };
};
