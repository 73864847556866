import { Box, Breadcrumbs, Link, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Location, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../constants/appRoutes";
import { PageTitle, StyledPageContainer } from '../../shared/components/Common';
import { GeneralDetailsPanel } from './components/GeneralDetailsPanel';
import { OperationsPanel } from './components/OperationsPanel';

type WalletDetailsTabValue = 0 | 1;

type Wallet = {
    code: number;
    userId: string;
    name: string;
    description: string;
    creationDate: string;
    amount: string;
    enabled: boolean;
    deactivationDate: string;
}

interface WalletLocationState {
    tab?: WalletDetailsTabValue;
    wallet?: Wallet;
}

const WalletDetailsTabs: Record<'Details' | 'Transactions', WalletDetailsTabValue> = {
    Details: 0,
    Transactions: 1,
}

export const PageWalletDetails = () => {

    const { t } = useTranslation();
    const theme = useTheme();
    const smNoMatches = useMediaQuery(theme.breakpoints.up('sm'));

    const navigate = useNavigate();
    const location: Location<WalletLocationState> = useLocation();

    const tab = location.state?.tab;

    const [wallet, setWallet] = React.useState<Wallet>(location.state?.wallet || {
        code: 0,
        name: '',
        amount: '',
        deactivationDate: '',
        description: '',
        creationDate: '',
        enabled: true,
        userId: ''
    });

    const [value, setValue] = React.useState<number>(tab || WalletDetailsTabs.Details);

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleRedirect = () => {
        navigate(`/protected/${routes.wallets}`, location);
    }

    return (
        <StyledPageContainer>
            <PageTitle
                title={t("walletsPage.pageTitle")}
                breadcrumbs={(
                    <Breadcrumbs>
                        <Link onClick={handleRedirect} sx={{ fontSize: '0.8rem' }} >
                            {t("shared.sectionWallets")}
                        </Link>
                        <Typography sx={{ fontSize: '0.8rem', color: '#1c6e3d', fontWeight: '500', wordBreak: 'break-all' }}>
                            {wallet?.name ? wallet.name : t("walletDetailsPage.new")}
                        </Typography>
                    </Breadcrumbs>
                )}
            />

            <Box>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label={t("walletDetailsPage.details")} value={WalletDetailsTabs.Details} />
                    {wallet?.code && <Tab label={t("walletDetailsPage.transactions")} value={WalletDetailsTabs.Transactions} />}
                </Tabs>
                <Box
                    sx={{
                        boxSizing: 'border-box',
                        overflow: 'auto',
                        paddingTop: '8px',
                        borderTop: '1px solid var(--primary-wo)',
                        height: smNoMatches ? 'calc(100vh - 132px)' : 'auto',
                    }}
                >
                    {value === WalletDetailsTabs.Details && (
                        <GeneralDetailsPanel setWallet={setWallet} wallet={wallet} />
                    )}

                    {value === WalletDetailsTabs.Transactions && wallet?.code && (
                        <OperationsPanel setWallet={setWallet} wallet={wallet} />
                    )}
                </Box>
            </Box>
        </StyledPageContainer>
    );
};
