import { useNotifyContext } from '../../shared/contexts';
import { useErrorTranslation } from '../../shared/hooks';

export const useStandardCatch = () => {

    const notify = useNotifyContext();
    const getErrorTranslation = useErrorTranslation();

    return {
        baseCatch: (reason: any, ref?: string) => {
            const status = reason?.response?.data?.errorCode ?? reason?.response?.data?.code ?? reason.response?.status ?? 500;
            const url = ref ?? reason?.config?.url ?? 'common';
            notify.warning({
                content: getErrorTranslation(url, status)
            });
        }
    }
}
