import { Box } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { ActionColumnType, DefaultColumnType, Filters, TFTableProps } from './TFTable';
import { ConditionalSorting, SelectFilter, TextFilter } from './TFTableFilters';

const baseThCellStyle = {
    fontSize: "14px",
    color: "var(--primary)",
    lineHeight: "27px",
    fontFamily: "Roboto",
    verticalAlign: "Bottom",
    fontWeight: 500
};

const filterThCellStyle = {
    padding: 0,
    color: "var(--primary)",
    fontFamily: "Roboto",
    fontWeight: 500,
    backgroundColor: '#1c6e3d07'
};

//#region Type guards

/** Verifica il set di dati passato come parametro, restituisce true se column è di tipo DefaultColumnType<R> */
export function columnTypeGuard<R>(column: DefaultColumnType<R> | ActionColumnType<R>): column is DefaultColumnType<R> {

    return (column as DefaultColumnType<R>).render !== undefined;
}

//#endregion

type ConditionalTFTableHeaderProps<R> = {
    columns: TFTableProps<R>['columns'];
    options: TFTableProps<R>['options'];
    sorting: Filters['sorting'];
    filters: Filters['filters'];
    sortingSubmit: (filters: Partial<Filters>) => void;     // Evento di applicazione del filtro di ordinamento
    textFilterSubmit: (filters: Partial<Filters>) => void;     // Evento di applicazione del filtro di test
    selectFilterSubmit: (filters: Partial<Filters>) => void;     // Evento di applicazione della select
}

export const ConditionalTFTableHeader: <R>(props: ConditionalTFTableHeaderProps<R>) => React.ReactElement = (props) => {

    return (
        <TableHead>
            <TableRow>
                {props.columns.map((column, ci) => {

                    if (column && columnTypeGuard(column) && column?.sorting?.field) {

                        return (
                            <TableCell
                                key={`th-cell-${ci}`}
                                sx={{ ...column.thStyle, ...baseThCellStyle }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: '#1c6e3d07',
                                        display: 'flex',
                                        alignItems: 'flex-end'
                                    }}
                                >
                                    {(column?.title ? column.title() : '') as any}
                                    <ConditionalSorting
                                        field={column.sorting.field}
                                        order={props.sorting?.field === column.sorting.field ? props.sorting?.order : null || 'Asc'}
                                        submit={props.sortingSubmit}
                                    />
                                </Box>
                            </TableCell>
                        );
                    } else {

                        return (
                            <TableCell
                                key={`th-cell-${ci}`}
                                sx={{
                                    ...column?.thStyle,
                                    ...baseThCellStyle,
                                    backgroundColor: '#1c6e3d07',
                                }}
                            >
                                {(column?.title && column.title()) as any}
                            </TableCell>
                        );
                    }
                })}
            </TableRow>
            {props.columns.filter((column) => column && columnTypeGuard(column) && column.filter).length > 0 && (
                <TableRow>
                    {props.columns.map((column, ci) => {

                        if (column && columnTypeGuard(column) && column.filter) {

                            return (
                                <TableCell
                                    key={`th-search-cell-${ci}`}
                                    sx={{
                                        ...filterThCellStyle,
                                        ...props?.options?.filtersAdjustmentStyle,
                                        backgroundColor: '#1c6e3d07',
                                    }}
                                >
                                    {column?.filter &&
                                        column?.filter?.type === 'text' ?
                                        (
                                            <TextFilter
                                                id={`th-text-filter-cell-${ci}`}
                                                search={props.options?.localize?.search}
                                                filter={props?.filters?.find(({ field }) => field === column?.filter?.field) || { field: column?.filter.field, filter: null }}
                                                submit={props.textFilterSubmit}
                                            />
                                        ) : column?.filter?.type === 'select' ?
                                            (
                                                <SelectFilter
                                                    options={column.filter.options}
                                                    filter={props?.filters?.find(({ field }) => field === column?.filter?.field) || { field: column?.filter.field, filter: null }}
                                                    submit={props.selectFilterSubmit}
                                                />
                                            ) : null
                                    }
                                </TableCell>
                            );
                        } else {

                            return (
                                <TableCell
                                    key={`th-search-cell-${ci}`}
                                    sx={{
                                        ...filterThCellStyle,
                                        ...props?.options?.filtersAdjustmentStyle,
                                        backgroundColor: '#1c6e3d07',
                                    }}
                                />
                            );
                        }
                    })}
                </TableRow>
            )}
        </TableHead>
    );
}