import { Add, Refresh } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Popover, TextField } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { default as React } from 'react';
import { useTranslation } from 'react-i18next';
import { BiTrash } from 'react-icons/bi';
import { FaCircleInfo } from 'react-icons/fa6';
import { CFFDateFormat } from '../../../constants/appRegex';
import { RT, TFTable } from '../../../shared/components';
import { useDialogContext, useLoaderContext, useNotifyContext, useProfileContext } from '../../../shared/contexts';
import { AddWalletOperations, DeleteWalletOperation, GetWalletOperations, UpdateWalletOperations } from '../../../shared/helpers';
import { useErrorTranslation, useMathUtilities, useStandardCatch } from '../../../shared/hooks';

type Wallet = {
    code: number;
    userId: string;
    name: string;
    description: string;
    creationDate: string;
    amount: string;
    enabled: boolean;
    deactivationDate: string;
}

interface DetailsPanelProps {
    setWallet: React.Dispatch<React.SetStateAction<Wallet>>;
    wallet: Wallet;
}

interface WalletOperation {
    oid: string;
    walletOid: number;
    description: string;
    userId: string;
    amount: string;
    progressive: number;
    creationDate: string;
    deleted: boolean;
    parentOid: number | null;
};

interface WalletModel {
    oid: string;
    description: string;
    amount: string;
}

type Errors<R> = Record<keyof R, string>;
type Validators<R> = Partial<Record<keyof R, { pattern: (state: R) => Partial<Errors<R>>; }>>;

export const OperationsPanel: React.FC<DetailsPanelProps> = (props) => {

    const { isAdmin } = useProfileContext();
    const tableRef = React.useRef() as React.MutableRefObject<RT<WalletOperation>>;

    const { t } = useTranslation();
    const notify = useNotifyContext();
    const setDialog = useDialogContext();
    const { toLocalePrice } = useMathUtilities();
    const getErrorTranslation = useErrorTranslation();
    const { baseCatch } = useStandardCatch();

    const { setLoader } = useLoaderContext();

    const validators = React.useRef<Validators<WalletModel>>({
        description: {
            pattern: (state) => ({
                description: !state.description ? t("validations.description") : ''
            })
        },
        amount: {
            pattern: (state) => ({
                amount: isNaN(+state.amount) || +state.amount === 0 ? t("validations.invalidNumber") : ''
            })
        },
    });

    const [walletDialog, setWalletDialog] = React.useState({ open: false });
    const [walletForm, setWalletForm] = React.useState({
        oid: '',
        description: '',
        amount: ''
    });
    const [walletFormErrors, setWalletFormErrors] = React.useState<Errors<Omit<WalletModel, 'oid'>>>({
        description: '',
        amount: ''
    });
    const [popover, setPopover] = React.useState<{
        anchorEl: (EventTarget & HTMLButtonElement) | null,
        infoPopover: string,
    }>({
        anchorEl: null,
        infoPopover: '',
    });

    const toggleInvitationDialogState = () => {
        const open = !walletDialog.open;
        setWalletDialog({
            ...walletDialog,
            open
        });
    }

    const change = (name: keyof WalletModel, value: string) => {
        const newState = { ...walletForm, [name]: value };
        setWalletForm(newState);
        const correctValidator = validators.current[name];
        if (correctValidator?.pattern) {
            const newErrors = {
                ...walletFormErrors,
                ...correctValidator.pattern(newState)
            };
            setWalletFormErrors(newErrors);
        }
    };
    const validate = (complete: () => void, fallback?: () => void) => {
        if (validators.current) {
            let _errors = { ...walletFormErrors };
            Object
                .keys(validators.current)
                .forEach((key) => {
                    const correctValidator = _.get(validators.current, key);
                    if (correctValidator?.pattern) {
                        _errors = {
                            ..._errors,
                            ...correctValidator.pattern(walletForm)
                        };
                    }
                });
            setWalletFormErrors({ ..._errors });
            const hasErrors = _.values(_errors);
            const counter = _.some(hasErrors, error => error);
            if (counter && fallback) {
                fallback();
            } else if (!counter) {
                complete();
            }
        } else {
            complete();
        }
    };

    const handleWalletSubmit = () => validate(() => {

        setLoader(true);
        if (walletForm.oid) {

            UpdateWalletOperations({
                params: {
                    walletOid: props.wallet.code,
                    operationOid: walletForm.oid,
                },
                data: {
                    description: walletForm.description,
                    amount: walletForm.amount
                }
            })
                .then(() => {
                    notify.success({
                        content: t("success:updateWalletOperation")
                    });
                    tableRef.current.reload();
                    toggleInvitationDialogState();
                    setLoader(false);
                })
                .catch(ev => {
                    setLoader(false);
                    baseCatch(ev, '/api/wallets/operations');
                });
        } else {

            AddWalletOperations({
                params: {
                    walletOid: props.wallet.code,
                },
                data: {
                    description: walletForm.description,
                    amount: walletForm.amount
                }
            })
                .then(() => {
                    notify.success({
                        content: t("success:addWalletOperation")
                    });
                    tableRef.current.reload();
                    toggleInvitationDialogState();
                    setLoader(false);
                })
                .catch(ev => {
                    setLoader(false);
                    baseCatch(ev, '/api/wallets/operations');
                });
        }
    });

    const handleClose = () => {
        setPopover({ ...popover, anchorEl: null });
    };

    const open = Boolean(popover.anchorEl);

    return (
        <Box px={2} sx={{ width: 'calc(100vw - 282px)' }}>
            <Grid spacing={2} container>
                <Grid xs={12} sm={12} md={12} item>
                    <TFTable<WalletOperation>
                        ref={tableRef}
                        initialize={{
                            sorting: {
                                field: "CreationDate",
                                order: "Desc"
                            }
                        }}
                        data={(query) => {

                            return new Promise((result) => {

                                GetWalletOperations({
                                    params: {
                                        orderBy: query?.sorting?.field,
                                        orderByDescending: query?.sorting?.order === 'Desc',
                                        code: props?.wallet?.code,
                                        userOid: isAdmin ? (props.wallet.userId || '') : '',
                                        pageNumber: query?.pageNumber,
                                        pageSize: query?.pageSize,
                                    }
                                }).then(({ data }) => {

                                    result({
                                        data: data.walletOperations,
                                        totalCount: data.totalCount
                                    });
                                }).catch((ev) => {

                                    const status = ev.response?.data?.errorCode || ev.response?.status || 500;
                                    const url = ev.config.url;
                                    notify.warning({
                                        content: getErrorTranslation(url, status)
                                    });
                                });
                            })
                        }}
                        events={{
                            onRowClick: (data) => {
                                setWalletFormErrors({
                                    description: '',
                                    amount: ''
                                });
                                setWalletForm(data);
                                setWalletDialog({ open: true });
                            }
                        }}
                        actions={[
                            {
                                icon: <Refresh />,
                                tooltip: t("shared.table.TT_refresh"),
                                onClick: () => {
                                    tableRef.current.reload();
                                }
                            },
                            (isAdmin ? ({
                                icon: <Add />,
                                tooltip: t("shared.table.add_Title"),
                                onClick: () => {
                                    setWalletFormErrors({
                                        description: '',
                                        amount: ''
                                    });
                                    setWalletForm({
                                        oid: '',
                                        description: '',
                                        amount: ''
                                    });
                                    setWalletDialog({ open: true });
                                }
                            }) : null)
                        ]}
                        options={{
                            //disableRow: (_data, { row }) => row !== 0,
                            //rowStyle: (_data, { row }) => {
                            //    if (row !== 0) {
                            //        return { opacity: 0.8 };
                            //    } else {
                            //        return {};
                            //    };
                            //},
                            usePagination: true,
                            useSearch: false,
                            localize: {
                                noData: t('shared.table.gridEmptyMsg'),
                                resetFilters: t('shared.table.resetFilters'),
                                search: t('shared.table.search'),
                                displayedElementsSeparator: t('shared.table.displayedElementsSeparator'),
                                rowsForPage: t('shared.table.rowsForPage'),
                            },
                            minWidth: "1000px",
                            style: {
                                maxHeight: "calc(100vh - 242px)"
                            },
                            filtersAdjustmentStyle: {
                                top: '40px'
                            }
                        }}
                        columns={[
                            {
                                title: () => "Data",
                                render: ({ creationDate }) => moment(creationDate).format(CFFDateFormat.long),
                                thStyle: { width: 150, textAlign: 'right', },
                                style: { width: 150, textAlign: 'right', },
                            },
                            {
                                title: () => "Codice transazione",
                                ignoreClick: true,
                                thStyle: { width: 60, textAlign: 'center', },
                                style: { width: 60, textAlign: 'center', },
                                render: ({ oid }, _filters) => {
                                    return (
                                        <IconButton
                                            onClick={(ev) => {
                                                setPopover({
                                                    ...popover,
                                                    anchorEl: ev.currentTarget,
                                                    infoPopover: `Codice transazione: ${oid}`
                                                });
                                            }}
                                        >
                                            <FaCircleInfo style={{ fontSize: "1.0rem" }} />
                                        </IconButton>
                                    );
                                },
                            },
                            {
                                title: () => t("walletDetailsPage.description"),
                                render: ({ description }, _filters) => description,
                            },
                            {
                                title: () => t("walletDetailsPage.amount"),
                                render: ({ amount }) => toLocalePrice(+amount),
                                thStyle: { width: 80, textAlign: 'right', },
                                style: { width: 80, textAlign: 'right', },
                            },
                            {
                                title: () => t("walletDetailsPage.progressive"),
                                render: ({ progressive }) => toLocalePrice(progressive),
                                thStyle: { width: 80, textAlign: 'right', },
                                style: { width: 80, textAlign: 'right', },
                            },
                            //{
                            //    tooltip: t("shared.delete"),
                            //    hidden: (_data, { row }) => row !== 0,
                            //    icon: <BiTrash style={{ fill: '#D82829' }} />,
                            //    style: { padding: '0 5px', width: 40, textAlign: "center", svg: { color: '#D82829', fill: '#D82829' } },
                            //    thStyle: { padding: '0 5px', width: 40, textAlign: "center", svg: { color: '#D82829', fill: '#D82829' } },
                            //    onClick: (data) => {

                            //        setDialog({
                            //            title: `${t('shared.dialogRemove_title')}`,
                            //            content: t('walletDetailsPage.dialog_RemoveOperation_body'),
                            //            yesCallback: (closeDialog) => {

                            //                setLoader(true);
                            //                DeleteWalletOperation({
                            //                    params: {
                            //                        operationOid: data.oid,
                            //                        walletOid: props.wallet.code
                            //                    }
                            //                }).then(() => {

                            //                    setLoader(false);
                            //                    tableRef.current.reload();
                            //                    notify.success({
                            //                        content: t("success:deleteWalletOperation")
                            //                    });
                            //                    closeDialog();
                            //                }).catch((ev) => {

                            //                    setLoader(false);
                            //                    const url = '/api/wallets';
                            //                    baseCatch(ev, url);
                            //                });
                            //            },
                            //            noCallback: (closeDialog) => {

                            //                closeDialog();
                            //            }
                            //        });
                            //    },
                            //}
                        ]}
                    />
                </Grid>
            </Grid>
            <Popover
                open={open}
                anchorEl={popover.anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                sx={{
                    '.MuiPaper-root': {
                        color: "#1c6e3d!important",
                        fontSize: "12px",
                        backgroundColor: "#FFFFFF",
                        border: "1px solid #1c6e3d",
                        letterSpacing: "0",
                        textAlign: "center",
                        lineHeight: "12px",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        borderRadius: "2px",
                        padding: "6px 8px",
                        fill: 'rgb(0, 80, 117)'
                    }
                }}
            >
                {popover.infoPopover}
            </Popover>
            <Dialog
                sx={{
                    '& .MuiDialogTitle-root': {
                        color: '#1c6e3d',
                        borderBottom: '2px solid #1c6e3d',
                        backgroundColor: 'white'
                    },
                }}
                open={walletDialog.open}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle>{walletForm.oid ? t("walletDetailsPage.dialog_UpdateWalletOperation_Title") : t("walletDetailsPage.dialog_AddWalletOperation_Title")}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} pt={2}>
                        <Grid xs={12} item>
                            <TextField
                                rows={3}
                                multiline
                                label={`${t("walletDetailsPage.description")}*`}
                                onChange={(ev) => {
                                    const { value } = ev.target;
                                    change('description', value);
                                }}
                                value={walletForm.description}
                                error={Boolean(walletFormErrors.description)}
                                helperText={walletFormErrors.description}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <TextField
                                label={`${t("walletDetailsPage.amount")}*`}
                                onChange={(ev) => {
                                    const { value } = ev.target;
                                    change('amount', value);
                                }}
                                value={walletForm.amount}
                                error={Boolean(walletFormErrors.amount)}
                                helperText={walletFormErrors.amount}
                                InputProps={{
                                    inputProps: {
                                        style: { textAlign: "right" },
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: '#eee' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={toggleInvitationDialogState}
                    >
                        {t("shared.close")}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleWalletSubmit}
                    >
                        {t("shared.save")}
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
}
