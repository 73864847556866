import { SubscriptionApiDto } from '../../shared/types/subscriptions.types';

export * from './PageSubscriptions';
export * from './PageSubscriptionDetails';

/**
 * Oggetto di utility, fornisce una mappatura dei tab
 * nella sezione di dettaglio
 */
export enum UserSubscriptionsPanelsMap {
    Details,
    Statistics,
    BackTests,
}

/**
 * Filtri generici per la sezione principale di 
 * visualizzazione wallets
 */
export interface Filters {
    status: string[]
    search: string;
}

/**
 * Modello di scambio per le pagine (location state)
 */
export interface UserSubscriptionsLocationState {
    models?: {
        subscription: SubscriptionApiDto;
        activeTab?: number;
    };
    filters?: {
        status?: string[]
        search?: string;
    }
}
