import { TextFieldProps } from "@mui/material";
import _ from "lodash";
import * as React from "react";

type CommonValue = string | boolean | null | CommonValue[] | undefined;
type CommonCallback<R> = (state: R) => void;
type Errors<R> = Record<keyof R, CommonValue>;
type FHChangeEvent = (path: string, value: CommonValue) => void;
type FHValidateEvent<R> = (complete: CommonCallback<R>, fallback?: CommonCallback<R>) => void;
type PatternType<R> = (state: R) => Partial<Errors<R>>;
type Validators<R> = Record<string, { pattern?: PatternType<R>; }>;

/**
 * Hook di utility per la gestione dello stato di form con delle propriet� da validare
 * @param initialize Modello utilizzato per l'inizializzazione di stato/errori e tipizzazione
 * @param validators Definisce i modelli da utilizzare per la validazione delle parti di stato
 */
export const useForm = <R extends { [P in string]: any }>(
    initialize: R,
    config?: {
        useExitPrompt?: boolean;
        validators?: Partial<Validators<R>>;
    }
) => {

    const stateGuardRef = React.useRef({ confirmedState: initialize });
    const validatorsGuardRef = React.useRef<Partial<Validators<R>>>({ ...config?.validators });

    const [state, setState] = React.useState<R>(initialize);
    const [errors, setErrors] = React.useState<Errors<R>>({} as Errors<R>);

    /**
     * Evento generico per la modifica sullo stato della form
     * @param path nome/percorso della propriet� in formato stringa. example: prop1.prop2
     * @param value
     */
    const change: FHChangeEvent = React.useCallback((path, value) => {
        const newState = _.set<R>({ ...state }, path, value);
        setState(newState);
        const correctValidator = _.get(validatorsGuardRef.current, path);
        if (correctValidator?.pattern) {
            const newErrors = {
                ...errors,
                ...correctValidator.pattern(newState)
            };
            setErrors(newErrors);
        }
    }, [validatorsGuardRef, state, errors, setErrors]);

    /**
     * Evento utilizzato per la validazione dello stato.
     * Verifica sulla base delle validazioni se sono presenti errori ed esegue una callback di completamento
     * altrimenti esegue la callback di fallback
     * @param complete Callback di completamento da eseguire se vengono passate le validazioni
     */
    const validate: FHValidateEvent<R> = React.useCallback((complete, fallback) => {
        const executeCompleteCallback = (state: R) => {
            stateGuardRef.current.confirmedState = { ...state };
            complete(state);
        }
        if (validatorsGuardRef.current) {
            let _errors = { ...errors };
            Object
                .keys(validatorsGuardRef.current)
                .forEach((key) => {
                    const correctValidator = _.get(validatorsGuardRef.current, key);
                    if (correctValidator?.pattern) {
                        _errors = {
                            ..._errors,
                            ...correctValidator.pattern(state)
                        };
                    }
                });
            setErrors({ ..._errors });
            const hasErrors = _.values(_errors);
            const counter = _.some(hasErrors, error => error);
            if (counter && fallback) {
                fallback(state);
            } else if (!counter) {
                executeCompleteCallback(state);
            }
        } else {
            executeCompleteCallback(state);
        }
    }, [validatorsGuardRef, state, errors, setErrors, stateGuardRef]);

    const namedTextFieldOnChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback((event) => {
        const { name, value } = event.target;
        if (name) change(name, value);
    }, [change]);

    const clean = React.useCallback(() => {
        setState({ ...initialize });
        setErrors({} as Errors<R>);
    }, [setState, setErrors, initialize]);

    const register = React.useCallback((
        path: keyof R,
        options?: {
            label?: string;
            readOnly?: boolean,
            type?: 'text',
            pattern?: PatternType<R>;
            useNumbers?: boolean;
        }
    ) => {

        const type = options?.type || 'text' || 'password';
        const error = _.get(errors, path);
        const value = _.get(state, path);

        validatorsGuardRef.current = _.set(
            validatorsGuardRef.current,
            path,
            { pattern: options?.pattern }
        );

        switch (type) {
            case 'text':
                return {
                    label: options?.label,
                    name: String(path),
                    error: Boolean(error),
                    helperText: error,
                    value,
                    onChange: namedTextFieldOnChange
                };
        }
    }, [state, errors, namedTextFieldOnChange]);

    const submit = React.useCallback((call: CommonCallback<R>) => {
        const event = (ev?: React.FormEvent<HTMLFormElement>) => {
            ev?.preventDefault();
            validate(call);
        };
        return event;
    }, [validate]);

    return {
        change,
        clean,
        errors,
        register,
        setErrors,
        setState,
        state,
        submit,
        validate,
        validators: validatorsGuardRef.current,
    }
}
