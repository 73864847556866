import { Box, Button, Link, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CFFRegex } from '../../constants/appRegex';
import { routes } from '../../constants/appRoutes';
import { StyledDescriptionText } from '../../shared/components/Common';
import { useLoaderContext, useNotifyContext } from '../../shared/contexts';
import { SendForgotPasswordRequest } from '../../shared/helpers';
import { useForm } from '../../shared/hooks';

const useForgotPasswordPage = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { setLoader } = useLoaderContext();
    const notify = useNotifyContext();

    const { register, submit } = useForm({ email: '' });

    const handleSubmitForm = submit((data) => {
        setLoader(true);
        const handleMessage = () => {
            setLoader(false);
            notify.success({
                content: t("success:SendForgotPasswordRequest")
            });
        };
        SendForgotPasswordRequest({ data })
            .then(handleMessage)
            .catch(handleMessage);
    });

    const handleRedirect = React.useCallback(() => {
        navigate(routes.loginRoute, { replace: true });
    }, [navigate]);

    return {
        helpers: {
            register,
        },
        events: {
            handleSubmitForm,
            handleRedirect
        },
    }
}

export const PageForgotPassword = () => {

    const { t } = useTranslation();
    const {
        helpers: {
            register
        },
        events: {
            handleSubmitForm,
            handleRedirect
        },
    } = useForgotPasswordPage();

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={2}
            component="form"
            autoComplete="on"
            onSubmit={handleSubmitForm}
        >
            <StyledDescriptionText>
                {t("forgotPassword.details")}
            </StyledDescriptionText>
            <TextField
                autoComplete="username"
                {...register('email', {
                    label: `${t('forgotPassword.email')}*`,
                    pattern: ({ email }) => ({
                        email: !CFFRegex.email.test(email) && t("validations.email")
                    })
                })}
            />
            <Button type="submit" fullWidth>
                {t('forgotPassword.send')}
            </Button>
            <Link onClick={handleRedirect}>
                {t("forgotPassword.goBack")}
            </Link>
        </Box>
    );
};
