import { ExitToApp } from "@mui/icons-material";
import { AppBar, Box, CircularProgress, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Toolbar } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaCogs, FaUser } from "react-icons/fa";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { routes } from "../../../constants/appRoutes";
import { useProfileContext } from "../../contexts";
import { IsAuthenticated } from "../../helpers/auth.services";

export const useProtectedSmallOutlet = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const {
        profile,
        setProfile,
        isAdmin,
        isInvestor,
        isGuest,
        signOut,
    } = useProfileContext();

    const [loader, setLoader] = React.useState<boolean>(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    React.useEffect(() => {

        IsAuthenticated()
            .then(({ data }) => {
                setProfile(data);
            })
            .catch((err) => {
                setLoader(false);
                const code = err?.response?.status || 500;
                navigate(`/${routes.errors}`, { state: { code } });
            })
            .finally(() => {
                setLoader(false);
            });
    }, []);

    return {
        anchorEl,
        loader,
        open,
        handleSignOut: signOut,
        location,
        handleUserMenu,
        handleClose,
        navigate,
        isAdmin,
        isInvestor,
        isGuest,
        profile
    }
}

export const ProtectedSmallOutlet = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const {
        open,
        loader,
        handleClose,
        anchorEl,
        handleSignOut,
        handleUserMenu,
    } = useProtectedSmallOutlet();

    return (
        <Box sx={{ display: 'flex', width: '100vw', height: '100%' }}>
            <AppBar
                position="fixed"
                sx={{
                    boxSizing: 'border-box',
                    backgroundColor: '#fff',
                    borderBottom: '1px solid var(--primary)',
                    color: 'var(--primary)',
                    boxShadow: 'none'
                }}
            >
                <Toolbar variant="dense" disableGutters sx={{
                    minHeight: '40px',
                }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            aligItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Box sx={{ fontSize: '1.3rem', ml: 2, fontWeight: 'bold', letterSpacing: '4px' }}>
                            CFF
                        </Box>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                gap: 1
                            }}
                        >
                            <Link onClick={() => navigate('')}>
                                {t("userSubscriptions.pageTitle")}
                            </Link>
                        </Box>
                        <Box sx={{ alignItems: 'center', display: 'flex', mr: 2, gap: 1 }}>
                            <IconButton onClick={handleUserMenu} sx={{ color: 'inherit' }} size="small">
                                <FaUser />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuList dense disablePadding>
                                    <MenuItem onClick={() => { navigate(routes.user); handleClose(); }} selected={location.pathname.includes('protected/user')}>
                                        <ListItemIcon>
                                            <FaCogs />
                                        </ListItemIcon>
                                        <ListItemText>{t("protectedOutlet.info")}</ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={() => handleSignOut()}>
                                        <ListItemIcon>
                                            <ExitToApp />
                                        </ListItemIcon>
                                        <ListItemText>{t("protectedOutlet.logOut")}</ListItemText>
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            {loader ? (
                <Box sx={{ width: '100%', p: 2, display: 'flex', justifyContent: 'center', gap: 2, alignItems: 'center' }}>
                    {t('protectedOutlet.loading')} <CircularProgress size={20} />
                </Box>
            ) : (
                <Box sx={{
                    width: '100%'
                }} display="flex" justifyContent="center" mt={7}>
                    <Outlet />
                </Box>
            )}
        </Box>
    );
}
