import { Box, Breadcrumbs, Button, FormControl, Grid, Icon, InputLabel, Link, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaSackDollar } from 'react-icons/fa6';
import { MdPlaylistRemove } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { Errors, FundLocationState, Validators } from '..';
import { CFFDateFormat } from '../../constants/appRegex';
import { PageLoader, PageTitle, StyledDescriptionText, StyledPageContainer } from '../../shared/components/Common';
import { useLoaderContext, useNotifyContext, useProfileContext } from '../../shared/contexts';
import { AddSubscription, GetWallets } from '../../shared/helpers';
import { useMathUtilities, useStandardCatch } from '../../shared/hooks';
import { FundApiDto, WalletApiDto } from '../../shared/types';
import { routes } from '../../constants/appRoutes';

interface Subscribe {
    walletBalance: number;
    walletCode: number;
    quoteNumbers: number;
}

export const PageFundSubscribe = () => {

    const location = useLocation();
    const state: FundLocationState = location.state;
    const fund = state.models?.fund || {} as FundApiDto;

    const validators = React.useRef<Validators<Subscribe>>({
        walletCode: {
            pattern: (state) => ({
                walletCode: !state.walletCode ? t("validations.walletCode") : ''
            })
        },
        quoteNumbers: {
            pattern: (state) => {
                if (/^\d+$/.test(String(state.quoteNumbers)) && +state.quoteNumbers > 0) {
                    return {
                        quoteNumbers: ''
                    }
                }
                return {
                    quoteNumbers: t("validations.invalidNumber")
                }
            }
        },
    });

    const { t } = useTranslation();
    const { profile } = useProfileContext();

    const { setLoader } = useLoaderContext();
    const { baseCatch } = useStandardCatch();
    const notify = useNotifyContext();
    const navigate = useNavigate();
    const { toLocalePrice } = useMathUtilities();

    const [pageLoader, setPageLoader] = React.useState(true);
    const [wallets, setWallets] = React.useState<WalletApiDto[]>([]);

    const [subscriptionForm, setSubscriptionForm] = React.useState<Subscribe>({
        walletCode: 0,
        walletBalance: 0,
        quoteNumbers: 0
    });
    const [subscriptionFormErrors, setSubscriptionFormErrors] = React.useState<Partial<Errors<Subscribe>>>({
        walletCode: '',
        quoteNumbers: ''
    });

    const change = (name: keyof Subscribe, value: string | number | null) => {
        const newState = { ...subscriptionForm, [name]: value };
        setSubscriptionForm(newState);
        const correctValidator = validators.current[name];
        if (correctValidator?.pattern) {
            const newErrors = {
                ...subscriptionFormErrors,
                ...correctValidator.pattern(newState)
            };
            setSubscriptionFormErrors(newErrors);
        }
    };

    const validate = (complete: () => void, fallback?: () => void) => {
        if (validators.current) {
            let _errors = { ...subscriptionFormErrors };
            Object
                .keys(validators.current)
                .forEach((key) => {
                    const correctValidator = _.get(validators.current, key);
                    if (correctValidator?.pattern) {
                        _errors = {
                            ..._errors,
                            ...correctValidator.pattern(subscriptionForm)
                        };
                    }
                });
            setSubscriptionFormErrors({ ..._errors });
            const hasErrors = _.values(_errors);
            const counter = _.some(hasErrors, error => error);
            if (counter && fallback) {
                fallback();
            } else if (!counter) {
                complete();
            }
        } else {
            complete();
        }
    };

    const handleSubmitForm = () => validate(() => {

        setLoader(true);
        AddSubscription({
            data: {
                fundOid: fund.oid,
                quotesNumber: subscriptionForm.quoteNumbers,
                walletCode: subscriptionForm.walletCode
            }
        })
            .then(() => {
                notify.success({
                    content: t("success:addSubscription")
                });
                handleGoToUserSubscriptions();
                setLoader(false);
            })
            .catch(ev => {
                setLoader(false);
                const url = '/api/subscription';
                baseCatch(ev, url);
            });
    });


    const handleGoToUserSubscriptions = () => {
        navigate("/protected/" + routes.userSubscriptions, location);
    }

    const handleGoBack = () => {
        navigate("/protected/funds", location);
    }

    React.useEffect(() => {
        handleGetWallets();
    }, []);

    const handleGetWallets = () => {
        setPageLoader(true);
        GetWallets({
            params: {
                userId: profile.id
            }
        })
            .then(({ data }) => {
                setPageLoader(false);

                /** Recupera solo i wallets con saldo sufficiente ad almeno una sottoscrizione */
                const available_wallets = data.wallets.filter(({ amount }) => {
                    return +amount > 0 && +amount > fund?.quotesAmount;
                });

                setWallets(available_wallets);

                if (available_wallets.length) {
                    setSubscriptionForm({
                        ...subscriptionForm,
                        walletCode: available_wallets[0].code
                    });
                }
            })
            .catch((ev) => {
                setPageLoader(false);
                baseCatch(ev);
            });
    }

    const subscription = React.useMemo(() => {

        const quoteNumbers = +subscriptionForm?.quoteNumbers > 0 ? subscriptionForm.quoteNumbers : 0

        const total = quoteNumbers * fund.quotesAmount;
        const expectedProfit = (fund.expectedProfit * total / 100);
        const expectedCommission = (fund.commission * expectedProfit) / 100;
        const totalExpenses = (fund.costs + (fund.quotesExpenses * subscriptionForm.quoteNumbers));

        const netProfit = quoteNumbers ? (expectedProfit - totalExpenses - expectedCommission) : 0;
        const stopLose = (fund.stopLoseTarget * quoteNumbers * fund.quotesAmount) / 100;
        const takeProfit = (fund.takeProfitTarget * quoteNumbers * fund.quotesAmount) / 100;

        return {
            total,
            totalExpenses,
            netProfit,
            expectedProfit,
            expectedCommission,
            stopLose,
            takeProfit
        };
    }, [subscriptionForm]);


    return (
        <StyledPageContainer>
            <PageTitle
                title={t("fundsPage.pageTitle")}
                breadcrumbs={(
                    <Breadcrumbs>
                        <Link onClick={handleGoBack} sx={{ fontSize: '0.8rem' }} >
                            {fund.name}
                        </Link>
                        <Typography sx={{ fontSize: '0.8rem', color: '#1c6e3d', fontWeight: '500', wordBreak: 'break-all' }}>
                            {t("shared.subscribe")}
                        </Typography>
                    </Breadcrumbs>
                )}
            />
            <Box sx={{ height: 'calc(100vh - 86px)', overflow: 'auto' }} >
                <Box sx={{ borderBottom: "1px dashed #DCE9E0", p: 2 }} >
                    <Grid spacing={2} container >
                        <Grid md={2} item>
                            <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                <Box
                                    sx={{
                                        width: '100px',
                                        height: '100px',
                                        borderRadius: '50%',
                                        border: '1px solid #ddd',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    {fund.logo ? (
                                        <img src={fund.logo} width="100px" height="100px" className="img" alt="logo" style={{ borderRadius: '50%' }} />
                                    ) : (
                                        <Icon
                                            className="img"
                                            sx={{
                                                color: "#222",
                                                fill: '#222',
                                                fontSize: '1.5rem'
                                            }}
                                        >
                                            <FaSackDollar />
                                        </Icon>
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid md={10} item>
                            <Grid container spacing={2}>
                                <Grid xs={12} sm={12} md={4} lg={6} item>
                                    <TextField
                                        label={t("fundDetailsPage.name")}
                                        value={fund.name}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12} sm={12} md={4} lg={3} item>
                                    <TextField
                                        label={t("fundDetailsPage.startDate")}
                                        value={moment(fund.startDate).format(CFFDateFormat.short)}
                                        InputProps={{
                                            readOnly: true,
                                            inputProps: {
                                                style: { textAlign: "right" },
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12} sm={12} md={4} lg={3} item>
                                    <TextField
                                        label={t("fundDetailsPage.endDate")}
                                        value={moment(fund.endDate).format(CFFDateFormat.short)}
                                        InputProps={{
                                            readOnly: true,
                                            inputProps: {
                                                style: { textAlign: "right" },
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12} sm={12} md={12} lg={12} item>
                                    <TextField
                                        label={t("fundDetailsPage.description")}
                                        value={fund.description}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        rows={1}
                                        multiline
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                {pageLoader ? (
                    <Grid p={2} sm={12} item overflow="hidden">
                        <PageLoader />
                    </Grid>
                ) : (
                    wallets.length ? (
                        <Box p={2}>
                            <Grid container spacing={2}>
                                <Grid xs={6} sm={6} md={3} lg={3} item>
                                    <Box display="flex" gap={2} flexDirection="column">
                                        <FormControl fullWidth>
                                            <InputLabel sx={{ background: 'white', px: 1 }} shrink>Portafogli</InputLabel>
                                            <Select
                                                value={subscriptionForm?.walletCode || null}
                                                onChange={(ev) => {
                                                    const { value } = ev.target;
                                                    const wallet = wallets.find(w => w.code === value);
                                                    if (value) {
                                                        setSubscriptionForm({
                                                            ...subscriptionForm,
                                                            walletCode: +value,
                                                            walletBalance: +(wallet?.amount || 0),
                                                            quoteNumbers: 0
                                                        });
                                                    }
                                                }}
                                            >
                                                {wallets.map(({ code, name, amount }) => (
                                                    <MenuItem value={code}>{name} ({toLocalePrice(+amount)})</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid xs={6} sm={6} md={3} lg={3} item>
                                    <Box display="flex" gap={2} flexDirection="column">
                                        <TextField
                                            label="Numero di quote*"
                                            type="number"
                                            name="quoteNumbers"
                                            disabled={!subscriptionForm?.walletCode}
                                            value={subscriptionForm.quoteNumbers}
                                            error={Boolean(subscriptionFormErrors.quoteNumbers)}
                                            helperText={String(subscriptionFormErrors.quoteNumbers)}
                                            InputProps={{
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                }
                                            }}
                                            onChange={(ev) => {
                                                const { value } = ev.target;
                                                change("quoteNumbers", value);
                                            }}
                                        />
                                        <TextField
                                            label="Spese fisse(&euro;)*"
                                            value={toLocalePrice(fund.costs, 2, false)}
                                            InputProps={{
                                                readOnly: true,
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                }
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid xs={6} sm={6} md={3} lg={3} item>
                                    <Box display="flex" gap={2} flexDirection="column">
                                        <TextField
                                            label="Costo unitario(&euro;)*"
                                            InputProps={{
                                                readOnly: true,
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                }
                                            }}
                                            value={toLocalePrice(fund.quotesAmount, 2, false)}
                                        />
                                        <TextField
                                            label="Spese variabili(&euro;)*"
                                            InputProps={{
                                                readOnly: true,
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                }
                                                }}
                                                value={toLocalePrice(fund.quotesExpenses * subscriptionForm.quoteNumbers, 2, false)}
                                        />
                                        <TextField
                                            label="Profitto previsto(%)*"
                                            InputProps={{
                                                readOnly: true,
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                }
                                            }}
                                            value={toLocalePrice(fund.expectedProfit, 2, false)}
                                        />
                                        <TextField
                                            label="Commissioni(%)*"
                                            InputProps={{
                                                readOnly: true,
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                }
                                            }}
                                            value={toLocalePrice(fund.commission, 2, false)}
                                        />
                                        <StyledDescriptionText sx={{ height: '40px', alignItems: 'center', displa: 'flex' }}>
                                            Profitto al netto delle spese e delle commissioni
                                        </StyledDescriptionText>
                                        <TextField
                                            label="Stop loss target(%)*"
                                            InputProps={{
                                                readOnly: true,
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                }
                                            }}
                                            value={toLocalePrice(fund.stopLoseTarget, 2, false)}
                                        />
                                        <TextField
                                            label="Take profit target(%)*"
                                            InputProps={{
                                                readOnly: true,
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                }
                                            }}
                                            value={toLocalePrice(fund.takeProfitTarget, 2, false)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid xs={6} sm={6} md={3} lg={3} item>
                                    <Box display="flex" gap={2} flexDirection="column">
                                        <TextField
                                            label="Totale sottoscrizione(&euro;)*"
                                            InputProps={{
                                                readOnly: true,
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                }
                                            }}
                                            value={toLocalePrice(subscription.total, 2, false)}
                                            />
                                        <TextField
                                            label="Totale spese(&euro;)*"
                                            InputProps={{
                                                readOnly: true,
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                }
                                            }}
                                                value={toLocalePrice(subscription.totalExpenses, 2, false)}
                                        />
                                        <TextField
                                            InputProps={{
                                                readOnly: true,
                                                inputProps: {
                                                    style: { textAlign: "right" },
                                                }
                                            }}
                                            value={toLocalePrice(subscription.expectedProfit, 2, false)}
                                        />
                                        <TextField
                                            InputProps={{
                                                readOnly: true,
                                                inputProps: {
                                                    style: {
                                                        textAlign: "right"
                                                    },
                                                }
                                            }}
                                            value={toLocalePrice(subscription.expectedCommission, 2, false)}
                                        />
                                        <TextField
                                            InputProps={{
                                                readOnly: true,
                                                inputProps: {
                                                    style: {
                                                        backgroundImage: "linear-gradient(45deg, #fff, #bdf5f5)",
                                                        textAlign: "right"
                                                    },
                                                }
                                            }}
                                            value={toLocalePrice(subscription.netProfit, 2, false)}
                                        />
                                        <TextField
                                            InputProps={{
                                                readOnly: true,
                                                inputProps: {
                                                    style: {
                                                        backgroundImage: "linear-gradient(45deg, #fff, #f5cfcf)",
                                                        textAlign: "right"
                                                    },
                                                }
                                            }}
                                            value={toLocalePrice(subscription.stopLose, 2, false)}
                                        />
                                        <TextField
                                            InputProps={{
                                                readOnly: true,
                                                inputProps: {
                                                    style: {
                                                        backgroundImage: "linear-gradient(45deg, #fff, #d2edd2)",
                                                        textAlign: "right"
                                                    },
                                                }
                                            }}
                                            value={toLocalePrice(subscription.takeProfit, 2, false)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid md={12} item>
                                    <Stack spacing={2} direction="row" justifyContent="end" alignItems="center">
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                            <Button onClick={handleSubmitForm}>
                                                {t("shared.save")}
                                            </Button>
                                        </Box>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    ) : (
                        <Box sx={{ width: '100%', height: '350px', display: 'flex', justifyContent: 'center', gap: 2, flexDirection: 'column', alignItems: 'center' }}>
                            <Icon sx={{ fontSize: '10rem', color: '#ddd' }}>
                                <MdPlaylistRemove />
                            </Icon>
                            <StyledDescriptionText sx={{ color: '#ddd', fontSize: '2rem', textAlign: 'center' }}>
                                {t('homePage.noWallets')}
                            </StyledDescriptionText>
                        </Box>
                    )
                )}
            </Box>
        </StyledPageContainer>
    );
}
