import axios from 'axios';
import {
    CreateInvitationRequest,
    CreateInvitationResponse,
    DeleteInvitationRequest,
    DeleteInvitationResponse,
    GetInvitationsRequest,
    GetInvitationsResponse,
} from '../types/invitations.types';

const baseUrl = '/api/invitations';

export const GetInvitations =
    async (request: GetInvitationsRequest) => await axios.get<GetInvitationsResponse>(`${baseUrl}`, request);

export const CreateInvitation =
    async (request: CreateInvitationRequest) => await axios.post<CreateInvitationResponse>(`${baseUrl}`, request.data);

export const DeleteInvitation =
    async (request: DeleteInvitationRequest) => await axios.delete<DeleteInvitationResponse>(`${baseUrl}/${request.params.oid}`);
