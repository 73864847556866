import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ErrorsOutlet, LoginOutlet, ProtectedOutlet, ProtectedSmallOutlet } from '..';
import { routes } from '../../../constants/appRoutes';
import { PageAdminWalletDetails, PageAdminWallets, PageConfirmEmail, PageConfirmForgotPassword, PageError500, PageForgotPassword, PageFundDetails, PageFundSubscribe, PageFunds, PageInvitations, PageLogin, PageRegistration, PageSubscriptionDetails, PageSubscriptions, PageUser, PageUserDetails, PageUsers, PageWalletDetails, PageWallets } from '../../../features';
import axios from 'axios';

export const AppRoutes = () => {

    const navigate = useNavigate();
    const theme = useTheme();
    const smNoMatches = useMediaQuery(theme.breakpoints.up('sm'));

    React.useEffect(() => {

        axios.interceptors.response.use(response => response, (error) => {
            if (error?.response?.status === 401) navigate('/', { replace: true });
            if (error?.response?.status === 403) navigate('/', { replace: true });
            if (error?.response?.status === 404) navigate('/', { replace: true });
            if (error?.response?.status === 419) navigate('/', { replace: true });
            return Promise.reject(error);
        });
    }, []);

    React.useEffect(() => {
        if (!smNoMatches) {
            navigate('/protected');
        }
    }, [smNoMatches]);

    return (
        <Routes>
            <Route path={routes.loginRoute} element={<LoginOutlet />}>
                <Route index element={<PageLogin />} />
                <Route path={routes.registration} element={<PageRegistration />} />
                <Route path={routes.forgotPassword} element={<PageForgotPassword />} />
                <Route path={routes.confirmForgotPassword} element={<PageConfirmForgotPassword />} />
                <Route path={routes.confirmEmail} element={<PageConfirmEmail />} />
            </Route>
            <Route path={routes.errors} element={<ErrorsOutlet />}>
                <Route index element={<PageError500 />} />
            </Route>
            <Route path={routes.protected} element={smNoMatches ? <ProtectedOutlet /> : <ProtectedSmallOutlet />}>
                <Route index element={smNoMatches ? <PageFunds /> : <PageSubscriptions/>} />
                <Route path={routes.user}>
                    <Route index element={<PageUser />} />
                </Route>
                <Route path={routes.users}>
                    <Route index element={<PageUsers />} />
                    <Route path={routes.fundDetails} element={<PageUserDetails />} />
                    <Route path={routes.adminWalletsView} element={<PageAdminWallets />} />
                    <Route path={routes.adminWalletsView + '/' + routes.walletDetails} element={<PageAdminWalletDetails />} />
                </Route>
                <Route path={routes.invitations}>
                    <Route index element={<PageInvitations />} />
                </Route>
                <Route path={routes.funds}>
                    <Route index element={<PageFunds />} />
                    <Route path={routes.fundDetails} element={<PageFundDetails />} />
                    <Route path={routes.subscriptions} element={<PageFundSubscribe />} />
                </Route>
                <Route path={routes.userSubscriptions}>
                    <Route index element={<PageSubscriptions />} />
                    <Route path={routes.fundDetails} element={<PageSubscriptionDetails />} />
                </Route>
                <Route path={routes.wallets}>
                    <Route index element={<PageWallets />} />
                    <Route path={routes.walletDetails} element={<PageWalletDetails />} />
                </Route>
            </Route>
        </Routes>
    );
};
