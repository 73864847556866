import { Box, Breadcrumbs, Link, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Location, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../constants/appRoutes";
import { PageTitle, StyledPageContainer } from '../../shared/components/Common';
import { SubscriptionApiDto } from '../../shared/types/subscriptions.types';
import { PanelDetails } from './components/PanelDetails';
import { PanelStatistics } from './components/PanelStatistics';
import { UserSubscriptionsLocationState, UserSubscriptionsPanelsMap } from '.';

export const PageSubscriptionDetails = () => {

    const { t } = useTranslation();
    const theme = useTheme();
    const smNoMatches = useMediaQuery(theme.breakpoints.up('sm'));

    const navigate = useNavigate();
    const location: Location<UserSubscriptionsLocationState> = useLocation();

    const locationSubscription = location.state.models?.subscription as SubscriptionApiDto;

    const [value, setValue] = React.useState<number>(UserSubscriptionsPanelsMap.Details);

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleRedirect = () => {
        navigate(`/protected/${routes.userSubscriptions}`, location);
    };

    return (
        <StyledPageContainer>
            <PageTitle
                title={t("shared.sectionUserSubscriptions")}
                breadcrumbs={(
                    <Breadcrumbs>
                        <Link onClick={handleRedirect} sx={{ fontSize: '0.8rem' }} >
                            {t("shared.sectionUserSubscriptions")}
                        </Link>
                        <Typography sx={{ fontSize: '0.8rem', color: '#1c6e3d', fontWeight: '500', wordBreak: 'break-all' }}>
                            {locationSubscription.fund.name}
                        </Typography>
                    </Breadcrumbs>
                )}
            />

            <Box>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label={t("fundDetailsPage.subscriptions")} value={UserSubscriptionsPanelsMap.Details} />
                    <Tab label={t("fundDetailsPage.statistics")} value={UserSubscriptionsPanelsMap.Statistics} />
                </Tabs>
                <Box
                    sx={{
                        boxSizing: 'border-box',
                        overflow: 'auto',
                        paddingTop: '8px',
                        borderTop: '1px solid var(--primary-wo)',
                        height: smNoMatches ? 'calc(100vh - 132px)' : 'auto',
                    }}
                >
                    {value === UserSubscriptionsPanelsMap.Details && (
                        <PanelDetails />
                    )}
                    {value === UserSubscriptionsPanelsMap.Statistics && (
                        <PanelStatistics />
                    )}
                </Box>
            </Box>
        </StyledPageContainer>
    );
};
