import { Box, Icon, IconButton, Stack, Tooltip, styled, useMediaQuery, useTheme } from '@mui/material';
import moment from "moment";
import { default as React } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTrash, FaWallet } from "react-icons/fa";
import { TbListDetails } from 'react-icons/tb';
import { CFFDateFormat } from '../../../constants/appRegex';
import { useMathUtilities } from '../../hooks/useMathUtilities';

const StyledContainer = styled(Box)<{ status: string }>(({ status }) => ({
    boxSizing: 'border-box',
    border: `2px solid var(--wallet-${status})`,
    padding: '16px 16px 0',
    color: '#000',
    borderRadius: '4px',
    gap: 1,
    minHeight: '105px',
    display: 'flex',
    flexDirection: 'column'
}));

interface WalletCardProps {
     model: {
         code: number;
         userId: string;
         name: string;
         description: string;
         creationDate: string;
         amount: string;
         enabled: boolean;
         deactivationDate: string;
     };
    events?: {
        handleDelete?: (wallet: WalletCardProps['model']) => void;
        handleDetailsClick?: (wallet: WalletCardProps['model']) => void;
        handleDeleteClick?: (wallet: WalletCardProps['model']) => void;
    }
}

export const WalletCard = (props: WalletCardProps) => {

    const { events, model } = props;

    const { t } = useTranslation();
    const theme = useTheme();
    const mdNoMatches = useMediaQuery(theme.breakpoints.up('md'));

    const mathUtilities = useMathUtilities();

    return (
        <StyledContainer status={`enabled-${model.enabled}`}>
            <Box display="flex" flexDirection="column" justifyContent="center" gap={1}>
                <Box display="flex" alignItems="center" gap={1}>
                    <Icon fontSize="medium">
                        <FaWallet />
                    </Icon>
                    <Box display="flex" flexDirection="column" fontSize="1rem" flexGrow={1}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" fontWeight={500} >
                            <Box flexGrow={1} sx={{ wordBreak: 'break-all' }}>
                                {model.name}
                            </Box>
                            <Box sx={{ wordBreak: 'break-all' }}>
                                {mathUtilities.toLocalePrice(+model?.amount)}€
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column" sx={{ height: '50px' }}>
                    <em style={{ fontWeight: '400', fontSize: '0.8rem', flexGrow: 1 }}>
                        {t("walletsPage.creationDate", {
                            creationDate: moment(model.creationDate).format(CFFDateFormat.long),
                        })}
                    </em>
                    {!model.enabled && (
                        <em style={{ fontWeight: '400', fontSize: '0.8rem', flexGrow: 1 }}>
                            {t("walletsPage.deactivationDate", {
                                deactivationDate: moment(model.deactivationDate).format(CFFDateFormat.long),
                            })}
                        </em>
                    )}
                </Box>
            </Box>
            <Stack direction="row" width="100%" justifyContent="end" alignItems="center" spacing={1} mb={1}>
                {mdNoMatches && events?.handleDetailsClick && (
                    <Tooltip title={t("shared.details")}>
                        <IconButton
                            size="small"
                            onClick={() => {
                                if (events?.handleDetailsClick)
                                    events.handleDetailsClick(model)
                            }}
                        >
                            <TbListDetails />
                        </IconButton>
                    </Tooltip>
                )}
                {mdNoMatches && events?.handleDeleteClick && (
                    <Tooltip title={t("shared.delete")}>
                        <IconButton
                            size="small"
                            onClick={() => {
                                if (events?.handleDeleteClick)
                                    events.handleDeleteClick(model)
                            }}
                        >
                            <FaTrash style={{ color: 'var(--error)' }} />
                        </IconButton>
                    </Tooltip>
                )}
            </Stack>
        </StyledContainer>
    );
}
