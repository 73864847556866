import { Refresh } from '@mui/icons-material';
import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaWallet } from 'react-icons/fa';
import { FaSackDollar } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants/appRoutes';
import { RT, TFTable } from '../../shared/components';
import { PageTitle, StyledPageContainer } from '../../shared/components/Common';
import { useNotifyContext } from '../../shared/contexts';
import { GetUsers } from '../../shared/helpers';
import { useErrorTranslation } from '../../shared/hooks';
import { CFF } from '../../shared/types';

export const PageUsers = () => {

    const tableRef = React.useRef() as React.MutableRefObject<RT<CFF.ApplicationUser>>;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const notify = useNotifyContext();
    const getErrorTranslation = useErrorTranslation();

    return (
        <StyledPageContainer>
            <PageTitle title="Utenti" />
            <Grid container spacing={2} px={2}>
                <Grid sm={12} item>
                    <TFTable<CFF.ApplicationUser>
                        ref={tableRef}
                        initialize={{
                            sorting: {
                                order: "Asc",
                                field: "ExpirationDate"
                            }
                        } }
                        data={() => {

                            return new Promise((result) => {

                                GetUsers().then(({ data }) => {

                                    result({
                                        data: data,
                                        totalCount: data.length
                                    });
                                }).catch((ev) => {

                                    const status = ev.response?.data?.errorCode || ev.response?.status || 500;
                                    const url = ev.config.url;
                                    notify.warning({
                                        content: getErrorTranslation(url, status)
                                    });
                                });
                            })
                        }}
                        actions={[
                            {
                                icon: <Refresh />,
                                tooltip: t("shared.table.TT_refresh"),
                                onClick: () => {
                                    tableRef.current.reload();
                                }
                            }
                        ]}
                        options={{
                            usePagination: false,
                            useSearch: false,
                            localize: {
                                noData: t('shared.table.gridEmptyMsg'),
                                resetFilters: t('shared.table.resetFilters'),
                                search: t('shared.table.search'),
                                displayedElementsSeparator: t('shared.table.displayedElementsSeparator'),
                                rowsForPage: t('shared.table.rowsForPage'),
                            },
                            minWidth: "400px",
                            style: {
                                maxHeight: "calc(100vh - 130px)"
                            },
                            filtersAdjustmentStyle: {
                                top: '40px'
                            }
                        }}
                        columns={[
                            {
                                title: () => t("usersPage.userName"),
                                render: ({ userName }) => userName,
                            },
                            {
                                title: () => t("usersPage.email"),
                                render: ({ email }) => email,
                            },
                            {
                                title: () => t("usersPage.roles"),
                                render: ({ roles }) => roles?.join(', '),
                            },
                            {
                                tooltip: t("shared.sectionWallets"),
                                icon: <FaWallet style={{ fill: 'var(--primary)' }} />,
                                style: { padding: '0 5px', width: 40, textAlign: "center", svg: { color: 'var(--primary)', fill: 'var(--primary)' } },
                                thStyle: { padding: '0 5px', width: 40, textAlign: "center", svg: { color: 'var(--primary)', fill: 'var(--primary)' } },
                                onClick: (data) => {
                                    navigate(routes.adminWalletsView, {
                                        state: {
                                            models: {
                                                user: {
                                                    userId: data.id,
                                                    email: data.email
                                                }
                                            }
                                        }
                                    });
                                },
                            },
                            {
                                tooltip: t("userSubscriptions.pageTitle"),
                                icon: <FaSackDollar style={{ fill: 'var(--primary)' }} />,
                                style: { padding: '0 5px', width: 40, textAlign: "center", svg: { color: 'var(--primary)', fill: 'var(--primary)' } },
                                thStyle: { padding: '0 5px', width: 40, textAlign: "center", svg: { color: 'var(--primary)', fill: 'var(--primary)' } },
                                onClick: (data) => {
                                    navigate(routes.fundDetails, {
                                        state: {
                                            models: {
                                                user: {
                                                    userId: data.id,
                                                    email: data.email
                                                }
                                            }
                                        }
                                    });
                                },
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        </StyledPageContainer>
    );
};
