import { Box, Link, Paper } from '@mui/material';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { routes } from '../../../constants/appRoutes';
import { ApplicationLogo } from '../../components/Common/ApplicationLogo';

export const ErrorsOutlet = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleRedirect = React.useCallback(() => {
        navigate(routes.loginRoute, { replace: true });
    }, [navigate]);

    return (
        <Box
            height="100%"
            width="100vw"
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="150px"
        >
            <Paper
                sx={{
                    padding: '1rem 2.5rem 2.5rem',
                    width: '250px',
                }}
            >
                <Box mb={4} mt={2} mx={2}>
                    <ApplicationLogo />
                </Box>
                <Outlet />
                <Box mt={2}>
                    <Link onClick={handleRedirect}>
                        {t("errorsOutlet.goToLogin")}
                    </Link>
                </Box>
            </Paper>
        </Box>
    );
};
