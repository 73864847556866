export * from './ConfirmEmailPage';
export * from './ConfirmForgotPasswordPage';
export * from './Error500Page';
export * from './ForgotPasswordPage';
export * from './ShopPage';
export * from './InvitationsPage';
export * from './LogInPage';
export * from './UserPage';
export * from './UserSubscriptionsPage';
export * from './UsersPage';
export * from './WalletsPage';
export * from './RegistrationPage'

/**
 * Oggetto di utility, fornisce una mappatura standard 
 * per gli elementi che implementano una divisione chart/table
 */
export enum CTDPanelsMap {
    Chart,
    Table
};

export interface Filters {
    status: string[];
    search: string;
};
