import { Cancel, EventNote } from "@mui/icons-material";
import { IconButton, TextField, TextFieldProps } from "@mui/material";
import React from "react";

export const CustomDateInput = React.forwardRef((props: TextFieldProps & { clear?: () => void }, ref: any) => {

    const { clear, ...rest } = props;

    return (
        <TextField
            ref={ref}
            {...rest}
            InputProps={{
                ...props.InputProps,
                endAdornment: (
                    <>
                        {props?.value && clear && !props?.disabled && !props?.InputProps?.readOnly && (
                            <IconButton onClick={clear}>
                                <Cancel />
                            </IconButton>
                        )}
                        <EventNote />
                    </>
                )
            }}
        />
    );
});
