import { Box } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import * as React from 'react';
import { Filters, TFTableProps } from './TFTable';

/** Stili di default per le sezioni della tabella */
const pageNumberPaginationStyle = {
    fontSize: '14px',
    '.MuiPaginationItem-firstLast': {
        color: '#1c6e3d'
    },
    '.MuiPaginationItem-previousNext': {
        color: '#1c6e3d'
    },
    '.Mui-selected': {
        backgroundColor: 'white!important',
        color: '#1c6e3d'
    },
    'button:hover:not(Mui-selected)': {
        backgroundColor: '#DEF0F7',
        color: '#1c6e3d'
    }
};

const pageNumberPaginationContainerStyle = {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
}

type VdsPageNumberPaginationProps<R> = {
    loader: boolean;
    totalCount: number;
    pageNumber: number;
    pageSize: number;
    options: TFTableProps<R>['options'];
    submit: (filters: Partial<Filters>) => void;     // Evento di applicazione del filtro
}

export const VdsPageNumberPagination: <R>(props: VdsPageNumberPaginationProps<R>) => React.ReactElement | null = (props) => {

    return (
        <Box component="span" sx={pageNumberPaginationContainerStyle} >
            {props.loader && props.options?.usePagination && (
                <>-</>
            )}
            {!props.loader && props.options?.usePagination && (
                <Pagination
                    count={props.pageSize > 0 ? Math.ceil(props.totalCount / props.pageSize) : 0}
                    page={props.pageNumber}
                    shape="rounded"
                    siblingCount={0} 
                    onChange={(ev, pageNumber) => props.submit({ pageNumber })}
                    sx={pageNumberPaginationStyle}
                    showFirstButton={props.totalCount > 0}
                    showLastButton={props.totalCount > 0}
                />
            )}
        </Box>
    );
}