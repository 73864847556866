import React from "react";

export const useMathUtilities = () => {

    const round = React.useCallback((n: number, digits = 2) => {
        var multiplicator = Math.pow(10, digits);
        n = parseFloat((n * multiplicator).toFixed(11));
        var rounded = (Math.round(n) / multiplicator);
        var result = rounded.toFixed(digits);
        return +result;
    }, []);

    const toLocalePrice = React.useCallback((n: number, digits = 2, useSign = true) => {
        const currentLocale = navigator.language || 'en-US'; // Imposta la lingua corrente del browser come predefinita
        const options = {
            minimumFractionDigits: digits || 2,
            maximumFractionDigits: digits || 2
        };
        return `${(useSign && n > 0) ? '+' : ''}${(n || 0).valueOf().toLocaleString(currentLocale, options)}`;
    }, []);

    return {
        round, 
        toLocalePrice
    }
}