import { IconButton, Tooltip } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import loading3 from '../../../assets/images/loading3.gif';
import { Filters, TFTableProps } from './TFTable';
import { columnTypeGuard } from './TFTableHeader';

/** Traduzioni di default per le sezioni della tabella */
const tsl = {
    noData: 'No data'
}

const ContainerWithTooltip = React.forwardRef(function ContainerWithTooltip({ children, ...props }: any, ref: any) {
    return <div {...props} ref={ref}>{children}</div>
});

type ConditionalTFTableBodyProps<R> = {
    loader?: boolean;
    totalCount: number;
    pageSize: number;
    columns: TFTableProps<R>['columns'];
    options: TFTableProps<R>['options'];
    events: TFTableProps<R>['events'];
    data: R[];
    filters: Filters;
}

export const ConditionalTFTableBody: <R>(props: ConditionalTFTableBodyProps<R>) => React.ReactElement = (props) => {

    return (
        <TableBody>
            {props.loader && (
                <TableRow>
                    <TableCell colSpan={props.columns.length} sx={{ textAlign: 'center', backgroundColor: '#1c6e3d07', }}>
                        <div
                            style={{
                                height: '20px',
                                backgroundPosition: 'center',
                                backgroundImage: `url(${loading3})`,
                                backgroundRepeat: 'no-repeat',
                            }}
                        >
                        </div>
                    </TableCell>
                </TableRow>
            )}
            {!props.loader && !props.totalCount && (
                <TableRow>
                    <TableCell
                        colSpan={props.columns.length}
                        sx={{
                            backgroundColor: '#1c6e3d07', 
                    textAlign: 'center', 
                    height: '20px'
                    }}
                    >
                        <div>
                            {props.options?.localize?.noData || tsl.noData}
                        </div>
                    </TableCell>
                </TableRow>
            )}
            {!props.loader && props.totalCount > 0 && props.data.map((row, ri) => {
                return (
                    <TableRow
                        key={`body-row-${ri}`}
                        sx={(props.options?.selectedRow && props.options?.selectedRow(row)) ? {
                            ...(props.options?.rowStyle && props.options?.rowStyle(row, { row: ri })),
                            'td:first-of-type': {
                                borderLeft: '3px solid #1c6e3d',
                                borderTop: '1px solid #1c6e3d',
                                borderBottom: '1px solid #1c6e3d'
                            },
                            'td': {
                                borderTop: '1px solid #1c6e3d',
                                borderBottom: '1px solid #1c6e3d',
                            },
                            'td:last-child': {
                                borderTop: '1px solid #1c6e3d',
                                borderBottom: '1px solid #1c6e3d',
                                borderRight: '1px solid #1c6e3d',
                            },
                        } : {
                                ...(props.options?.rowStyle && props.options?.rowStyle(row, { row: ri })),
                        }}
                        hover
                    >
                        {props.columns.map((column, i) => {

                            if (column && columnTypeGuard(column)) {

                                return (
                                    <TableCell
                                        key={`row-${ri}-cell-${i}`}
                                        onClick={() => {
                                            if (!column.ignoreClick) {
                                                if (props?.options?.disableRow) {
                                                    const disabled = props.options.disableRow(row, { row: ri });
                                                    if (!disabled) {
                                                        props?.events?.onRowClick && props.events.onRowClick(row, props.filters);
                                                    }
                                                } else {
                                                    props?.events?.onRowClick && props.events.onRowClick(row, props.filters);
                                                }
                                            }
                                        }}
                                        style={{
                                            cursor: (!(column.ignoreClick || (props?.options?.disableRow && props?.options?.disableRow(row, { row: ri }))) && props.events?.onRowClick) ? 'pointer' : 'text',
                                            wordBreak: 'break-word',
                                            whiteSpace: 'break-spaces',
                                            ...column.style,
                                            backgroundColor: '#1c6e3d07'
                                        }}
                                    >
                                        {column?.tooltip ? (
                                            <Tooltip title={(column?.tooltip as any)(row) || ''} placement="bottom" arrow>
                                                <span>
                                                    <ContainerWithTooltip>
                                                        {column.render(row, props.filters, { row: ri }) as string}
                                                    </ContainerWithTooltip>
                                                </span>
                                            </Tooltip>
                                        ) : column.render(row, props.filters, { row: ri }) as string}
                                    </TableCell>
                                )
                            } else {

                                if (column?.hidden) {

                                    return (
                                        <TableCell
                                            key={`row-${ri}-cell-${i}`}
                                            style={{
                                                wordBreak: 'break-word',
                                                ...column.style,
                                                backgroundColor: '#1c6e3d07'
                                            }}
                                        >
                                            {!column.hidden(row, { row: ri }) && <Tooltip title={''+column.tooltip || ''}>
                                                <span>
                                                    <IconButton onClick={() => column.onClick(row, props.filters)} size="small" disabled={column.disabled && column.disabled(row)}>
                                                        {(typeof column.icon === 'function' ? column.icon(row, props.filters) : column.icon) as any}
                                                    </IconButton>
                                                </span>
                                            </Tooltip>}
                                        </TableCell>
                                    );
                                }

                                return (
                                    <TableCell
                                        key={`row-${ri}-cell-${i}`}
                                        style={{
                                            wordBreak: 'break-word',
                                            ...column?.style,
                                            backgroundColor: '#1c6e3d07'
                                        }}
                                    >
                                        <Tooltip title={''+column?.tooltip || ''}>
                                            <span>
                                                <IconButton onClick={() => column?.onClick(row, props.filters)} size="small" disabled={column?.disabled && column.disabled(row)}>
                                                    {(typeof column?.icon === 'function' ? column?.icon(row, props.filters) : column?.icon) as any}
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </TableCell>
                                );
                            }
                        })}
                    </TableRow>
                );
            })}
        </TableBody>
    );
}