import { IconButton, styled } from "@mui/material";

export const StyledToolbarIconButton = styled(IconButton)({
    borderRadius: '4px',
    border: '1px solid var(--primary-wo)',
    color: 'var(--primary-wo)',
    ':hover': {
        opacity: 0.8,
        background: '#152935',
        color: '#fff'
    }
})