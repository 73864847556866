import { Add, Refresh } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Switch, TextField } from '@mui/material';
import { default as React } from 'react';
import { useTranslation } from 'react-i18next';
import { BiTrash } from 'react-icons/bi';
import { FaRegCopy } from 'react-icons/fa';
import { COMMON_EMAIL_VALIDATOR, COMMON_IP_VALIDATOR } from '../../../constants/appRegex';
import { RT, TFTable } from '../../../shared/components';
import { useDialogContext, useLoaderContext, useNotifyContext } from '../../../shared/contexts';
import { AddBasicAuth, AddIpAddress, DeleteBasicAuth, DeleteIpAddress, GetBasicAuths, GetIpAddress, UpdateFund } from '../../../shared/helpers';
import { useErrorTranslation, useStandardCatch } from "../../../shared/hooks";
import { FundApiDto } from '../../../shared/types';

interface IPAddressModel {
    oid: string;
    address: string;
    fundOid: string;
}

interface BasicAuthModel {
    base64: string;
    username: string;
    oid: string;
}

interface DetailsPanelProps {
    setFund: React.Dispatch<React.SetStateAction<FundApiDto>>;
    fund: FundApiDto;
}

export const useSecurityPanel = (props: DetailsPanelProps) => {

    const tableRef = React.useRef() as React.MutableRefObject<RT<IPAddressModel>>;
    const basicAuthTableRef = React.useRef() as React.MutableRefObject<RT<BasicAuthModel>>;

    const notify = useNotifyContext();
    const { setLoader } = useLoaderContext();
    const { t } = useTranslation();
    const { baseCatch } = useStandardCatch();

    const getErrorTranslation = useErrorTranslation();

    const handleEnableFilters: React.ChangeEventHandler<HTMLInputElement> = React.useCallback((ev) => {

        setLoader(true);

        const state = {
            ...props.fund,
            ipCheck: ev.target.checked
        };

        UpdateFund({
            params: {
                oid: props.fund.oid
            },
            data: state
        })
            .then(() => {
                setLoader(false);
                props.setFund(state);
            })
            .catch(ev => {
                setLoader(false);
                const url = '/api/funds';
                baseCatch(ev, url);
            });
    }, []);

    const [ipDialog, setIpDialog] = React.useState({
        ipAddress: {} as IPAddressModel,
        ipAddressErrors: {} as Record<string, string | boolean>,
        open: false
    });
    const [basicAuthDialog, setBasicAuthDialog] = React.useState({
        basicAuth: {} as BasicAuthModel,
        basicAuthErrors: {} as Record<string, string | boolean>,
        open: false
    });

    const toggleIpDialogState = () => {

        setIpDialog({
            ...ipDialog,
            open: !ipDialog.open
        });
    }
    const toggleBasicAuthDialogState = () => {

        setBasicAuthDialog({
            ...basicAuthDialog,
            open: !basicAuthDialog.open
        });
    }
    const handleIpAddressChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {

        setIpDialog({
            ...ipDialog,
            ipAddress: {
                ...ipDialog.ipAddress,
                address: ev.target.value
            },
            ipAddressErrors: {
                address: COMMON_IP_VALIDATOR(ev.target.value) ? t("validations.ipAddress") : false
            }
        });
    }

    const handleBasicUsernameChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {

        setBasicAuthDialog({
            ...basicAuthDialog,
            basicAuth: {
                ...basicAuthDialog.basicAuth,
                username: ev.target.value
            },
            basicAuthErrors: {
                username: COMMON_EMAIL_VALIDATOR(ev.target.value) ? t("validations.basicUsername") : false
            }
        });
    }

    const handleIpAddressSubmit = () => {
        const ipError = COMMON_IP_VALIDATOR(ipDialog.ipAddress.address);
        setIpDialog({
            ...ipDialog,
            ipAddressErrors: {
                address: ipError ? t("validations.ipAddress") : false
            }
        });
        if (!ipError) {

            setLoader(true);

            AddIpAddress({
                data: {
                    address: ipDialog.ipAddress.address,
                    fundOid: props.fund.oid
                }
            })
                .then(() => {
                    setLoader(false);
                    toggleIpDialogState();
                    tableRef.current.reload();
                    notify.success({
                        content: t("success:addIpAddress")
                    });
                })
                .catch(ev => {
                    setLoader(false);
                    const status = ev.response?.data?.errorCode || ev.response?.status || 500;
                    const url = ev.config.url;
                    notify.warning({
                        content: getErrorTranslation(url, status)
                    });
                });
        }
    }

    const handleBasicAuthSubmit = () => {

        const usernameError = COMMON_EMAIL_VALIDATOR(basicAuthDialog.basicAuth.username);

        setBasicAuthDialog({
            ...basicAuthDialog,
            basicAuthErrors: {
                username: usernameError ? t("validations.basicUsername") : false
            }
        });

        if (!usernameError) {

            setLoader(true);

            AddBasicAuth({
                params: {
                    fundOid: props.fund.oid
                },
                data: basicAuthDialog.basicAuth
            })
                .then(() => {
                    setLoader(false);
                    toggleBasicAuthDialogState();
                    basicAuthTableRef.current.reload();
                    notify.success({
                        content: t("success:addBasicAuth")
                    });
                })
                .catch(ev => {
                    setLoader(false);
                    const status = ev.response?.data?.code || ev.response?.status || 500;
                    const url = '/api/basicauth';
                    notify.warning({
                        content: getErrorTranslation(url, status)
                    });
                });
        }
    }
    return {
        models: {
            basicAuthDialog,
            setIpDialog,
            setBasicAuthDialog,
            ipDialog,
            basicAuthTableRef,
            tableRef,
        },
        events: {
            toggleBasicAuthDialogState,
            handleIpAddressChange,
            toggleIpDialogState,
            handleIpAddressSubmit,
            handleBasicAuthSubmit,
            handleBasicUsernameChange,
            getErrorTranslation,
            handleEnableFilters
        },
    };
};

export const PanelSecurity: React.FC<DetailsPanelProps> = (props) => {

    const { t } = useTranslation();
    const notify = useNotifyContext();
    const setDialog = useDialogContext();
    const { setLoader } = useLoaderContext();

    const {
        models: {
            basicAuthDialog,
            setIpDialog,
            setBasicAuthDialog,
            ipDialog,
            basicAuthTableRef,
            tableRef,
        },
        events: {
            toggleBasicAuthDialogState,
            handleIpAddressChange,
            toggleIpDialogState,
            handleIpAddressSubmit,
            handleBasicAuthSubmit,
            handleBasicUsernameChange,
            getErrorTranslation,
            handleEnableFilters
        },
    } = useSecurityPanel(props);

    return (
        <Box px={2}>
            <Grid spacing={2} container>
                <Grid xs={6} sm={6} item>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: '11px' }}>
                        <Box sx={{ color: '#1c6e3d', fontWeight: '500' }}>
                            {t("fundDetailsPage.basicAuth")}
                        </Box>
                    </Box>
                    <TFTable<BasicAuthModel>
                        ref={basicAuthTableRef}
                        data={(query) => {

                            return new Promise((result) => {

                                GetBasicAuths({
                                    params: {
                                        fundOid: props.fund?.oid || ''
                                    }
                                }).then(({ data }) => {

                                    result({
                                        data: data.basicAuths,
                                        totalCount: data.basicAuths.length
                                    });
                                }).catch((ev) => {

                                    const status = ev.response?.data?.errorCode || ev.response?.status || 500;
                                    const url = ev.config.url;
                                    notify.warning({
                                        content: getErrorTranslation(url, status)
                                    });
                                });
                            })
                        }}
                        actions={[
                            {
                                icon: <Refresh />,
                                tooltip: t("shared.table.TT_refresh"),
                                onClick: () => {
                                    basicAuthTableRef.current.reload();
                                }
                            },
                            {
                                icon: <Add />,
                                tooltip: t("shared.table.add_Title"),
                                onClick: (ev, query) => {
                                    setBasicAuthDialog({
                                        basicAuth: {} as BasicAuthModel,
                                        basicAuthErrors: {},
                                        open: true
                                    });
                                }
                            },
                        ]}
                        options={{
                            usePagination: false,
                            useSearch: false,
                            localize: {
                                noData: t('shared.table.gridEmptyMsg'),
                                resetFilters: t('shared.table.resetFilters'),
                                search: t('shared.table.search'),
                                displayedElementsSeparator: t('shared.table.displayedElementsSeparator'),
                                rowsForPage: t('shared.table.rowsForPage'),
                            },
                            minWidth: "300px",
                            style: {
                                maxHeight: "calc(100vh - 275px)"
                            },
                            filtersAdjustmentStyle: {
                                top: '40px'
                            }
                        }}
                        columns={[
                            {
                                tooltip: t("shared.copy"),
                                icon: <FaRegCopy style={{ fill: '#1c6e3d' }} />,
                                style: { padding: '0 5px', width: 25, textAlign: "center", svg: { color: '#1c6e3d', fill: '#1c6e3d' } },
                                thStyle: { padding: '0 5px', width: 25, textAlign: "center", svg: { color: '#1c6e3d', fill: '#1c6e3d' } },
                                onClick: async (data) => {

                                    const type = "text/plain";
                                    var blob = new Blob([`Basic ${data.base64}`], { type });
                                    await navigator.clipboard.write([new ClipboardItem({ [type]: blob })]);
                                }
                            },
                            {
                                title: () => t("fundDetailsPage.basicUsername"),
                                render: ({ username }) => username,
                            },
                            {
                                tooltip: t("shared.delete"),
                                icon: <BiTrash style={{ fill: '#D82829' }} />,
                                style: { padding: '0 5px', width: 40, textAlign: "center", svg: { color: '#D82829', fill: '#D82829' } },
                                thStyle: { padding: '0 5px', width: 40, textAlign: "center", svg: { color: '#D82829', fill: '#D82829' } },
                                onClick: (data) => {

                                    setDialog({
                                        title: `${t('shared.dialogRemove_title')}`,
                                        content: t('fundDetailsPage.dialog_RemoveBasicAuth_body', { username: data.username }),
                                        yesCallback: (closeDialog) => {

                                            setLoader(true);
                                            DeleteBasicAuth({
                                                params: {
                                                    oid: data?.oid
                                                }
                                            }).then(({ data }) => {

                                                setLoader(false);
                                                basicAuthTableRef.current.reload();
                                                notify.success({
                                                    content: t("success:deleteBasicAuth")
                                                });
                                                closeDialog();
                                            }).catch((ev) => {

                                                setLoader(false);
                                                const status = ev.response?.data?.errorCode || ev.response?.status || 500;
                                                const url = ev.config.url;
                                                notify.warning({
                                                    content: getErrorTranslation(url, status)
                                                });
                                            });
                                        },
                                        noCallback: (closeDialog) => {

                                            closeDialog();
                                        }
                                    });
                                },
                            }
                        ]}
                    />
                </Grid>
                <Grid xs={6} sm={6} item>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: '8px' }}>
                        <Box sx={{ color: '#1c6e3d', fontWeight: '500' }}>
                            {t("fundDetailsPage.enableFilters")}
                        </Box>
                        <Switch checked={props.fund.ipCheck} onChange={handleEnableFilters} />
                    </Box>
                    {props.fund.ipCheck && (
                        <TFTable<IPAddressModel>
                            ref={tableRef}
                            data={(query) => {

                                return new Promise((result) => {

                                    GetIpAddress({
                                        params: {
                                            fundOid: props.fund?.oid || ''
                                        }
                                    }).then(({ data }) => {

                                        result({
                                            data: data?.ipAddresses ?? [],
                                            totalCount: data?.ipAddresses.length ?? 0
                                        });
                                    }).catch((ev) => {

                                        const status = ev.response?.data?.errorCode || ev.response?.status || 500;
                                        const url = ev.config.url;
                                        notify.warning({
                                            content: getErrorTranslation(url, status)
                                        });
                                    });
                                })
                            }}
                            actions={[
                                {
                                    icon: <Refresh />,
                                    tooltip: t("shared.table.TT_refresh"),
                                    onClick: () => {
                                        tableRef.current.reload();
                                    }
                                },
                                {
                                    icon: <Add />,
                                    tooltip: t("shared.table.add_Title"),
                                    onClick: (ev, query) => {
                                        setIpDialog({
                                            ipAddress: {} as IPAddressModel,
                                            ipAddressErrors: {},
                                            open: true
                                        });
                                    }
                                },
                            ]}
                            options={{
                                usePagination: false,
                                useSearch: false,
                                localize: {
                                    noData: t('shared.table.gridEmptyMsg'),
                                    resetFilters: t('shared.table.resetFilters'),
                                    search: t('shared.table.search'),
                                    displayedElementsSeparator: t('shared.table.displayedElementsSeparator'),
                                    rowsForPage: t('shared.table.rowsForPage'),
                                },
                                minWidth: "250px",
                                style: {
                                    maxHeight: "calc(100vh - 275px)"
                                },
                                filtersAdjustmentStyle: {
                                    top: '40px'
                                }
                            }}
                            columns={[
                                {
                                    title: () => t("fundDetailsPage.ipAddress"),
                                    render: ({ address }) => address,
                                },
                                {
                                    tooltip: t("shared.delete"),
                                    icon: <BiTrash style={{ fill: '#D82829' }} />,
                                    style: { padding: '0 5px', width: 40, textAlign: "center", svg: { color: '#D82829', fill: '#D82829' } },
                                    thStyle: { padding: '0 5px', width: 40, textAlign: "center", svg: { color: '#D82829', fill: '#D82829' } },
                                    onClick: (data) => {

                                        setDialog({
                                            title: `${t('shared.dialogRemove_title')}`,
                                            content: t('fundDetailsPage.dialog_RemoveIpAddress_body', { address: data.address }),
                                            yesCallback: (closeDialog) => {

                                                setLoader(true);
                                                DeleteIpAddress({
                                                    params: {
                                                        oid: data?.oid
                                                    }
                                                }).then(({ data }) => {

                                                    setLoader(false);
                                                    tableRef.current.reload();
                                                    notify.success({
                                                        content: t("success:deleteIpAddress")
                                                    });
                                                    closeDialog();
                                                }).catch((ev) => {

                                                    setLoader(false);
                                                    const status = ev.response?.data?.errorCode || ev.response?.status || 500;
                                                    const url = ev.config.url;
                                                    notify.warning({
                                                        content: getErrorTranslation(url, status)
                                                    });
                                                });
                                            },
                                            noCallback: (closeDialog) => {

                                                closeDialog();
                                            }
                                        });
                                    },
                                }
                            ]}
                        />
                    )}
                </Grid>
            </Grid>
            <Dialog
                sx={{
                    '& .MuiDialogTitle-root': {
                        color: '#1c6e3d',
                        borderBottom: '2px solid #1c6e3d',
                        backgroundColor: 'white'
                    },
                }}
                open={basicAuthDialog.open}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle>{t("fundDetailsPage.dialog_AddBasicAuth_Title")}</DialogTitle>
                <DialogContent >
                    <Grid container spacing={2} pt={2}>
                        <Grid sm={12} md={12} lg={12} item>
                            <TextField
                                label={`${t("fundDetailsPage.basicUsername")}*`}
                                name="username"
                                value={basicAuthDialog.basicAuth.username}
                                onChange={handleBasicUsernameChange}
                                error={!!basicAuthDialog.basicAuthErrors.username}
                                helperText={basicAuthDialog.basicAuthErrors.username}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: '#eee' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            toggleBasicAuthDialogState();
                        }}
                    >
                        {t("shared.close")}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleBasicAuthSubmit}
                    >
                        {t("shared.add")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                sx={{
                    '& .MuiDialogTitle-root': {
                        color: '#1c6e3d',
                        borderBottom: '2px solid #1c6e3d',
                        backgroundColor: 'white'
                    },
                }}
                open={ipDialog.open}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle>{t("fundDetailsPage.dialog_AddIp_Title")}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} pt={2}>
                        <Grid sm={12} md={12} lg={12} item>
                            <TextField
                                label={`${t("fundDetailsPage.ipAddress")}*`}
                                name="address"
                                value={ipDialog.ipAddress.address}
                                onChange={handleIpAddressChange}
                                error={!!ipDialog.ipAddressErrors.address}
                                helperText={ipDialog.ipAddressErrors.address}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: '#eee' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={toggleIpDialogState}
                    >
                        {t("shared.close")}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleIpAddressSubmit}
                    >
                        {t("shared.add")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
