import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const PageLoader = () => {

    const { t } = useTranslation();

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', gap: 2, alignItems: 'center' }}>
            {t('protectedOutlet.loading')} <CircularProgress size={20} />
        </Box>
    );
}
