import { Components } from "@mui/material/styles";

export const MuiTab: Components['MuiTab'] = {
    styleOverrides: {
        root: {
            color: '#1c6e3ddd',
            minHeight: '40px',
            textTransform: 'capitalize',
            '&.Mui-selected': {
                backgroundColor: '#1c6e3ddd',
                color: '#fff',
            }
        },
    }
};
