import { Components } from "@mui/material/styles";

export const MuiPaper: Components['MuiPaper'] = {
    defaultProps: {
        elevation: 4
    },
    styleOverrides: {
        root: {
            boxShadow: '0 0 4px #1c6e3d40'
        }
    }
};
