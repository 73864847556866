import { Add, Refresh } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BiTrash } from 'react-icons/bi';
import { COMMON_EMAIL_VALIDATOR } from '../../constants/appRegex';
import { BooleanCell, RT, TFTable } from '../../shared/components';
import { PageTitle } from '../../shared/components/Common';
import { useDialogContext, useLoaderContext, useNotifyContext } from '../../shared/contexts';
import { CreateInvitation, DeleteInvitation, GetInvitations } from '../../shared/helpers';
import { useStandardCatch } from '../../shared/hooks';
import { InvitationApiDto } from '../../shared/types';

const useInvitationsPage = () => {

    const tableRef = React.useRef() as React.MutableRefObject<RT<InvitationApiDto>>;
    const { t } = useTranslation();
    const { setLoader } = useLoaderContext();

    const notify = useNotifyContext();
    const { baseCatch } = useStandardCatch();

    const [invitationDialog, setInvitationDialog] = React.useState({
        open: false,
        invitation: {} as { email: string },
        invitationErrors: {} as Record<string, string | boolean>,
    });

    const handleInvitationUsernameChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {

        setInvitationDialog({
            ...invitationDialog,
            invitation: {
                ...invitationDialog.invitation,
                email: ev.target.value
            },
            invitationErrors: {
                email: COMMON_EMAIL_VALIDATOR(ev.target.value) ? t("validations.basicUsername") : false
            }
        });
    }

    const toggleInvitationDialogState = () => {

        setInvitationDialog({
            ...invitationDialog,
            open: !invitationDialog.open
        });
    }
    const handleInvitationSubmit = () => {

        const usernameError = COMMON_EMAIL_VALIDATOR(invitationDialog.invitation.email);

        setInvitationDialog({
            ...invitationDialog,
            invitationErrors: {
                email: usernameError ? t("validations.basicUsername") : false
            }
        });

        if (!usernameError) {

            setLoader(true);

            CreateInvitation({
                data: {
                    email: invitationDialog.invitation.email
                }
            })
                .then(() => {
                    setLoader(false);
                    toggleInvitationDialogState();
                    tableRef.current.reload();
                    notify.success({
                        content: t("success:createInvitation")
                    });
                })
                .catch(ev => {
                    setLoader(false);
                    const url = '/api/invitations';
                    baseCatch(ev, url);
                });
        }
    }

    return {
        handleInvitationSubmit,
        tableRef,
        setInvitationDialog,
        invitationDialog,
        toggleInvitationDialogState,
        handleInvitationUsernameChange
    }
}

export const PageInvitations = () => {

    const { t } = useTranslation();
    const { setLoader } = useLoaderContext();

    const notify = useNotifyContext();
    const setDialog = useDialogContext();
    const { baseCatch } = useStandardCatch();

    const {
        tableRef,
        setInvitationDialog,
        handleInvitationSubmit,
        invitationDialog,
        toggleInvitationDialogState,
        handleInvitationUsernameChange
    } = useInvitationsPage();

    return (
        <Box>
            <PageTitle title="Inviti" />
            <Grid container spacing={2} px={2}>
                <Grid sm={12} item>
                    <TFTable<InvitationApiDto>
                        ref={tableRef}
                        initialize={{
                            sorting: {
                                order: "Asc",
                                field: "ExpirationDate"
                            }
                        }}
                        data={(query) => {

                            return new Promise((result) => {

                                GetInvitations({
                                    params: {
                                        orderByDescending: query?.sorting?.order !== "Desc",
                                        orderByPropertyName: query?.sorting?.field,
                                        pageNumber: query?.pageNumber,
                                        pageSize: query?.pageSize,
                                    }
                                }).then(({ data }) => {

                                    result({
                                        data: data.invitations,
                                        totalCount: data.totalNumber
                                    });
                                }).catch((ev) => {
                                    baseCatch(ev);
                                });
                            })
                        }}
                        actions={[
                            {
                                icon: <Refresh />,
                                tooltip: t("shared.table.TT_refresh"),
                                onClick: () => {
                                    tableRef.current.reload();
                                }
                            },
                            {
                                icon: <Add />,
                                tooltip: t("shared.table.add_Title"),
                                onClick: () => {
                                    setInvitationDialog({
                                        invitation: {} as { email: string },
                                        invitationErrors: {},
                                        open: true
                                    });
                                }
                            },
                        ]}
                        options={{
                            usePagination: true,
                            useSearch: false,
                            localize: {
                                noData: t('shared.table.gridEmptyMsg'),
                                resetFilters: t('shared.table.resetFilters'),
                                search: t('shared.table.search'),
                                displayedElementsSeparator: t('shared.table.displayedElementsSeparator'),
                                rowsForPage: t('shared.table.rowsForPage'),
                            },
                            minWidth: "200px",
                            style: {
                                maxHeight: "calc(100vh - 180px)"
                            }
                        }}
                        columns={[
                            {
                                title: () => t("invitationsPage.email"),
                                render: ({ email }) => email,
                            },
                            {
                                title: () => t("invitationsPage.expirationDate"),
                                render: ({ expirationDate, accepted }) => accepted ? '---' : BooleanCell(moment(expirationDate).valueOf() <= moment().valueOf()),
                                thStyle: { width: 80, textAlign: 'center' },
                                style: { width: 80, textAlign: 'center' }
                            },
                            {
                                title: () => t("invitationsPage.isAccepted"),
                                render: ({ accepted }) => BooleanCell(accepted),
                                thStyle: { width: 80, textAlign: 'center' },
                                style: { width: 80, textAlign: 'center' }
                            },
                            {
                                tooltip: t("shared.delete"),
                                icon: <BiTrash style={{ fill: '#D82829' }} />,
                                style: { padding: '0 5px', width: 40, textAlign: "center", svg: { color: '#D82829', fill: '#D82829' } },
                                thStyle: { padding: '0 5px', width: 40, textAlign: "center", svg: { color: '#D82829', fill: '#D82829' } },
                                onClick: (data) => {

                                    setDialog({
                                        title: `${t('shared.dialogRemove_title')}`,
                                        content: t('invitationsPage.dialog_RemoveInvitation_body', { username: data.email }),
                                        yesCallback: (closeDialog) => {

                                            setLoader(true);
                                            DeleteInvitation({
                                                params: {
                                                    oid: data?.oid
                                                }
                                            }).then(({ data }) => {

                                                setLoader(false);
                                                tableRef.current.reload();
                                                notify.success({
                                                    content: t("success:deleteInvitation")
                                                });
                                                closeDialog();
                                            }).catch((ev) => {

                                                setLoader(false);
                                                const url = '/api/invitations';
                                                baseCatch(ev, url);
                                            });
                                        },
                                        noCallback: (closeDialog) => {

                                            closeDialog();
                                        }
                                    });
                                },
                            }
                        ]}
                    />
                    <Dialog
                        sx={{
                            '& .MuiDialogTitle-root': {
                                color: '#1c6e3d',
                                borderBottom: '2px solid #1c6e3d',
                                backgroundColor: 'white'
                            },
                        }}
                        open={invitationDialog.open}
                        fullWidth
                        maxWidth="xs"
                    >
                        <DialogTitle>{t("invitationsPage.dialog_AddBasicAuth_Title")}</DialogTitle>
                        <DialogContent >
                            <Grid container spacing={2} pt={2}>
                                <Grid xs={12} item>
                                    <TextField
                                        label={`${t("invitationsPage.email")}*`}
                                        name="email"
                                        value={invitationDialog.invitation.email}
                                        onChange={handleInvitationUsernameChange}
                                        error={!!invitationDialog.invitationErrors.email}
                                        helperText={invitationDialog.invitationErrors.email}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions sx={{ backgroundColor: '#eee' }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    toggleInvitationDialogState();
                                }}
                            >
                                {t("shared.close")}
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleInvitationSubmit}
                            >
                                {t("shared.send")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Grid>
        </Box>
    );
};
