import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, InputAdornment, Link, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { CFFRegex } from '../../constants/appRegex';
import { routes } from '../../constants/appRoutes';
import { useLoaderContext, useNotifyContext } from '../../shared/contexts';
import { ConfirmForgotPasswordRequest } from '../../shared/helpers';
import { useFormHelpers, useStandardCatch, useTextFieldVisibility } from '../../shared/hooks';

const useConfirmForgotPasswordPage = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const query = new URLSearchParams(location.search);
    const token = query.get("token");
    const id = query.get("id");

    const { setLoader } = useLoaderContext();
    const notify = useNotifyContext();
    const { baseCatch } = useStandardCatch();

    const formController = useFormHelpers(
        { newPassword: '', confirmPassword: '' }
    );

    const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {

        const newPassword = ev.target.value;

        formController.setState({
            ...formController.state,
            newPassword
        });
        formController.setErrors({
            newPassword: !newPassword ? t("validations.newPassword") : false,
            confirmPassword: !(newPassword === formController.state.confirmPassword) ? t("validations.confirmPassword") : false
        });
    }

    const handleConfirmPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {

        const confirmPassword = ev.target.value;

        formController.setState({
            ...formController.state,
            confirmPassword
        });
        formController.setErrors({
            ...formController.errors,
            confirmPassword: !(formController.state.newPassword && formController.state.newPassword === confirmPassword) ? t("validations.confirmPassword") : false
        });
    }

    const handleSubmitForm = () => {

        const passwordError = !CFFRegex.password.test(formController.state.newPassword);
        const confirmPasswordError = !(formController.state.newPassword && formController.state.newPassword === formController.state.confirmPassword);
       
        formController.setErrors({
            ...formController.errors,
            newPassword: passwordError ? t("validations.password") : false,
            confirmPassword: confirmPasswordError ? t("validations.confirmPassword") : false,
        });

        if (!(passwordError || confirmPasswordError) && id && token) {

            setLoader(true);

            ConfirmForgotPasswordRequest({
                data: {
                    id,
                    token,
                    newPassword: formController.state.newPassword,
                }
            })
                .then(() => {
                    setLoader(false);
                    navigate('/' + routes.protected, { replace: true });
                    notify.success({
                        content: t("success:resetPassword")
                    });
                })
                .catch((ev) => {
                    setLoader(false);
                    baseCatch(ev);
                });
        }
    }

    const handleRedirect = () => {
        navigate(routes.loginRoute, { replace: true });
    }

    return {
        models: {
            formModel: formController.state,
            formErrorModel: formController.errors,
        },
        events: {
            handlePasswordChange,
            handleConfirmPasswordChange,
            handleSubmitForm,
            handleRedirect
        },
    }
}

export const PageConfirmForgotPassword = () => {

    const { t } = useTranslation();

    const newPassword = useTextFieldVisibility();
    const confirmPassword = useTextFieldVisibility();

    const {
        models: {
            formModel,
            formErrorModel,
        },
        events: {
            handlePasswordChange,
            handleConfirmPasswordChange,
            handleSubmitForm,
            handleRedirect
        },
    } = useConfirmForgotPasswordPage();

    return (
        <Box component="form" autoComplete="off">
            <Grid container spacing={2}>
                <Grid xs={12} item>
                    <TextField
                        label={`${t("forgotPassword.newPassword")}*`}
                        type={newPassword.visibility ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={newPassword.setVisibility}
                                        edge="end"
                                    >
                                        {newPassword.visibility ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        name="newPassword"
                        value={formModel.newPassword}
                        onChange={handlePasswordChange}
                        error={Boolean(formErrorModel.newPassword)}
                        helperText={formErrorModel.newPassword}
                    />
                </Grid>
                <Grid xs={12} item>
                    <TextField
                        label={`${t("forgotPassword.confirmPassword")}*`}
                        type={confirmPassword.visibility ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={confirmPassword.setVisibility}
                                        edge="end"
                                    >
                                        {confirmPassword.visibility ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        name="confirmPassword"
                        value={formModel.confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        error={Boolean(formErrorModel.confirmPassword)}
                        helperText={formErrorModel.confirmPassword}
                    />
                </Grid>
                <Grid xs={12} item>
                    <Button onClick={handleSubmitForm} fullWidth>
                        {t('forgotPassword.send')}
                    </Button>
                </Grid>
                <Grid xs={12} item>
                    <Link onClick={handleRedirect}>
                        {t("forgotPassword.goBack")}
                    </Link>
                </Grid>
            </Grid>
        </Box>
    );
};
