import axios from 'axios';
import {
    AddBasicAuthsRequest,
    GetBasicAuthsRequest,
    GetBasicAuthsResponse,
    DeleteBasicAuthsRequest,
} from '../types/basicAuth.types';

const baseUrl = '/api/basicauth';

export const GetBasicAuths =
    async (request: GetBasicAuthsRequest) => await axios.get<GetBasicAuthsResponse>(`${baseUrl}/${request.params.fundOid}`);

export const AddBasicAuth =
    async (request: AddBasicAuthsRequest) => await axios.post(`${baseUrl}/${request.params.fundOid}`, request.data);

export const DeleteBasicAuth =
    async (request: DeleteBasicAuthsRequest) => await axios.delete(`${baseUrl}/${request.params.oid}`);
