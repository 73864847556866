import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, IconButton, InputAdornment, Link, TextField } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CFFRegex } from '../../constants/appRegex';
import { routes } from '../../constants/appRoutes';
import { useLoaderContext } from '../../shared/contexts';
import { SignIn } from '../../shared/helpers';
import { useStandardCatch, useTextFieldVisibility } from '../../shared/hooks';

type Errors<R> = Record<keyof R, string | undefined | null | boolean>;
type Validators<R> = Partial<Record<keyof R, {
    pattern: (state: R) => Partial<Errors<R>>;
}>>;

interface LogInFormState {
    email: string;
    password: string;
}

export const PageLogin = () => {

    const { t } = useTranslation();

    const { visibility, setVisibility } = useTextFieldVisibility();

    const navigate = useNavigate();

    const { setLoader } = useLoaderContext();
    const { baseCatch } = useStandardCatch();

    const validators = React.useRef<Validators<LogInFormState>>({
        password: {
            pattern: ({ password }) => ({
                password: !CFFRegex.password.test(password) && t("validations.password")
            })
        },
        email: {
            pattern: ({ email }) => ({
                email: !CFFRegex.email.test(email) && t("validations.email")
            })
        },
    });

    const [formModel, setForm] = React.useState<LogInFormState>({ email: '', password: '' });
    const [formErrorModel, setFormErrors] = React.useState<Partial<Errors<LogInFormState>>>({
        email: '',
        password: '',
    });

    const change = (name: keyof LogInFormState, value: string) => {
        const newState = { ...formModel, [name]: value };
        setForm(newState);
        const correctValidator = validators.current[name];
        if (correctValidator?.pattern) {
            const newErrors = {
                ...formErrorModel,
                ...correctValidator.pattern(newState)
            };
            setFormErrors(newErrors);
        }
    };

    const validate = (complete: () => void, fallback?: () => void) => {
        if (validators.current) {
            let _errors = { ...formErrorModel };
            Object
                .keys(validators.current)
                .forEach((key) => {
                    const correctValidator = _.get(validators.current, key);
                    if (correctValidator?.pattern) {
                        _errors = {
                            ..._errors,
                            ...correctValidator.pattern(formModel)
                        };
                    }
                });
            setFormErrors({ ..._errors });
            const hasErrors = _.values(_errors);
            const counter = _.some(hasErrors, error => error);
            if (counter && fallback) {
                fallback();
            } else if (!counter) {
                complete();
            }
        } else {
            complete();
        }
    };

    const handleSubmitForm = (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        validate(() => {
            setLoader(true);
            SignIn({
                data: {
                    email: formModel.email,
                    password: formModel.password,
                }
            }).then(() => {
                navigate(routes.protected);
                setLoader(false);
            }).catch((ev) => {
                setLoader(false);
                baseCatch(ev);
            });
        });
    }

    const handleForgotPassword = React.useCallback(() => {
        navigate(routes.forgotPassword);
    }, [navigate]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={2}
            component="form"
            autoComplete="on"
            onSubmit={handleSubmitForm}
        >
            <TextField
                autoComplete="username"
                value={formModel.email || ''}
                error={Boolean(formErrorModel?.email)}
                helperText={formErrorModel.email}
                onChange={(ev) => {
                    const { value } = ev.target;
                    change('email', value);
                }}
            />
            <TextField
                autoComplete="current-password"
                type={visibility ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={setVisibility} edge="end">
                                {visibility ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                value={formModel.password || ''}
                error={Boolean(formErrorModel?.password)}
                helperText={formErrorModel.password}
                onChange={(ev) => {
                    const { value } = ev.target;
                    change('password', value);
                }}
            />
            <Button type="submit" fullWidth>
                {t('logInPage.logIn')}
            </Button>
            <Link onClick={handleForgotPassword}>
                {t('logInPage.forgotPassword')}
            </Link>
        </Box>
    );
};
