import { Components } from "@mui/material/styles";

export const MuiToolbar: Components['MuiToolbar'] = {
    defaultProps: {
        variant: 'dense',
    },
    styleOverrides: {
        root: {
            minHeight: '2.53rem',
            boxSizing: 'border-box',
        }
    }
};
