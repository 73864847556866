import { Components } from "@mui/material/styles";

export const MuiTooltip: Components['MuiTooltip'] = {
    defaultProps: {
        arrow: true
    },
    styleOverrides: {
        tooltip: {
            backgroundColor: '#1c6e3d',
            border: '1px solid #1c6e3d'
        },
        arrow: {
            color: '#1c6e3d'
        }
    }
};
