import * as React from 'react';

import loading3 from '../../../src/assets/images/loading3.gif';

type LoaderContextProps = {
    setLoader: React.Dispatch<React.SetStateAction<boolean>>;
    loader: boolean;
};

const LoaderContext = React.createContext<LoaderContextProps>({} as LoaderContextProps);

export const useLoaderContext = () => React.useContext(LoaderContext);

export const LoaderContextProvider: React.FC<{ children: React.ReactElement }> = ({ children }) => {

    const [loader, setLoader] = React.useState<boolean>(false);

    return (
        <LoaderContext.Provider value={{ setLoader, loader }}>
            {children}
            {loader && (
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        height: '100vh',
                        width: '100vw',
                        backgroundPosition: 'center',
                        backgroundImage: `url(${loading3})`,
                        backgroundRepeat: 'no-repeat',
                        opacity: 0.3,
                        backgroundColor: "#f2f5f8",
                        zIndex: 1500
                    }}
                />
            )}
        </LoaderContext.Provider>
    );
};
