import axios from 'axios';
import {
    AddIpAddressRequest,
    DeleteIpAddressRequest,
    GetIPAddressRequest,
    GetIPAddressResponse
} from '../types/ipaddress.types';

const baseUrl = '/api/ipaddress';

export const GetIpAddress = async (request: GetIPAddressRequest) =>
    await axios.get<GetIPAddressResponse>(`${baseUrl}`, { params: request.params });

export const AddIpAddress = async (request: AddIpAddressRequest) =>
    await axios.post(`${baseUrl}`, request.data);

export const DeleteIpAddress = async (request: DeleteIpAddressRequest) =>
    await axios.delete(`${baseUrl}/${request.params.oid}`);
