import { Components } from "@mui/material/styles";

export const MuiIconButton: Components['MuiIconButton'] = {
    defaultProps: {
    },
    styleOverrides: {
        root: {
            color: '#1c6e3d'
        }
    }
};
