import React from "react";
import { DialogContext } from "./DialogContext";
import { LoaderContextProvider } from "./LoaderContext";
import { NotifyContextProvider } from "./NotifyContext";
import { ProfileContextProvider } from "./ProfileContext";

export const RootContext:
    React.FC<{ children: React.ReactElement }> =
    ({ children }) => {
        return (
            <ProfileContextProvider>
                <LoaderContextProvider>
                    <NotifyContextProvider>
                        <DialogContext>
                            {children}
                        </DialogContext>
                    </NotifyContextProvider>
                </LoaderContextProvider>
            </ProfileContextProvider>
        );
    };
