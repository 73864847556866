import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "react-datepicker/dist/react-datepicker.css";
import { ThemeProvider } from "@mui/material";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./assets/css/custom.css";
import { App } from "./App";
import { appMuiTheme } from "./assets/theme";
import { RootContext } from "./shared/contexts";

const root = createRoot(document.getElementById('root')!);
root.render(
    <BrowserRouter>
        <ThemeProvider theme={appMuiTheme}>
            <RootContext>
                <App />
            </RootContext>
        </ThemeProvider>
    </BrowserRouter>
);
