import { useLocation } from 'react-router-dom';
import { Filters } from '..';
import { FundApiDto } from '../../shared/types/fund.types';

/**
 * Oggetto di utility, fornisce una mappatura dei tab
 * nella sezione di dettaglio
 */
export enum FundPanelsMap {
    Details,
    Statistics,
    Security,
    BackTests,
    Operations,
    Subscriptions,
}

export interface FundLocationState {
    models?: {
        activeTab: number;
        fund: FundApiDto;
    };
    filters?: Filters;
};

export const useFundLocationState = () => {
    const location = useLocation();
    return location.state as FundLocationState
}

/**
 * Modello per la gestione degli errori in uno stato generico R
 */
export type Errors<R> = Record<keyof R, any>;

/**
 * Definisci un tipo come riferimento per la validazione 
 * di una data propriet�
 */
export type Validators<R> = Partial<Record<keyof R, {
    pattern: (state: R) => Partial<Errors<R>>;
}>>;

export * from './PageFundDetails';
export * from './PageFunds';
export * from './PageFundSubscribe';

