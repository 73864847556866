import { Box, Breadcrumbs, Link, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Location, useLocation, useNavigate } from "react-router-dom";
import { UserLocationState } from '.';
import { routes } from "../../constants/appRoutes";
import { PageTitle, StyledPageContainer } from '../../shared/components/Common';
import { PanelSubscriptions } from './components/PanelSubscriptions';

const FundDetailsTabs = {
    Subscriptions: 0,
}

export const PageUserDetails = () => {

    const { t } = useTranslation();
    const theme = useTheme();
    const smNoMatches = useMediaQuery(theme.breakpoints.up('sm'));

    const navigate = useNavigate();
    const location: Location<UserLocationState> = useLocation();

    const tab = location.state?.models?.activeTab;
    const user = location.state?.models?.user;

    const [value, setValue] = React.useState<number>(tab || FundDetailsTabs.Subscriptions);

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleRedirect = () => {
        navigate(`/protected/${routes.users}`, location);
    }

    return (
        <StyledPageContainer>
            <PageTitle
                title={t("shared.sectionPlatformUsers")}
                breadcrumbs={(
                    <Breadcrumbs>
                        <Link onClick={handleRedirect} sx={{ fontSize: '0.8rem' }} >
                            {user?.email}
                        </Link>
                        <Typography sx={{ fontSize: '0.8rem', color: '#1c6e3d', fontWeight: '500', wordBreak: 'break-all' }}>
                            {t("fundDetailsPage.subscriptions")}
                        </Typography>
                    </Breadcrumbs>
                )}
            />

            <Box>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label={t("fundDetailsPage.subscriptions")} value={FundDetailsTabs.Subscriptions} />
                </Tabs>
                <Box
                    sx={{
                        boxSizing: 'border-box',
                        overflow: 'auto',
                        paddingTop: '8px',
                        borderTop: '1px solid var(--primary-wo)',
                        height: smNoMatches ? 'calc(100vh - 132px)' : 'auto',
                    }}
                >
                    {value === FundDetailsTabs.Subscriptions && user && (
                        <PanelSubscriptions user={user} />
                    )}
                </Box>
            </Box>
        </StyledPageContainer>
    );
};
