import { Components } from "@mui/material/styles";

export const MuiTextField: Components['MuiTextField'] = {
    defaultProps: {
        size: 'small',
        fullWidth: true,
        autoComplete: 'off',
        InputLabelProps: {
            shrink: true
        }
    },
    styleOverrides: {
        root: {
            '[type=number]': {
                textAlign: 'right'
            },
            '& label': {
                color: 'var(--primary-wo)',
            },
            '& label.Mui-focused': {
                color: 'var(--primary-wo)',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: 'yellow',
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: 'var(--primary-wo)',
                },
                '&:hover fieldset': {
                    borderColor: 'var(--primary-wo)',
                },
                '&.Mui-focused fieldset': {
                    borderColor: 'var(--primary-wo)',
                },
            },
        },
    }
};
