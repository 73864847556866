import { Components } from "@mui/material/styles";

export const MuiCheckbox: Components['MuiCheckbox'] = {
    defaultProps: {
        size: 'small',
    },
    styleOverrides: {
        root: {
            p: 0
        }
    }
};
