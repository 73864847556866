import { Components } from "@mui/material/styles";

export const MuiTabs: Components['MuiTabs'] = {
    styleOverrides: {
        root: {
            minHeight: '40px',
        },
        indicator: {
            backgroundColor: 'unset'
        },
    }
};
